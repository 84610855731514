html {
    font-size: 62.5%
}

html,
body {
    max-width: 100%
}

body {
    padding: 0;
    margin: 0;
    background: #ffffff;
    color: #666666;
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 1.4rem;
    line-height: 1;
    overflow-x: hidden !important;
    overflow-y: scroll;
    -webkit-text-size-adjust: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

*,
*:before,
*:after {
    box-sizing: border-box
}

.page-fade-reset {
    opacity: 1;
    -webkit-transition-duration: 0ms;
    transition-duration: 0ms
}

.page-fade-out {
    opacity: 0;
    -webkit-transition-property: opacity;
    transition-property: opacity;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    -webkit-backface-visibility: hidden
}

.wrapper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden
}

.wrapper-inner {
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden
}

body.boxed .wrapper-inner {
    max-width: 114rem
}

.aux-navigation-active .wrapper {
    position: relative
}

.mobile.aux-navigation-active .wrapper {
    /*position: fixed*/
}

.row {
    width: 100%;
    max-width: 114rem;
    margin-right: auto;
    margin-left: auto;
    margin-top: 0;
    margin-bottom: 0
}

.ie-browser .row {
    max-width: 1140px
}

.row.full-width {
    max-width: 100%
}

/*.row .row {
    width: auto;
    margin-right: -1.5rem;
    margin-left: -1.5rem
}*/

.row:before,
.row:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden
}

.row:after {
    clear: both
}

.column,
[class*="content-grid"] .grid-item {
    width: 100%;
    float: left
}

.column > *:first-child {
    margin-top: 0
}

.row.collapse .column {
    padding-left: 0;
    padding-right: 0
}

.row.small {
    padding: 3rem 0
}

.row.medium {
    padding: 5rem 0
}

.row.large {
    padding: 7rem 0
}

.row.xlarge {
    padding: 9rem 0
}

.width-1 {
    width: 8.33333%
}

.width-2 {
    width: 16.66667%
}

.width-3 {
    width: 25%
}

.width-4 {
    width: 33.33333%
}

.width-5 {
    width: 41.66667%
}

.width-6 {
    width: 50%
}

.width-7 {
    width: 58.33333%
}

.width-8 {
    width: 66.66667%
}

.width-9 {
    width: 75%
}

.width-10 {
    width: 83.33333%
}

.width-11 {
    width: 91.66667%
}

.width-12 {
    width: 100%
}

.column[class*='push-'],
.column[class*='pull-'] {
    position: relative
}

.push-1 {
    left: 8.33333%
}

.push-2 {
    left: 16.66667%
}

.push-3 {
    left: 25%
}

.push-4 {
    left: 33.33333%
}

.push-5 {
    left: 41.66667%
}

.push-6 {
    left: 50%
}

.push-7 {
    left: 58.33333%
}

.push-8 {
    left: 66.66667%
}

.push-9 {
    left: 75%
}

.push-10 {
    left: 83.33333%
}

.push-11 {
    left: 91.66667%
}

.pull-1 {
    right: 8.33333%
}

.pull-2 {
    right: 16.66667%
}

.pull-3 {
    right: 25%
}

.pull-4 {
    right: 33.33333%
}

.pull-5 {
    right: 41.66667%
}

.pull-6 {
    right: 50%
}

.pull-7 {
    right: 58.33333%
}

.pull-8 {
    right: 66.66667%
}

.pull-9 {
    right: 75%
}

.pull-10 {
    right: 83.33333%
}

.pull-11 {
    right: 91.66667%
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

.alpha {
    padding-left: 0
}

.omega {
    padding-right: 0
}

.clear {
    width: 0;
    height: 0;
    display: block;
    clear: both;
    overflow: hidden;
    visibility: hidden
}

.clearfix:before,
.clearfix:after,
.row:before,
.row:after {
    height: 0;
    content: ".";
    display: block;
    overflow: hidden
}

.clearfix:after,
.row:after {
    clear: both
}

.left {
    text-align: left
}

.right {
    text-align: right
}

.center {
    text-align: center
}

.pull-left {
    float: left !important
}

.pull-right {
    float: right !important
}

.v-align-bottom {
    vertical-align: bottom !important
}

.v-align-top {
    vertical-align: top !important
}

.v-align-top .background-on-mobile {
    margin-top: -2rem
}

.v-align-bottom .background-on-mobile {
    margin-bottom: -2rem
}

.hide {
    display: none !important;
    visibility: hidden
}

.show {
    display: block !important
}

.mobile .hide-on-mobile,
.mobile .hide-on-mobile * {
    display: none !important
}

.inline,
[class*="border-"].inline,
[class*="bkg-"].inline {
    width: auto !important;
    display: inline-block !important
}

.no-margins {
    margin: 0 !important
}

.no-margin-top {
    margin-top: 0 !important
}

.no-margin-right {
    margin-right: 0 !important
}

.no-margin-bottom {
    margin-bottom: 0 !important
}

.no-margin-left {
    margin-left: 0 !important
}

.no-padding {
    padding: 0 !important
}

.no-padding-top {
    padding-top: 0 !important
}

.no-padding-right {
    padding-right: 0 !important
}

.no-padding-bottom {
    padding-bottom: 0 !important
}

.no-padding-left {
    padding-left: 0 !important
}

.overflow-visible {
    overflow: visible !important
}

.prototype {
    font-size: 1.1rem;
    line-height: 3.5rem
}

.row.prototype .column:nth-child(odd) {
    background: #f9f9f9
}

.row.prototype .column:nth-child(even) {
    background: #eee
}

.nav-bar {
    width: 100%;
    min-height: 5rem
}

.nav-bar-inner {
    position: relative
}

.nav-bar .logo {
    height: 5rem;
    line-height: 5rem;
    float: left
}

.nav-bar .logo.logo-center {
    float: none;
    margin: 0 auto
}

.navigation .navigation.nav-left {
    float: left
}

.navigation.nav-right {
    float: right !important
}

.navigation.nav-center {
    float: none !important;
    display: block
}

.nav-bar .dropdown .button {
    width: auto
}

.navigation ul,
.nav-bar .dropdown-list {
    padding: 0;
    margin: 0;
    list-style: none
}

.navigation.nav-center > ul {
    text-align: center
}

.navigation ul li {
    margin: 0;
    float: left;
    position: relative
}

.navigation ul li.contains-mega-sub-menu {
    position: inherit
}

.navigation.nav-center > ul > li {
    margin-left: -0.4rem;
    float: none;
    display: inline-block;
    vertical-align: top
}

.navigation .sub-menu,
.nav-bar .dropdown-list {
    width: 18rem;
    text-align: left;
    background: #222222;
    background: rgba(34, 34, 34, 1);
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, .3);
    border: none;
    border-radius: 0;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 101
}

.nav-bar .dropdown-list.custom-content {
    width: auto;
    min-width: 25rem;
    padding: 2rem
}

.navigation > ul > li:last-child > .sub-menu,
.nav-bar .dropdown.pull-right .dropdown-list {
    right: 0
}

.navigation .sub-menu ul {
    top: 0;
    left: 18rem
}

.navigation > ul > li:last-child > .sub-menu ul {
    right: 18rem;
    left: auto
}

.navigation .sub-menu li,
.nav-bar .dropdown-list li {
    background: none;
    border-bottom: 1px solid #303030;
    float: none
}

.navigation ul li a,
.nav-bar .dropdown-list li a {
    padding: 0 1.5rem;
    font-size: 1.1rem;
    color: #878787;
    text-decoration: none;
    display: block;
    word-wrap: break-word
}

.navigation > ul > li > a {
    background-color: rgba(0, 0, 0, 0);
    line-height: 5rem
}

.navigation ul li:hover > a,
.nav-bar .dropdown-list li a {
    background: none;
    color: #ffffff
}

.navigation .sub-menu li a,
.nav-bar .dropdown-list li a {
    padding: 1.2rem 1.5rem;
    line-height: 1.8;
    color: #666666
}

.navigation .sub-menu li:last-child,
.nav-bar .dropdown-list li:last-child {
    border: none
}

.navigation.sub-menu-indicator > ul > li.contains-sub-menu > a:after,
.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu > a:after,
.navigation .sub-menu li.contains-sub-menu > a:after {
    font-family: 'icomoon';
    font-size: inherit;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: inherit;
    display: inline-block;
    position: absolute
}

.navigation.sub-menu-indicator > ul > li.contains-sub-menu,
.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu {
    padding-right: 1.2rem
}

.navigation.sub-menu-indicator > ul > li.contains-sub-menu > a:after,
.navigation.sub-menu-indicator > ul > li.contains-mega-sub-menu > a:after {
    margin-left: 0.5rem;
    content: "\e63e"
}

.navigation .sub-menu li.contains-sub-menu > a {
    padding-right: 2.2rem
}

.navigation .sub-menu li.contains-sub-menu > a:after {
    right: 0.8rem;
    content: "\e640"
}

.navigation .sub-menu li:hover > a,
.nav-bar .dropdown-list li:hover a {
    color: #ffffff
}

.navigation ul li:hover > .sub-menu {
    visibility: visible;
    opacity: 1
}

.navigation ul li.current > a {
    color: #ffffff
}

.navigation ul li.current > a:hover {
    color: #ffffff
}

.navigation .mega-sub-menu {
    padding: 2rem 0;
    background: #222222;
    background: rgba(34, 34, 34, 1);
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, .3);
    opacity: 0;
    visibility: hidden;
    display: block;
    position: absolute;
    left: 1.5rem;
    right: 1.5rem;
    z-index: 101
}

.navigation .mega-sub-menu > li {
    width: 33.33333%;
    padding: 0 2rem;
    text-align: left;
    float: left;
    border-right: 1px solid #303030
}

.navigation .mega-sub-menu > li > a {
    background: none;
    font-weight: bold
}

.navigation .mega-sub-menu > li:hover > a {
    background: none
}

.navigation .mega-sub-menu > li:last-child {
    border-right: none
}

.navigation .mega-sub-menu > li > a {
    padding: 0rem 1.5rem 0.7rem 1.5rem;
    font-size: 1.2rem;
    line-height: 1.8;
    text-transform: uppercase
}

.navigation .mega-sub-menu ul li p {
    padding: 0rem 1.5rem 0.7rem 1.5rem;
    font-size: 1.2rem;
    margin-bottom: 0
}

.navigation .mega-sub-menu ul li a {
    padding: 0.6rem 1.5rem;
    line-height: 1.8;
    color: #666666
}

.navigation .mega-sub-menu ul li.current > a {
    color: #ffffff
}

.navigation .mega-sub-menu ul li:hover > a {
    background: #333333;
    color: #ffffff
}

.navigation .mega-sub-menu li ul > li {
    float: none
}

.navigation .mega-sub-menu li.content-column ul li {
    padding: 0.6rem 1.5rem
}

.navigation .mega-sub-menu li.content-column ul li a {
    padding: 0
}

.navigation ul li:hover > .mega-sub-menu {
    visibility: visible;
    opacity: 1
}

.navigation .sub-menu,
.navigation .mega-sub-menu {
    -webkit-transition-property: -webkit-transform, opacity, visibility, border-color;
    -webkit-transition-property: opacity, visibility, border-color, -webkit-transform;
    transition-property: opacity, visibility, border-color, -webkit-transform;
    transition-property: transform, opacity, visibility, border-color;
    transition-property: transform, opacity, visibility, border-color, -webkit-transform;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms
}

.navigation ul li a {
    -webkit-transition-property: background, color, line-height;
    transition-property: background, color, line-height;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms
}

.mobile .logo.logo-center {
    margin-left: -2.5rem;
    float: left;
    position: relative;
    left: 50%
}

.mobile header .navigation {
    display: none !important
}

.mobile .navigation-show {
    display: block
}

.navigation-show,
.navigation-hide {
    width: 4.4rem;
    height: 4.4rem;
    text-align: center;
    float: right
}

.navigation-show a,
.navigation-hide a {
    height: 4.4rem;
    text-align: center;
    display: block
}

.navigation-show span,
.navigation-hide span {
    width: 100%;
    line-height: 4.4rem
}

.navigation-show {
    display: none;
    float: right
}

.navigation-show a {
    color: #666666
}

.navigation-show a:hover {
    color: #fff
}

.navigation-hide {
    position: relative;
    z-index: 10
}

.navigation-hide a {
    color: #666666
}

.navigation-hide a:hover {
    color: #fff
}

.side-navigation-wrapper {
    width: 35rem;
    min-height: 100%;
    padding: 0;
    background: #292929;
    box-shadow: inset -7px 0 9px -7px rgba( 0, 0, 0, 0.6);
    position: fixed;
    left: 0;
    right: auto;
    z-index: 102;
    -webkit-transform: translate3d(-35rem, 0, 0);
    transform: translate3d(-35rem, 0, 0)
}

.side-navigation-wrapper.enter-right {
    text-align: left;
    box-shadow: inset 7px 0 9px -7px rgba( 0, 0, 0, 0.6);
    right: 0;
    left: auto;
    -webkit-transform: translate3d(35rem, 0, 0);
    transform: translate3d(35rem, 0, 0)
}

.side-navigation-wrapper.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.element-reveal-left {
    -webkit-transform: translate3d(35rem, 0, 0);
    transform: translate3d(35rem, 0, 0)
}

.element-reveal-right {
    -webkit-transform: translate3d(-35rem, 0, 0);
    transform: translate3d(-35rem, 0, 0)
}

.side-navigation-wrapper,
.reveal-side-navigation {
    -webkit-transition-property: -webkit-transform, visibility;
    -webkit-transition-property: visibility, -webkit-transform;
    transition-property: visibility, -webkit-transform;
    transition-property: transform, visibility;
    transition-property: transform, visibility, -webkit-transform
}

.element-reveal-left,
.element-reveal-right {
    position: relative
}

.mobile .side-navigation-wrapper {
    visibility: hidden
}

.mobile .side-navigation-wrapper.active {
    visibility: visible
}

.side-navigation-wrapper.hide {
    visibility: hidden;
    left: -35rem
}

.side-navigation-wrapper.enter-right.hide {
    right: -35rem
}

.element-show-left {
    left: 35rem
}

.element-show-right {
    left: -35rem
}

.element-show-left,
.element-show-right {
    position: relative !important
}

.side-navigation {
    width: 100%;
    height: 100%
}

.side-navigation:before,
.side-navigation:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both
}

.side-navigation ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.side-navigation ul li {
    width: 100%;
    display: block;
    margin-bottom: 0
}

.side-navigation ul li a {
    font-size: 1.2rem;
    line-height: 1;
    color: #999999;
    text-decoration: none;
    display: block;
    padding: 1.3rem 4rem;
    word-wrap: break-word;
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.side-navigation ul li a:hover {
    color: #ffffff
}

.side-navigation .sub-menu a {
    padding: 1.1rem 4rem 1.1rem 5.5rem;
    color: #666666
}

.side-navigation.center .sub-menu a {
    padding: 1.1rem 4rem 1.1rem 4rem
}

.side-navigation .sub-menu .sub-menu a {
    padding-left: 7rem
}

.side-navigation ul li:hover > .sub-menu {
    opacity: 1
}

.side-navigation ul li.current > a {
    color: #ffffff;
    font-weight: bold
}

.side-navigation ul li.current > a:hover {
    color: #ffffff
}

.content-side-navigation-screen {
    width: 100%;
    height: 100%;
    display: block;
    background: rgba( 0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101
}

.side-navigation .sub-menu {
    overflow: hidden
}

.side-navigation .sub-menu {
    height: 0;
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.mobile .side-navigation-wrapper.active {
    position: absolute
}

.overlay-navigation-wrapper {
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-color: rgba( 0, 0, 0, 0.8);
    visibility: hidden;
    opacity: 0;
    display: table;
    position: fixed;
    top: -100%;
    z-index: 101;
    -webkit-transition-property: height, opacity, visibility;
    transition-property: height, opacity, visibility
}

.overlay-navigation-wrapper.active {
    opacity: 1;
    visibility: visible
}

.overlay-navigation-inner {
    width: 100%;
    height: 100%;
    display: table-cell;
    text-align: center;
    vertical-align: middle
}

.overlay-navigation {
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto
}

.overlay-navigation:before,
.overlay-navigation:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
    clear: both
}

.overlay-navigation ul {
    padding: 0;
    margin: 0;
    list-style: none
}

.overlay-navigation ul li {
    width: 100%;
    display: block;
    margin-bottom: 0
}

.overlay-navigation ul li a {
    font-size: 1.2rem;
    line-height: 1.5;
    color: #878787;
    text-decoration: none;
    display: block;
    padding: 0.6rem 0;
    word-wrap: break-word;
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.overlay-navigation > ul > li > a {
    font-size: 1.8rem;
    line-height: 1.5;
    font-weight: bold
}

.overlay-navigation ul li a:hover {
    color: #ffffff
}

.overlay-navigation .sub-menu a {
    padding-left: 0;
    color: #666666
}

.overlay-navigation .sub-menu .sub-menu a {
    padding-left: 7rem
}

.overlay-navigation ul li:hover > .sub-menu {
    opacity: 1
}

.overlay-navigation ul li.current > a {
    color: #ffffff;
    font-weight: bold
}

.overlay-navigation ul li.current > a:hover {
    color: #ffffff
}

.overlay-navigation .sub-menu {
    overflow: hidden
}

.overlay-navigation .sub-menu {
    height: 0;
    -webkit-transition-property: height, border-color;
    transition-property: height, border-color;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.mobile .overlay-navigation-wrapper {
    position: absolute
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    border: none;
    outline: none
}

img.pull-left,
.thumbnail.pull-left {
    margin: 0 1.5rem 1rem 0
}

img.pull-right,
.thumbnail.pull-right {
    margin: 0 0 1.5rem 1rem
}

.thumbnail {
    width: 100%;
    margin-bottom: 3rem;
    position: relative;
    overflow: hidden
}

.thumbnail.rounded,
img.rounded {
    border-radius: 50%
}

.thumbnail > span,
.thumbnail > a {
    width: 100%;
    height: 100%;
    float: left
}

.caption-below {
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1.2rem;
    font-style: italic;
    border: 1px solid #eeeeee;
    float: left
}

.thumbnail .caption-over-outer {
    width: 100%;
    height: 100%;
    padding: 2rem;
    display: table;
    opacity: 0;
    position: absolute;
    bottom: 0;
    z-index: 2
}

.thumbnail .caption-over-inner {
    width: 100%;
    height: 100%;
    display: table-cell;
    vertical-align: middle
}

.thumbnail .caption-over-inner *:first-child {
    margin-top: 0
}

.thumbnail .caption-over-inner *:last-child {
    margin-bottom: 0
}

.thumbnail > span,
.thumbnail > a {
    width: 100%;
    height: 100%;
    display: block;
    outline: none;
    border: none;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0
}

.overlay-info {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    color: #fff;
    border: none;
    background: #000;
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}

a.overlay-info:hover {
    color: #fff
}

.overlay-info > span {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    font-size: 1.2rem;
    font-style: normal;
    display: table
}

.overlay-info > span > span {
    display: table-cell;
    text-align: center;
    vertical-align: middle
}

.fill-background {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.overlay-info.left > span > span {
    text-align: left
}

.overlay-info.right > span > span {
    text-align: right
}

.thumbnail .caption-over-inner.v-align-top,
.overlay-info.v-align-top > span > span {
    vertical-align: top
}

.thumbnail .caption-over-inner.v-align-bottom,
.overlay-info.v-align-bottom > span > span {
    vertical-align: bottom
}

.thumbnail .overlay-info {
    width: 100.1%;
    line-height: 1;
    opacity: 0
}

.thumbnail:hover .overlay-info {
    opacity: 1
}

.thumbnail .overlay-info,
.thumbnail img {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden
}

.thumbnail:hover .overlay-info,
.thumbnail:hover img {
    -webkit-transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1);
    transform: translate3d(0, 0, 0) rotate3d(1, 1, 1, 0deg) scale3d(1, 1, 1)
}

.overlay-fade-out .overlay-info,
.overlay-fade-img-scale-in .overlay-info,
.overlay-fade-img-scale-out .overlay-info {
    opacity: 1
}

.overlay-fade-out:hover .overlay-info,
.overlay-fade-img-scale-in:hover .overlay-info,
.overlay-fade-img-scale-out:hover .overlay-info {
    opacity: 0
}

.overlay-slide-in-top .overlay-info {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.overlay-slide-in-right .overlay-info {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.overlay-slide-in-bottom .overlay-info {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.overlay-slide-in-left .overlay-info {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.overlay-scale-in .overlay-info {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 1);
    transform: scale3d(0.1, 0.1, 1)
}

.overlay-rotate-in .overlay-info {
    opacity: 0;
    -webkit-transform: rotate3d(0.1, 0.1, 1, -180deg);
    transform: rotate3d(0.1, 0.1, 1, -180deg)
}

.overlay-img-slide-right .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.overlay-img-slide-right:hover img {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.overlay-img-slide-left .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.overlay-img-slide-left:hover img {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.overlay-img-slide-down .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.overlay-img-slide-down:hover img {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.overlay-img-slide-up .overlay-info {
    opacity: 1;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.overlay-img-slide-up:hover img {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.img-scale-in:hover img,
.img-scale-out img,
.overlay-fade-img-scale-out img,
.overlay-fade-img-scale-in:hover img,
.overlay-img-scale-in:hover img {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1)
}

.overlay-img-scale-in .overlay-info {
    -webkit-transform: scale3d(0.4, 0.4, 1);
    transform: scale3d(0.4, 0.4, 1)
}

[class*="content-grid"] .grid-item {
    margin: 0 0 3rem;
    float: left
}

[class*="content-grid"] .thumbnail {
    float: none
}

.content-grid-1 .grid-item {
    width: 100%
}

.content-grid-2 .grid-item {
    width: 50%
}

.content-grid-3 .grid-item {
    width: 33.33333%
}

.content-grid-4 .grid-item {
    width: 25%
}

.content-grid-5 .grid-item {
    width: 20%
}

.content-grid-6 .grid-item {
    width: 16.66667%
}

[class*="content-grid-"].no-margins,
.row [class*="content-grid-"].no-margins {
    margin-right: 0 !important;
    margin-left: 0 !important
}

[class*="content-grid-"].no-margins .grid-item {
    padding: 0 !important;
    margin: 0 !important
}

.text-column {
    margin-bottom: 3rem
}

.text-column > *:last-child {
    margin-bottom: 0
}

.feature-column {
    margin-bottom: 3rem
}

.feature-column > *:last-child {
    margin-bottom: 0
}

.feature-column .feature-icon {
    font-size: 3rem;
    margin-bottom: 2rem;
    display: inline-block;
    position: relative
}

.feature-column.left .feature-icon {
    float: left
}

.feature-text.left {
    padding-left: 5rem
}

.feature-column.right .feature-text {
    padding-right: 5rem;
    padding-left: 0
}

.feature-text *:first-child {
    margin-top: 0
}

.feature-column.small .feature-icon {
    font-size: 2rem
}

.feature-column.left.small .feature-text {
    padding-left: 4rem
}

.feature-column.left.small .icon-boxed,
.feature-column.left.small .icon-circled {
    width: 5.8rem;
    height: 5.8rem;
    line-height: 5.8rem
}

.feature-column.left.small .icon-boxed + .feature-text,
.feature-column.left.small .icon-circled + .feature-text {
    padding-left: 9rem
}

.feature-column.right.small .feature-text {
    padding-right: 4rem;
    padding-left: 0
}

.feature-column.right.small .icon-boxed + .feature-text,
.feature-column.right.small .icon-circled + .feature-text {
    padding-right: 9rem;
    padding-left: 0
}

.feature-colum.mediumn .feature-icon {
    font-size: 3rem
}

.feature-column.left.medium .feature-text {
    padding-left: 5rem
}

.feature-column.medium .icon-boxed,
.feature-column.medium .icon-circled {
    width: 6.6rem;
    height: 6.6rem;
    line-height: 6.6rem
}

.feature-column.left .icon-boxed + .feature-text,
.feature-column.left .icon-circled + .feature-text,
.feature-column.left.medium .icon-boxed + .feature-text,
.feature-column.left.medium .icon-circled + .feature-text {
    padding-left: 9.8rem
}

.feature-column.right .icon-boxed + .feature-text,
.feature-column.right .icon-circled + .feature-text,
.feature-column.right.medium .icon-boxed + .feature-text,
.feature-column.right.medium .icon-circled + .feature-text {
    padding-right: 9.8rem;
    padding-left: 0
}

.feature-column.large .feature-icon {
    width: 7.4rem;
    height: 7.4rem;
    font-size: 4rem;
    line-height: 7.4rem
}

.feature-column.left.large .feature-text {
    padding-left: 6rem
}

.feature-column.large .icon-boxed,
.feature-column.large .icon-circled {
    width: 7.4rem;
    height: 7.4rem;
    line-height: 7.4rem
}

.feature-column.left.large .icon-boxed + .feature-text,
.feature-column.left.large .icon-circled + .feature-text {
    padding-left: 10.6rem
}

.feature-column.right.large .icon-boxed + .feature-text,
.feature-column.right.large .icon-circled + .feature-text {
    padding-right: 10.6rem;
    padding-left: 0
}

.feature-column.xlarge .feature-icon {
    font-size: 5rem
}

.feature-column.left.xlarge .feature-text {
    padding-left: 7rem
}

.feature-column.xlarge .icon-boxed,
.feature-column.xlarge .icon-circled {
    width: 9rem;
    height: 9rem;
    line-height: 9rem
}

.feature-column.left.xlarge .icon-boxed + .feature-text,
.feature-column.left.xlarge .icon-circled + .feature-text {
    padding-left: 12.2rem
}

.feature-column.right.xlarge .icon-boxed + .feature-text,
.feature-column.right.xlarge .icon-circled + .feature-text {
    padding-right: 12.2rem;
    padding-left: 0
}

.feature-column.right .feature-icon {
    float: right;
    margin: 0 auto 2rem auto
}

.feature-column.center .feature-icon {
    display: inline-block;
    float: none;
    margin: 0 auto 2rem auto
}

.feature-column.center .feature-icon + .feature-text {
    padding-left: 0;
    padding-right: 0
}

@media only screen and (max-width: 768px) {
    .feature-column.center-on-mobile .feature-icon {
        display: inline-block;
        float: none;
        margin: 0 auto 2rem auto
    }
    .feature-column.center-on-mobile .feature-icon + .feature-text {
        padding-left: 0;
        padding-right: 0
    }
}

input {
    line-height: normal !important
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="time"],
input[type="datetime"],
input[type="month"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="password"],
textarea {
    width: 100%;
    padding: 1.3rem 2.5rem;
    margin: 0 0 1.5rem 0
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="date"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="password"]:focus,
textarea:focus {
    outline: none
}

.form-element,
textarea {
    line-height: normal !important;
    background: #f9f9f9;
    color: #666666;
    border: 1px solid #eeeeee;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.form-element:focus,
textarea:focus {
    background: #ffffff;
    color: #333333;
    border: 1px solid #cccccc;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2)
}

textarea {
    min-width: 10rem;
    min-height: 10rem
}

.textarea-no-resize {
    resize: none
}

label {
    margin-bottom: 0.8rem;
    color: #666;
    font-size: 1.2rem;
    display: block
}

label.pull-left {
    margin-bottom: 0;
    margin-right: 1rem;
    padding: 1.4rem 0;
    line-height: normal;
    float: left
}

label.pull-right {
    margin-bottom: 0;
    margin-left: 1rem;
    padding: 1.4rem 0;
    line-height: normal;
    float: left
}

label.emphasized {
    color: #666;
    font-size: 1.2rem;
    font-weight: bold
}

.form-element.rounded,
textarea.rounded {
    border-radius: 0.3rem
}

.form-element.pill {
    border-radius: 10.5rem
}

.form-element.small {
    padding: 1rem 1.5rem;
    font-size: 1rem
}

.form-element.medium {
    padding: 1.3rem 2.5rem
}

.form-element.large {
    padding: 1.6rem 3rem;
    font-size: 1.4rem
}

.form-element.xlarge {
    padding: 1.9rem 3.5rem;
    font-size: 1.5rem
}

div[contenteditable="true"] {
    background: #f9f9f9;
    color: #666666;
    border: 1px solid #eeeeee
}

div[contenteditable="true"]:focus {
    background: #ffffff;
    color: #333333;
    border: 1px solid #cccccc;
    box-shadow: 0 0 0.2rem 0 rgba(0, 0, 0, 0.2);
    outline: none
}

div[contenteditable="true"] {
    padding: 1.3rem 2.5rem
}

div[contenteditable="true"].small {
    padding: 1rem 1.5rem;
    font-size: 1rem
}

div[contenteditable="true"].medium {
    padding: 1.3rem 2.5rem
}

div[contenteditable="true"].large {
    padding: 1.6rem 3rem;
    font-size: 1.4rem
}

div[contenteditable="true"].xlarge {
    padding: 1.9rem 3.5rem;
    font-size: 1.5rem
}

.button-group {
    margin-bottom: 3rem
}

button,
.button {
    padding: 1.3rem 2.5rem;
    margin: 0 0 1rem 0;
    font-family: inherit;
    font-size: 1.2rem;
    line-height: 1;
    color: #666666;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    font-weight: normal;
    vertical-align: top;
    display: inline-block;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

button:focus,
.button:focus {
    outline: none
}

button:hover,
.button:hover {
    text-decoration: none;
    color: #666666;
    background-color: #d0d0d0;
    border-color: #d0d0d0
}

button.small,
.button.small {
    padding: 1rem 1.5rem;
    font-size: 1rem
}

button.medium,
.button.medium {
    padding: 1.3rem 2.5rem;
    font-size: 1.2rem
}

button.large,
.button.large {
    padding: 1.6rem 3rem;
    font-size: 1.4rem
}

button.xlarge,
.button.xlarge {
    padding: 1.9rem 3.5rem;
    font-size: 1.5rem
}

button [class*="icon-"]:before,
.button [class*="icon-"]:before {
    margin-left: 0;
    margin-right: 0.5rem
}

input[type="submit"]::-moz-focus-inner {
    border: 0 !important
}

button.rounded,
.button.rounded {
    border-radius: 0.3rem
}

button.pill,
.button.pill {
    border-radius: 10.5rem
}

button.shadow,
.button.shadow {
    box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.5)
}

button.hard-shadow,
.button.hard-shadow {
    border-bottom: 3px solid rgba(0, 0, 0, .15)
}

button.disabled,
.button.disabled {
    cursor: default;
    color: #cccccc;
    background-color: #f9f9f9
}

button.disabled:hover,
.button.disabled:hover {
    background-color: #f9f9f9;
    border-color: #eeeeee
}

.button-nav {
    padding: 0;
    margin: 0;
    list-style: none
}

.button-nav li {
    float: left;
    margin: 0 1px 0 0
}

.button-nav li .button.rounded,
.button-nav li button.rounded,
.button-nav li .button.pill,
.button-nav li button.pill {
    border-radius: 0
}

.button-nav li:first-child .button.rounded,
.button-nav li:first-child button.rounded,
.button-nav.rounded li:first-child .button,
.button-nav.rounded li:first-child button {
    border-radius: 0.3rem 0 0 0.3rem
}

.button-nav li:last-child .button.rounded .button-nav li:last-child button.rounded,
.button-nav.rounded li:last-child .button,
.button-nav.rounded li:last-child button {
    border-radius: 0 0.3rem 0.3rem 0
}

.button-nav li:first-child .button.pill,
.button-nav li:first-child button.pill,
.button-nav.pill li:first-child .button,
.button-nav.pill li:first-child button {
    border-radius: 10.5rem 0 0 10.5rem
}

.button-nav li:last-child .button.pill,
.button-nav li:last-child button.pill,
.button-nav.pill li:last-child .button,
.button-nav.pill li:last-child button {
    border-radius: 0 10.5rem 10.5rem 0
}

.button-nav.center li {
    margin-left: -0.3rem;
    display: inline-block;
    float: none
}

.dropdown {
    position: relative;
    margin-bottom: 1rem
}

.dropdown button,
.dropdown .button {
    width: 100%;
    text-align: left;
    margin-bottom: 0
}

.dropdown button [class*="icon-"],
.dropdown .button [class*="icon-"] {
    display: initial;
    float: right
}

.dropdown button [class*="icon-"]:before,
.dropdown .button [class*="icon-"]:before {
    margin: 0 0 0 1rem
}

.dropdown.disabled .button {
    cursor: default
}

.dropdown.disabled button,
.dropdown.disabled .button,
.dropdown.disabled button:hover,
.dropdown.disabled .button:hover {
    border-color: #ddd !important;
    background: #fff !important;
    color: #ddd !important
}

.dropdown-list {
    width: 16rem;
    margin-top: 0.3rem;
    text-align: left;
    background: #fff;
    border: 1px solid #ddd;
    box-shadow: 0 0.2rem 0.2rem rgba(0, 0, 0, .3);
    border-radius: 0.3rem;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: 101;
    -webkit-transition-property: -webkit-transform, opacity, visibility;
    -webkit-transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: transform, opacity, visibility;
    transition-property: transform, opacity, visibility, -webkit-transform;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms
}

.dropdown-list li a {
    padding: 0.6rem 2.5rem;
    display: block;
    font-size: 1.2rem;
    color: #666;
    font-weight: normal;
    cursor: pointer
}

.dropdown-list li a:hover {
    background: #f4f4f4;
    color: #333;
    text-decoration: none
}

.dropdown-list li.current a {
    background: #f4f4f4;
    color: #666
}

.dropdown-list.active {
    opacity: 1;
    visibility: visible
}

.dropdown-list .list-header {
    padding: 0.6rem 2.5rem;
    font-size: 1.1rem;
    font-weight: bold;
    background: #ddd
}

.dropdown.small .button,
.dropdown.small button {
    padding: 1rem 1.5rem
}

.dropdown.small .dropdown-list li a {
    padding: 0.4rem 1.5rem
}

.dropdown.small .dropdown-list {
    width: 14rem
}

.dropdown.medium .button,
.dropdown.medium button {
    padding: 1.3rem 2.5rem
}

.dropdown.medium .dropdown-list li a {
    padding: 0.6rem 2.5rem
}

.dropdown.medium .dropdown-list {
    width: 16rem
}

.dropdown.large .button,
.dropdown.large button {
    padding: 1.6rem 3rem
}

.dropdown.large .dropdown-list li a {
    padding: 0.9rem 3rem
}

.dropdown.large .dropdown-list {
    width: 18rem
}

.dropdown.xlarge .button,
.dropdown.xlarge button {
    padding: 1.9rem 3.5rem
}

.dropdown.xlarge .dropdown-list li a {
    padding: 1.2rem 3.5rem
}

.dropdown.xlarge .dropdown-list {
    width: 20rem
}

.dropdown.rounded .button,
.dropdown.rounded button {
    border-radius: 0.3rem
}

ul,
ol {
    margin: 0 0 3rem 0;
    padding: 0;
    font-size: 1.4rem;
    line-height: 1.8
}

ul {
    list-style: none
}

ol {
    list-style: decimal inside
}

ul.disc {
    list-style: disc inside
}

ul.circle {
    list-style: circle inside
}

ul.square {
    list-style: square inside
}

ol.decimal-leading-zero {
    list-style: decimal-leading-zero inside
}

ol.upper-roman {
    list-style: upper-roman inside
}

ol.lower-alpha {
    list-style: lower-alpha inside
}

ul ul,
ul ol,
ol ol,
ol ul {
    margin: 0.3rem 0 0.3rem 2rem;
    list-style: inherit
}

ul [class*="icon-"],
ol [class*="icon-"] {
    margin-right: 1rem
}

.list-group.solid,
.list-group.dashed,
.list-group-dotted {
    border: none
}

.list-group li {
    padding: 1rem 0;
    border-bottom: 1px solid #eeeeee
}

.list-group li li {
    border-top: 1px solid #eeeeee
}

.list-group > li:first-child {
    padding-top: 0
}

.list-group li li:last-child {
    padding-bottom: 0
}

.list-group li:last-child {
    border-bottom: none;
    border-width: 0
}

.list-group ul,
.list-group ol {
    margin: 0
}

.list-group.boxed li {
    padding: 1rem 2rem
}

.list-group.boxed li li:last-child {
    padding: 1rem 2rem;
    border-top: 1px solid
}

.list-group li ul {
    padding-top: 1rem
}

.list-group[class*="border-"] li,
.list-group[class*="border-"] li li,
.list-group[class*="border-"] li ul {
    border-color: inherit !important
}

.list-group li *:last-child {
    margin-bottom: 0
}

.list-group.small li li {
    padding-left: 1.5rem;
    margin-top: 0
}

.list-group.small li {
    padding: 0.7rem 0
}

.list-groups.small ul,
.list-group.small ol {
    margin-top: 0.7rem !important
}

.list-group.boxed.small li,
.list-groups.boxed.small li {
    padding: 0.7rem 1.5rem
}

.list-group.medium li {
    padding: 1rem 0
}

.list-groups.medium ul,
.list-group.medium ol {
    margin-top: 1rem !important
}

.list-group.boxed.medium li,
.list-group.boxed.medium li {
    padding: 1rem 2rem
}

.list-group.large li {
    padding: 1.3rem 0
}

.list-groups.large ul,
.list-group.large ol {
    margin-top: 1.3rem !important
}

.list-group.boxed.large li,
.list-group.boxed.large li {
    padding: 1.3rem 2.5rem
}

.list-group.xlarge li {
    padding: 1.6rem 0
}

.list-groups.xlarge ul,
.list-group.xlarge ol {
    margin-top: 1.6rem !important
}

.list-group.boxed.xlarge li,
.list-group.boxed.xlarge li {
    padding: 1.6rem 3rem
}

.list-group.dotted li {
    border-bottom-style: dotted
}

.list-group.dotted li li {
    border-top-style: dotted
}

.list-group.dashed li {
    border-bottom-style: dashed
}

.list-group.dashed li li {
    border-top-style: dashed
}

.list-group.thick li {
    border-bottom-width: 0.2rem
}

.list-group.thick li li {
    border-top-width: 0.2rem
}

.list-group:not(.boxed) li:last-child {
    border-bottom: none;
    padding-bottom: 0 !important
}

.list-group li li:last-child {
    border-top: none
}

.list-group.boxed li {
    border: 1px solid;
    border-bottom: none
}

.list-group.boxed li:last-child {
    border-bottom: 1px solid
}

.list-group.boxed.dotted li {
    border-style: dotted;
    border-bottom: none
}

.list-group.boxed.dotted li:last-child {
    border-bottom: 1px dotted
}

.list-group.boxed.dotted li li:last-child {
    border-top-style: dotted
}

.list-group.boxed.dashed li {
    border-style: dashed;
    border-bottom: none
}

.list-group.boxed.dashed li:last-child {
    border-bottom: 1px dashed
}

.list-group.boxed.dashed li li:last-child {
    border-top-style: dashed
}

.list-group.boxed.thick li,
.list-group.boxed.thick li:last-child {
    border-width: 0.2rem
}

.list-group.rounded li:first-child {
    border-radius: 0.3rem 0.3rem 0 0
}

.list-group.rounded li:last-child {
    border-radius: 0 0 0.3rem 0.3rem
}

.list-group[class*="border-"],
.list-group[class*="border-"].dotted,
.list-group[class*="border-"].dashed,
.list-group[class*="border-"].thick,
.list-group[class*="border-"]:hover,
.list-group.boxed[class*="border-"],
.list-group.boxed[class*="border-"].dotted,
.list-group.boxed[class*="border-"].dashed,
.list-group.boxed[class*="border-"].thick,
.list-group.boxed[class*="border-"]:hover {
    border-width: 0
}

.list-horizontal li {
    display: inline-block
}

.menu-box {
    overflow: visible;
    margin-bottom: 3rem
}

.menu-list,
.menu-list li {
    margin: 0 0 3rem 0
}

.menu-list.boxed {
    padding: 2rem
}

.menu-list li {
    position: relative
}

.menu-list li:last-child {
    margin-bottom: 0
}

.menu-list h4 {
    margin-bottom: 0
}

.menu-list h4 + p {
    margin-top: 1.3rem
}

.menu-list p {
    margin-bottom: 0
}

.menu-list .menu-content {
    padding-right: 10rem
}

.menu-list .menu-title,
.menu-list .menu-description,
.menu-list .menu-price {
    background-color: #fff
}

.menu-list h4,
.menu-list .menu-title,
.menu-list .menu-content,
.menu-list .menu-description,
.menu-list .menu-price {
    position: relative;
    z-index: 1
}

.menu-list .menu-price {
    font-weight: bold;
    position: absolute;
    right: 0;
    bottom: 0
}

.menu-title .menu-price {
    position: relative;
    right: auto;
    left: auto;
    bottom: auto
}

.menu-list .menu-line {
    height: 1px;
    margin: 0;
    border: 0;
    display: block;
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
    z-index: 0;
    background-image: -webkit-linear-gradient(left, #ccc 100%, rgba(0, 0, 0, 0) 0%);
    background-image: linear-gradient(to right, #ccc 100%, rgba(0, 0, 0, 0) 0%);
    background-position: bottom;
    background-size: 6px 2px;
    background-repeat: repeat-x
}

.menu-list .callout {
    padding: 2rem;
    background-color: #f4f4f4
}

.menu-list .callout * {
    background-color: inherit
}

.menu-list .special-item {
    font-size: 1.3rem;
    font-style: italic;
    text-transform: none
}

.menu-list h4 .menu-line {
    bottom: 4px
}

.menu-list .menu-content .menu-line {
    bottom: 8px
}

.menu-box.small,
.menu-list.boxed.small {
    padding: 1.5rem 1.5rem 0 1.5rem
}

.menu-box.small .menu-list,
.menu-list.boxed.small li:last-child {
    margin-bottom: 1.5rem
}

.menu-box.medium,
.menu-list.boxed.medium {
    padding: 2rem 2rem 0 2rem
}

.menu-box.medium .menu-list,
.menu-list.boxed.medium li:last-child {
    margin-bottom: 2rem
}

.menu-box.large,
.menu-list.boxed.large {
    padding: 2.5rem 2.5rem 0 2.5rem
}

.menu-box.large .menu-list,
.menu-list.boxed.large li:last-child {
    margin-bottom: 2.5rem
}

.menu-box.xlarge,
.menu-list.boxed.xlarge {
    padding: 3rem 3rem 0 3rem
}

.menu-box.xlarge .menu-list,
.menu-list.boxed.xlarge li:last-child {
    margin-bottom: 3rem
}

.menu-list.center .menu-line {
    display: none
}

.menu-list.center .menu-price {
    position: relative
}

.menu-list.center .menu-content {
    padding: 0
}

.menu-list.center .menu-description {
    display: block
}

.menu-list.right .menu-content {
    padding-left: 10rem;
    padding-right: 0
}

.menu-list.right .menu-price {
    left: 0;
    right: auto
}

.menu-list.boxed {
    border-style: solid;
    border-width: 1px
}

.menu-list.boxed.dashed {
    border-style: dashed
}

.menu-list.boxed.dotted {
    border-style: dotted
}

.menu-list.rounded,
.menu-list.rounded .callout {
    border-radius: 0.3rem
}

.dotted .menu-line {
    height: 2px;
    background-image: -webkit-radial-gradient(circle closest-side, #ccc 99%, rgba(0, 0, 0, 0) 0%);
    background-image: radial-gradient(circle closest-side, #ccc 99%, rgba(0, 0, 0, 0) 0%)
}

.dashed .menu-line {
    height: 1px;
    background-image: -webkit-linear-gradient(left, #ccc 50%, rgba(0, 0, 0, 0) 0%);
    background-image: linear-gradient(to right, #ccc 50%, rgba(0, 0, 0, 0) 0%)
}

.menu-list .callout.rounded {
    border-radius: 0.3rem
}

.tabs {
    margin: 0 0 3rem 0;
    padding: 0
}

.tab-nav {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none
}

.tab-nav:after {
    height: 0;
    display: block;
    content: ".";
    overflow: hidden;
    clear: both
}

.tabs .tab-nav > li {
    margin: 0 1px 0 0;
    display: inline-block
}

.tabs.vertical:after {
    display: block;
    content: ".";
    overflow: hidden;
    clear: both
}

.tabs.vertical .tab-nav > li {
    margin: 0 0 1px 0;
    display: block;
    float: none
}

.tabs.vertical .tab-nav {
    width: 25%;
    float: left
}

.tabs .tab-nav > li:last-child {
    margin-right: 0
}

.tabs.right .tab-nav > li {
    margin: 0 0 0 1px
}

.tabs.right.vertical .tab-nav > li {
    margin: 0 0 1px 0;
    float: none
}

.tabs.right.vertical .tab-nav {
    width: 25%;
    float: right
}

.tabs .tab-nav > li.active a:hover,
.tabs .tab-nav > li.active a {
    background: #ffffff;
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #eeeeee;
    cursor: default
}

.tabs.vertical .tab-nav > li.active a:hover,
.tabs.vertical .tab-nav > li.active a {
    background: #ffffff;
    border-top: 1px solid #eeeeee;
    border-right: 1px solid transparent;
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    cursor: default;
    position: relative
}

.tabs.right.vertical .tab-nav > li.active a:hover,
.tabs.right.vertical .tab-nav > li.active a {
    background: #ffffff;
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid transparent
}

.tabs .tab-nav > li a {
    padding: 1rem 1.5rem;
    margin: 0;
    font-size: 1.2rem;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    display: inline-block;
    color: #666666;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    cursor: pointer
}

.tabs.vertical .tab-nav > li a {
    display: block
}

.tabs .tab-nav > li a:hover {
    text-decoration: none;
    color: #666666;
    background: #d0d0d0;
    border-color: #d0d0d0
}

.tab-panes {
    margin-top: -1px;
    padding: 1.5rem;
    background: #fff;
    border: 1px solid #eeeeee
}

.tabs.vertical .tab-panes {
    width: 75%;
    margin-top: 0;
    margin-left: -1px;
    float: left
}

.tabs.right.vertical .tab-panes {
    margin-left: 0;
    margin-right: -1px;
    float: right
}

.tab-panes > div {
    display: none;
    background: none
}

.tab-panes .tab-content {
    opacity: 0;
    -webkit-transition-property: opacity, visibility;
    transition-property: opacity, visibility;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms
}

.tab-panes .fade-in .tab-content {
    opacity: 1
}

.tab-panes > div.active {
    display: block;
    content: ".";
    overflow: hidden;
    clear: both
}

.tab-panes > div *:first-child {
    margin-top: 0
}

.tab-panes > div *:last-child {
    margin-bottom: 0
}

.tabs.small .tab-nav > li a {
    padding: 0.7rem 1rem;
    font-size: 1rem
}

.tabs.small .tab-panes {
    padding: 1rem
}

.tabs.medium .tab-nav > li a {
    padding: 1rem 1.5rem
}

.tabs.medium .tab-panes {
    padding: 1.5rem
}

.tabs.large .tab-nav > li a {
    padding: 1.3rem 2.5rem;
    font-size: 1.4rem
}

.tabs.large .tab-panes {
    padding: 2.5rem
}

.tabs.xlarge .tab-nav > li a {
    padding: 1.6rem 3rem;
    font-size: 1.5rem
}

.tabs.xlarge .tab-panes {
    padding: 3rem
}

.tabs.rounded .tab-nav > li:first-child a {
    border-radius: 0.3rem 0 0 0
}

.tabs.right.rounded .tab-nav > li:first-child a {
    border-radius: 0 0.3rem 0 0
}

.tabs.rounded .tab-nav > li:last-child a {
    border-radius: 0 0.3rem 0 0
}

.tabs.right.rounded .tab-nav > li:last-child a {
    border-radius: 0.3rem 0 0 0
}

.tabs.vertical.rounded .tab-nav > li:last-child a {
    border-radius: 0.3rem 0 0 0.3rem
}

.tabs.right.vertical.rounded .tab-nav > li:last-child a {
    border-radius: 0.3rem 0 0.3rem 0
}

.tabs.rounded .tab-panes {
    border-radius: 0 0.3rem 0.3rem 0.3rem
}

.tabs.right.rounded .tab-panes {
    border-radius: 0.3rem 0 0.3rem 0.3rem
}

.tabs.solid .tab-nav > li.active a:hover,
.tabs.solid .tab-nav > li.active a,
.tabs.solid .tab-panes {
    border-style: solid
}

.tabs.dotted .tab-nav > li.active a:hover,
.tabs.dotted .tab-nav > li.active a,
.tabs.dotted .tab-panes {
    border-style: dashed
}

.tabs.dashed .tab-nav > li.active a:hover,
.tabs.dashed .tab-nav > li.active a,
.tabs.dashed .tab-panes {
    border-style: dashed
}

.tabs.thick .tab-nav > li.active a:hover,
.tabs.thick .tab-nav > li.active a,
.tabs.thick .tab-panes {
    border-width: 0.2rem
}

.tabs[class*="border-"] {
    border: none
}

.accordion {
    margin: 0 0 3rem 0;
    padding: 0;
    border: none !important
}

.accordion ul {
    margin: 0;
    padding: 0;
    list-style: none
}

.accordion:after {
    height: 0;
    display: block;
    content: ".";
    overflow: hidden;
    clear: both
}

.accordion li {
    margin: 0 0 1px 0;
    display: block
}

.accordion li.active a {
    background: none;
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid transparent;
    border-left: 1px solid #eeeeee
}

.accordion li a {
    padding: 1rem 1.5rem;
    margin: 0;
    font-size: 1.2rem;
    font-weight: normal;
    text-decoration: none;
    vertical-align: middle;
    display: block;
    color: #666666;
    background-color: #eeeeee;
    border: 1px solid #eeeeee;
    cursor: pointer
}

.accordion li a:hover {
    text-decoration: none;
    color: #666666;
    background: #d0d0d0;
    border-color: #d0d0d0
}

.accordion li > div {
    height: 0;
    visibility: hidden;
    border: 1px solid #eeeeee;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.accordion li.active > div {
    height: auto;
    visibility: visible
}

.accordion .accordion-content {
    height: auto;
    padding: 1.5rem;
    overflow: hidden;
    display: block
}

.accordion li > div *:first-child {
    margin-top: 0
}

.accordion li > div *:last-child {
    margin-bottom: 0
}

.accordion.small li a {
    padding: 0.7rem 1rem;
    font-size: 1rem
}

.accordion.small .accordion-content {
    padding: 1rem
}

.accordion.medium li a {
    padding: 1rem 1.5rem
}

.accordion.medium .accordion-content {
    padding: 1.5rem
}

.accordion.large li a {
    padding: 1.3rem 2.5rem;
    font-size: 1.4rem
}

.accordion.large .accordion-content {
    padding: 2.5rem
}

.accordion.xlarge li a {
    padding: 1.6rem 3rem;
    font-size: 1.5rem
}

.accordion.xlarge .accordion-content {
    padding: 3rem
}

.accordion.rounded li:first-child a {
    border-radius: 0.3rem 0.3rem 0 0
}

.accordion.rounded li:last-child a {
    border-radius: 0 0 0.3rem 0.3rem
}

.accordion.rounded li > div {
    border-radius: 0 0 0.3rem 0.3rem
}

.accordion.rounded li.active:last-child a:hover {
    border-radius: 0
}

.accordion.solid li a,
.accordion.solid li > div {
    border-style: solid
}

.accordion.dotted li a,
.accordion.dotted li > div {
    border-style: dashed
}

.accordion.dashed li a,
.accordion.dashed li > div {
    border-style: dashed
}

.accordion.thick li a,
.accordion.thick li > div {
    border-width: 0.2rem
}

table.table {
    width: 100%;
    margin-bottom: 3rem;
    border: 1px solid #eeeeee;
    font-size: 1.2rem;
    line-height: 1.5;
    text-align: left;
    border-collapse: separate;
    border-spacing: 0
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    padding: 1rem 1.5rem
}

.table > thead {
    color: #666666;
    background-color: #eeeeee
}

.table th + th,
.table td {
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee
}

.table > thead > tr > th {
    border-bottom: none
}

.table.small > thead > tr > th,
.table.small > tbody > tr > th,
.table.small > tfoot > tr > th,
.table.small > thead > tr > td,
.table.small > tbody > tr > td,
.table.small > tfoot > tr > td {
    padding: 0.7rem 1rem;
    font-size: 1rem
}

.table.medium > thead > tr > th,
.table.medium > tbody > tr > th,
.table.medium > tfoot > tr > th,
.table.medium > thead > tr > td,
.table.medium > tbody > tr > td,
.table.medium > tfoot > tr > td {
    padding: 1rem 1.5rem
}

.table.large > thead > tr > th,
.table.large > tbody > tr > th,
.table.large > tfoot > tr > th,
.table.large > thead > tr > td,
.table.large > tbody > tr > td,
.table.large > tfoot > tr > td {
    padding: 1.3rem 2.5rem;
    font-size: 1.4rem
}

.table.xlarge > thead > tr > th,
.table.xlarge > tbody > tr > th,
.table.xlarge > tfoot > tr > th,
.table.xlarge > thead > tr > td,
.table.xlarge > tbody > tr > td,
.table.xlarge > tfoot > tr > td {
    padding: 1.6rem 3rem;
    font-size: 1.5rem
}

.table.rounded {
    border-radius: 0.3rem
}

.table.striped tbody tr:nth-child(even) td,
.table.striped tbody tr:nth-child(even) th {
    background-color: #eeeeee
}

.pricing-table {
    width: 100%;
    margin-bottom: 3rem;
    text-align: center
}

.pricing-table:before,
.pricing-table:after {
    content: ".";
    display: block;
    height: 0;
    overflow-x: hidden;
    overflow-y: hidden
}

.pricing-table:after {
    clear: both
}

.pricing-table.columns-1 .pricing-table-column {
    width: 100%
}

.pricing-table.columns-2 .pricing-table-column {
    width: 50%
}

.pricing-table.columns-3 .pricing-table-column {
    width: 33.33333%
}

.pricing-table.columns-4 .pricing-table-column {
    width: 25%
}

.pricing-table.columns-5 .pricing-table-column {
    width: 16.66667%
}

.pricing-table-column {
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    border-left: 1px solid #eeeeee;
    float: left
}

.pricing-table-column.callout {
    margin-left: -1px;
    margin-bottom: -2rem;
    border: 1px solid #ffffff;
    box-shadow: 0rem 0rem 0.8rem rgba(0, 0, 0, .2);
    position: relative;
    top: -1.5rem;
    z-index: 1
}

@media only screen and (min-width: 480px) {
    .pricing-table .pricing-table-column:not(:first-child):not(.callout) {
        border-left: none
    }
}

.pricing-table-header {
    padding: 2.5rem 1.5rem
}

.pricing-table-column.callout .pricing-table-header {
    padding: 3.5rem 1.5rem 3rem 1.5rem
}

.pricing-table-header h2 {
    margin: 0;
    font-size: 2.5rem
}

.pricing-table-header[class*="bkg-"] h2 {
    color: #ffffff
}

.pricing-table-price {
    padding: 1.5rem;
    color: #666666;
    background: #eeeeee
}

.pricing-table-price h4 {
    margin: 0;
    font-size: 1.8rem;
    font-weight: normal
}

.pricing-table-price[class*="bkg-"] h4 {
    color: #ffffff
}

.pricing-table-price .currency {
    font-size: 1.3rem;
    position: relative
}

.pricing-table-price .interval {
    font-size: 1.8rem;
    opacity: 0.5
}

.pricing-table-price + .pricing-table-options {
    padding-top: 1.5rem
}

.pricing-table-options {
    margin: 0;
    padding: 0 0 2rem 0;
    list-style: none;
    line-height: 3
}

.pricing-table-text {
    padding: 0 3rem 2rem 3rem;
    line-height: 1.8
}

.pricing-table-text p:last-child {
    margin-bottom: 0
}

.pricing-table-options li {
    margin-bottom: 0;
    padding: 0 1.5rem
}

.pricing-table-footer {
    padding: 0 1.5rem 2.5rem 1.5rem
}

.pricing-table-column.callout .pricing-table-footer {
    padding: 0 1.5rem 4rem 1.5rem
}

.pricing-table-footer button,
.pricing-table-footer .button {
    margin-bottom: 0
}

.pricing-table[class*="border-"] {
    border-width: 0
}

.pricing-table[class*="border-"] .pricing-table-column {
    border-color: inherit !important
}

.pricing-table.small .pricing-table-header {
    padding: 1.5rem 0.8rem
}

.pricing-table.small .pricing-table-header h2 {
    font-size: 1.7rem
}

.pricing-table.small .pricing-table-price .currency {
    font-size: 1rem
}

.pricing-table.small .pricing-table-price .interval {
    font-size: 1.5rem
}

.pricing-table.small .pricing-table-price h4 {
    font-size: 1.5rem
}

.pricing-table.small .pricing-table-column.callout .pricing-table-header {
    padding: 2.5rem 1.5rem 2rem 1.5rem
}

.pricing-table.small .pricing-table-options {
    padding: 0 0 1.5rem 0
}

.pricing-table.small .pricing-table-column .pricing-table-footer {
    padding: 0 1.5rem 2rem 1.5rem
}

.pricing-table.small .pricing-table-column.callout .pricing-table-footer {
    padding: 0 1.5rem 3.5rem 1.5rem
}

.pricing-table.medium .pricing-table-header {
    padding: 2.5rem 1.5rem
}

.pricing-table.medium .pricing-table-header h2 {
    font-size: 2.5rem
}

.pricing-table.medium .pricing-table-price .currency {
    font-size: 1.3rem
}

.pricing-table.medium .pricing-table-price .interval {
    font-size: 1.8rem
}

.pricing-table.medium .pricing-table-price h4 {
    font-size: 1.8rem
}

.pricing-table.medium .pricing-table-column.callout .pricing-table-header {
    padding: 3.5rem 1.5rem 3rem 1.5rem
}

.pricing-table.medium .pricing-table-options {
    padding: 0 0 2rem 0
}

.pricing-table.medium .pricing-table-column .pricing-table-footer {
    padding: 0 1.5rem 2.5rem 1.5rem
}

.pricing-table.medium .pricing-table-column.callout .pricing-table-footer {
    padding: 0 1.5rem 4rem 1.5rem
}

.pricing-table.large .pricing-table-header {
    padding: 3rem 2rem
}

.pricing-table.large .pricing-table-header h2 {
    font-size: 3rem
}

.pricing-table.large .pricing-table-price .currency {
    font-size: 1.5rem
}

.pricing-table.large .pricing-table-price .interval {
    font-size: 2rem
}

.pricing-table.large .pricing-table-price h4 {
    font-size: 2rem
}

.pricing-table.large .pricing-table-column.callout .pricing-table-header {
    padding: 4rem 1.5rem 3.5rem 1.5rem
}

.pricing-table.large .pricing-table-options {
    padding: 0 0 2.5rem 0
}

.pricing-table.large .pricing-table-column .pricing-table-footer {
    padding: 0 1.5rem 3rem 1.5rem
}

.pricing-table.large .pricing-table-column.callout .pricing-table-footer {
    padding: 0 1.5rem 4.5rem 1.5rem
}

.pricing-table.xlarge .pricing-table-header {
    padding: 3.5rem 2.5rem
}

.pricing-table.xlarge .pricing-table-header h2 {
    font-size: 3.2rem
}

.pricing-table.xlarge .pricing-table-price .currency {
    font-size: 1.7rem
}

.pricing-table.xlarge .pricing-table-price .interval {
    font-size: 2.2rem
}

.pricing-table.xlarge .pricing-table-price h4 {
    font-size: 2.2rem
}

.pricing-table.xlarge .pricing-table-column.callout .pricing-table-header {
    padding: 4.5rem 2rem 4rem 2rem
}

.pricing-table.xlarge .pricing-table-options {
    padding: 0 0 3rem 0
}

.pricing-table.xlarge .pricing-table-column .pricing-table-footer {
    padding: 0 2rem 3.5rem 2rem
}

.pricing-table.xlarge .pricing-table-column.callout .pricing-table-footer {
    padding: 0 2rem 5rem 2rem
}

.pricing-table.rounded .pricing-table-column:first-child {
    border-radius: 0.3rem 0 0 0.3rem
}

.pricing-table.rounded .pricing-table-column:last-child {
    border-radius: 0 0.3rem 0.3rem 0
}

.pricing-table.rounded .pricing-table-column.callout {
    border-radius: 0.3rem
}

.box {
    width: 100%;
    padding: 1.5rem;
    margin-bottom: 3rem;
    border: 1px solid #eeeeee;
    overflow: hidden
}

.box.dotted {
    border-style: dotted
}

.box.dashed {
    border-style: dashed
}

.box > *:first-child {
    margin-top: 0
}

.box *:last-child {
    margin-bottom: 0
}

.box > a:not(.button) {
    color: inherit;
    font-weight: bold
}

.box > a:not(.button):hover {
    color: inherit;
    text-decoration: underline
}

.box.dismissable {
    max-height: 100rem;
    padding-right: 3rem
}

.box.dismissable .close {
    text-decoration: none;
    opacity: 0.5;
    display: block;
    float: right;
    position: relative;
    right: -2rem
}

.box.dismissable .close:hover {
    text-decoration: none;
    opacity: 1
}

.box.small {
    padding: 1rem;
    font-size: 1rem
}

.box.small.dismissable {
    padding-right: 2rem
}

.box.small.dismissable .close {
    right: -1.5rem
}

.box.medium {
    padding: 1.5rem
}

.box.large {
    padding: 2.5rem;
    font-size: 1.4rem
}

.box.large.dismissable {
    padding-right: 5rem
}

.box.large.dismissable .close {
    right: -3rem
}

.box.xlarge {
    padding: 3rem;
    font-size: 1.5rem
}

.box.xlarge.dismissable {
    padding-right: 6rem
}

.box.xlarge.dismissable .close {
    right: -3.5rem
}

.box.rounded {
    border-radius: 0.3rem
}

.label {
    padding: 0.2rem 0.6rem 0.3rem 0.6rem;
    background: #eeeeee;
    color: #666666;
    border: 1px solid #eeeeee
}

.label [class*="icon-"]:before {
    margin-left: 0;
    margin-right: 0.5rem
}

.label.rounded {
    border-radius: 0.3rem
}

.progress-bar-group {
    margin-bottom: 3rem
}

.progress-bar-group .progress-bar:last-child {
    margin-bottom: 0
}

.progress-bar {
    width: 100%;
    height: 2rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: bold;
    text-align: right;
    color: #666666;
    border: 1px solid #eeeeee;
    background: #eeeeee;
    overflow: hidden;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC )
}

.aux-navigation-active .progress-bar {
    -webkit-mask-image: none
}

.bar {
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
    border: 1px solid #d0d0d0;
    background: #d0d0d0;
    -webkit-backface-visibility: hidden
}

.progress-bar-label {
    margin-bottom: 0.8rem;
    color: #666;
    font-size: 1.2rem;
    display: block
}

.progress-bar-label + .progress-bar {
    margin-bottom: 1.7rem
}

.progress-bar.small {
    height: 1.3rem;
    font-size: 1rem;
    line-height: 0.8rem
}

.progress-bar.medium {
    height: 2rem
}

.progress-bar.large {
    height: 3rem;
    font-size: 1.4rem;
    line-height: 2.6rem
}

.progress-bar.xlarge {
    height: 4rem;
    font-size: 1.5rem;
    line-height: 3.6rem
}

.progress-bar.rounded,
.progress-bar.rounded .bar {
    border-radius: 0.3rem
}

.progress-bar.pill,
.progress-bar.pill .bar {
    border-radius: 10.5rem
}

.video-container {
    max-width: 100%;
    overflow: hidden
}

.video-container,
.audio-container {
    margin-bottom: 3rem
}

.audio-container .mejs-container {
    height: 3rem
}

iframe {
    width: 100%;
    border: none;
    overflow: hidden
}

.divider,
hr {
    margin: 3rem 0;
    border-top: 1px solid #eeeeee;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
    height: 0;
    clear: both
}

.divider.dashed,
hr.dashed {
    border-style: dashed
}

.divider.dotted,
hr.dotted {
    border-style: dotted
}

.divider.thick,
hr.thick {
    border-top-width: 0.2rem
}

.divider[class*="border-"],
hr[class*="border-"],
.divider[class*="border-"].dashed,
.divider[class*="border-"].dotted,
hr[class*="border-"].dashed,
hr[class*="border-"].dotted {
    border-right-style: none !important;
    border-bottom-style: none !important;
    border-left-style: none !important
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #333333;
    font-weight: bold;
    line-height: 1;
    margin: 0 0 2rem 0
}

h1 {
    font-size: 4.8rem
}

h2 {
    font-size: 3.2rem
}

h3 {
    font-size: 2.4rem
}

h3.widget-title {
    font-size: 2.1rem
}

h4 {
    font-size: 2.1rem
}

h5 {
    font-size: 1.8rem
}

h6 {
    font-size: 1.6rem
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
    color: inherit
}

p {
    margin: 0 0 3rem 0
}

h1[class*="border-"],
h1[class*="bkg-"],
h1 span[class*="border-"],
h1 span[class*="bkg-"],
h2[class*="border-"],
h2[class*="bkg-"],
h2 span[class*="border-"],
h2 span[class*="bkg-"],
h3[class*="border-"],
h3[class*="bkg-"],
h3 span[class*="border-"],
h3 span[class*="bkg-"],
h4[class*="border-"],
h4[class*="bkg-"],
h4 span[class*="border-"],
h4 span[class*="bkg-"],
h5[class*="border-"],
h5[class*="bkg-"],
h5 span[class*="border-"],
h5 span[class*="bkg-"],
h6[class*="border-"],
h6[class*="bkg-"],
h6 span[class*="border-"],
h6 span[class*="bkg-"],
p[class*="border-"],
p[class*="bkg-"] {
    padding: 0.4rem 0.6rem
}

address {
    margin: 0 0 3rem 0
}

.lead {
    margin-bottom: 3rem;
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: 200
}

.separator {
    width: 10rem;
    height: 0.4rem;
    display: block;
    background-color: #333333;
    margin-bottom: 2rem
}

.separator.small {
    width: 7rem;
    height: 0.2rem
}

.separator.large {
    width: 13rem;
    height: 0.6rem
}

.separator.xlarge {
    width: 16rem;
    height: 0.8rem
}

.center .separator,
.separator.center {
    margin-right: auto;
    margin-left: auto
}

.right .separator,
.separator.right {
    margin-left: auto
}

.font-alt-1 {
    font-family: "Helvetica Neue", "Helvetica", Helvetica, Arial, sans-serif
}

.font-alt-1 {
    font-family: "Times New Roman", Arial, sans-serif
}

em {
    font-style: italic
}

strong {
    font-weight: bold
}

small {
    font-size: 65%
}

[class*="weight-"] p,
[class*="weight-"] span,
[class*="weight-"] a {
    font-weight: inherit
}

.weight-light {
    font-weight: 100
}

.weight-thin {
    font-weight: 200
}

.weight-regular {
    font-weight: 400
}

.weight-semi-bold {
    font-weight: 600
}

.weight-bold {
    font-weight: 700
}

.weight-xbold {
    font-weight: 900
}

.text-shadow {
    text-shadow: 0.2rem 0.2rem 0.2rem rgba(0, 0, 0, 0.5)
}

[class*="lspacing-"] p,
[class*="lspacing-"] span,
[class*="lspacing-"] a {
    letter-spacing: inherit
}

.lspacing-small {
    letter-spacing: 0.2rem
}

.lspacing-medium {
    letter-spacing: 0.3rem
}

.lspacing-large {
    letter-spacing: 0.5rem
}

.lspacing-xlarge {
    letter-spacing: 0.8rem
}

a,
p a {
    color: #666666;
    text-decoration: none;
    outline: none
}

a:hover,
p a:hover {
    color: #333333;
    text-decoration: none
}

::-moz-selection {
    background: #333333;
    color: #ffffff
}

::selection {
    background: #333333;
    color: #ffffff
}

::-moz-selection {
    background: #333333;
    color: #ffffff
}

blockquote {
    margin: 0 0 3rem 0;
    font-size: 1.8rem;
    line-height: 1.5
}

blockquote p {
    margin-bottom: 0;
    font-size: 1.8rem
}

blockquote cite,
blockquote .cite {
    margin-top: 2rem;
    font-size: 60%;
    display: block
}

blockquote.small,
blockquote.small p {
    font-size: 1.6rem
}

blockquote.medium,
blockquote.medium p {
    font-size: 1.8rem
}

blockquote.large,
blockquote.large p {
    font-size: 2.1rem
}

blockquote.xlarge,
blockquote.xlarge p {
    font-size: 2.4rem
}

blockquote.border {
    padding: 0 0 0 2.5rem;
    border-left: 1px solid #eeeeee
}

blockquote.border.small {
    padding-left: 2rem
}

blockquote.border.medium {
    padding-left: 2.5rem
}

blockquote.border.large {
    padding-left: 3rem
}

blockquote.border.xlarge {
    padding-left: 3.5rem
}

blockquote.border.small {
    padding-right: 2rem
}

blockquote.border.medium {
    padding-right: 2.5rem
}

blockquote.border.large {
    padding-right: 3rem
}

blockquote.border.xlarge {
    padding-right: 3.5rem
}

blockquote.icon [class*="icon-"] {
    font-size: 150%;
    text-align: center;
    line-height: 0
}

blockquote.avatar span,
blockquote.avatar span img {
    width: 5rem;
    height: 5rem;
    border-radius: 7rem;
    display: inline-block
}

blockquote.avatar.small span,
blockquote.avatar.small span img {
    width: 4rem;
    height: 4rem
}

blockquote.avatar.medium span,
blockquote.avatar.medium span img {
    width: 5rem;
    height: 5rem
}

blockquote.avatar.large span,
blockquote.avatar.large span img {
    width: 6rem;
    height: 6rem
}

blockquote.avatar.xlarge span,
blockquote.avatar.xlarge span img {
    width: 7rem;
    height: 7rem
}

blockquote.pull-left {
    width: 40%;
    float: left;
    margin-right: 2rem;
    margin-bottom: 2rem
}

blockquote.pull-right {
    width: 40%;
    float: right;
    margin-left: 2rem;
    margin-bottom: 2rem
}

blockquote.center {
    margin-left: auto;
    margin-right: auto
}

blockquote.border.right {
    padding: 0 2.5rem 0 0;
    border-left: none;
    border-right: 1px solid #eeeeee
}

blockquote.border.center {
    margin-left: auto;
    margin-right: auto;
    padding: 2.5rem 0 0 0;
    border-left: none;
    border-top: 1px solid #eeeeee
}

.breadcrumb {
    list-style: none;
    overflow: hidden
}

.breadcrumb li {
    float: left
}

.breadcrumb li,
.breadcrumb li a {
    font-size: 1.2rem
}

.breadcrumb li:after,
.breadcrumb li:before {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    font-family: 'icomoon'
}

.breadcrumb li:after {
    content: "\e640"
}

.breadcrumb.center li {
    display: inline-block;
    float: none
}

.breadcrumb.right li:after {
    padding: 0;
    content: ""
}

.breadcrumb.right li:before {
    content: "\e63f"
}

.breadcrumb li:last-child:after,
.breadcrumb.right li:first-child:before {
    content: "";
    padding: 0
}

code,
samp,
kbd {
    font-family: "Courier New", Courier, monospace, sans-serif;
    text-align: left
}

pre {
    max-width: 100%;
    margin: 0;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word
}

pre code {
    line-height: 1.5;
    white-space: pre-wrap
}

.tag {
    color: #074a72
}

.tag .att {
    color: #47a4db
}

.tag .val {
    color: #d74950
}

.tag .comm {
    color: #999999
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/entypo.eot?-r9lvae');
    src: url('../fonts/entypo.eot?#iefix-r9lvae') format('embedded-opentype'), url('../fonts/entypo.woff?-r9lvae') format('woff'), url('../fonts/entypo.ttf?-r9lvae') format('truetype'), url('../fonts/entypo.svg?-r9lvae#icomoon') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="icon-"],
[class*=" icon-"] {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.icon-add-to-list:before {
    content: "\e600"
}

.icon-add-user:before {
    content: "\e601"
}

.icon-address:before {
    content: "\e602"
}

.icon-adjust:before {
    content: "\e603"
}

.icon-air:before {
    content: "\e604"
}

.icon-aircraft-landing:before {
    content: "\e605"
}

.icon-aircraft-take-off:before {
    content: "\e606"
}

.icon-aircraft:before {
    content: "\e607"
}

.icon-align-bottom:before {
    content: "\e608"
}

.icon-align-horizontal-middle:before {
    content: "\e609"
}

.icon-align-left:before {
    content: "\e60a"
}

.icon-align-right:before {
    content: "\e60b"
}

.icon-align-top:before {
    content: "\e60c"
}

.icon-align-vertical-middle:before {
    content: "\e60d"
}

.icon-archive:before {
    content: "\e60e"
}

.icon-area-graph:before {
    content: "\e60f"
}

.icon-arrow-bold-down:before {
    content: "\e610"
}

.icon-arrow-bold-left:before {
    content: "\e611"
}

.icon-arrow-bold-right:before {
    content: "\e612"
}

.icon-arrow-bold-up:before {
    content: "\e613"
}

.icon-arrow-down:before {
    content: "\e614"
}

.icon-arrow-left:before {
    content: "\e615"
}

.icon-arrow-long-down:before {
    content: "\e616"
}

.icon-arrow-long-left:before {
    content: "\e617"
}

.icon-arrow-long-right:before {
    content: "\e618"
}

.icon-arrow-long-up:before {
    content: "\e619"
}

.icon-arrow-right:before {
    content: "\e61a"
}

.icon-arrow-up:before {
    content: "\e61b"
}

.icon-arrow-with-circle-down:before {
    content: "\e61c"
}

.icon-arrow-with-circle-left:before {
    content: "\e61d"
}

.icon-arrow-with-circle-right:before {
    content: "\e61e"
}

.icon-arrow-with-circle-up:before {
    content: "\e61f"
}

.icon-attachment:before {
    content: "\e620"
}

.icon-awareness-ribbon:before {
    content: "\e621"
}

.icon-back-in-time:before {
    content: "\e622"
}

.icon-back:before {
    content: "\e623"
}

.icon-bar-graph:before {
    content: "\e624"
}

.icon-battery:before {
    content: "\e625"
}

.icon-beamed-note:before {
    content: "\e626"
}

.icon-bell:before {
    content: "\e627"
}

.icon-blackboard:before {
    content: "\e628"
}

.icon-block:before {
    content: "\e629"
}

.icon-book:before {
    content: "\e62a"
}

.icon-bookmark:before {
    content: "\e62b"
}

.icon-bookmarks:before {
    content: "\e62c"
}

.icon-bowl:before {
    content: "\e62d"
}

.icon-box:before {
    content: "\e62e"
}

.icon-briefcase:before {
    content: "\e62f"
}

.icon-browser:before {
    content: "\e630"
}

.icon-brush:before {
    content: "\e631"
}

.icon-bucket:before {
    content: "\e632"
}

.icon-bug:before {
    content: "\e633"
}

.icon-cake:before {
    content: "\e634"
}

.icon-calculator:before {
    content: "\e635"
}

.icon-calendar:before {
    content: "\e636"
}

.icon-camera:before {
    content: "\e637"
}

.icon-ccw:before {
    content: "\e638"
}

.icon-chat:before {
    content: "\e639"
}

.icon-check:before {
    content: "\e63a"
}

.icon-down:before {
    content: "\e63b"
}

.icon-left:before {
    content: "\e63c"
}

.icon-right:before {
    content: "\e63d"
}

.icon-down-open-mini:before {
    content: "\e63e"
}

.icon-left-open-mini:before {
    content: "\e63f"
}

.icon-right-open-mini:before {
    content: "\e640"
}

.icon-up-open-mini:before {
    content: "\e641"
}

.icon-down-open-big:before {
    content: "\e642"
}

.icon-left-open-big:before {
    content: "\e643"
}

.icon-right-open-big:before {
    content: "\e644"
}

.icon-up-open-big:before {
    content: "\e645"
}

.icon-up:before {
    content: "\e646"
}

.icon-down-circled:before {
    content: "\e647"
}

.icon-left-circled:before {
    content: "\e648"
}

.icon-right-circled:before {
    content: "\e649"
}

.icon-up-circled:before {
    content: "\e64a"
}

.icon-circle-with-cross:before {
    content: "\e64b"
}

.icon-circle-with-minus:before {
    content: "\e64c"
}

.icon-circle-with-plus:before {
    content: "\e64d"
}

.icon-circle:before {
    content: "\e64e"
}

.icon-circular-graph:before {
    content: "\e64f"
}

.icon-clapperboard:before {
    content: "\e650"
}

.icon-classic-computer:before {
    content: "\e651"
}

.icon-clipboard:before {
    content: "\e652"
}

.icon-clock:before {
    content: "\e653"
}

.icon-cloud:before {
    content: "\e654"
}

.icon-code:before {
    content: "\e655"
}

.icon-cog:before {
    content: "\e656"
}

.icon-colours:before {
    content: "\e657"
}

.icon-compass:before {
    content: "\e658"
}

.icon-fast-backward:before {
    content: "\e659"
}

.icon-fast-forward:before {
    content: "\e65a"
}

.icon-jump-to-start:before {
    content: "\e65b"
}

.icon-next:before {
    content: "\e65c"
}

.icon-paus:before {
    content: "\e65d"
}

.icon-play:before {
    content: "\e65e"
}

.icon-record:before {
    content: "\e65f"
}

.icon-stop:before {
    content: "\e660"
}

.icon-volume:before {
    content: "\e661"
}

.icon-copy:before {
    content: "\e662"
}

.icon-creative-commons-attribution:before {
    content: "\e663"
}

.icon-creative-commons-noderivs:before {
    content: "\e664"
}

.icon-creative-commons-noncommercial-eu:before {
    content: "\e665"
}

.icon-creative-commons-noncommercial-us:before {
    content: "\e666"
}

.icon-creative-commons-public-domain:before {
    content: "\e667"
}

.icon-creative-commons-remix:before {
    content: "\e668"
}

.icon-creative-commons-share:before {
    content: "\e669"
}

.icon-creative-commons-sharealike:before {
    content: "\e66a"
}

.icon-creative-commons:before {
    content: "\e66b"
}

.icon-credit-card:before {
    content: "\e66c"
}

.icon-credit:before {
    content: "\e66d"
}

.icon-crop:before {
    content: "\e66e"
}

.icon-cancel:before {
    content: "\e66f"
}

.icon-cup:before {
    content: "\e670"
}

.icon-cw:before {
    content: "\e671"
}

.icon-cycle:before {
    content: "\e672"
}

.icon-database:before {
    content: "\e673"
}

.icon-dial-pad:before {
    content: "\e674"
}

.icon-direction:before {
    content: "\e675"
}

.icon-document-landscape:before {
    content: "\e676"
}

.icon-document:before {
    content: "\e677"
}

.icon-documents:before {
    content: "\e678"
}

.icon-dot-single:before {
    content: "\e679"
}

.icon-dots-three-horizontal:before {
    content: "\e67a"
}

.icon-dots-three-vertical:before {
    content: "\e67b"
}

.icon-dots-two-horizontal:before {
    content: "\e67c"
}

.icon-dots-two-vertical:before {
    content: "\e67d"
}

.icon-download:before {
    content: "\e67e"
}

.icon-drink:before {
    content: "\e67f"
}

.icon-drive:before {
    content: "\e680"
}

.icon-drop:before {
    content: "\e681"
}

.icon-edit:before {
    content: "\e682"
}

.icon-email:before {
    content: "\e683"
}

.icon-emoji-flirt:before {
    content: "\e684"
}

.icon-emoji-happy:before {
    content: "\e685"
}

.icon-emoji-neutral:before {
    content: "\e686"
}

.icon-emoji-sad:before {
    content: "\e687"
}

.icon-erase:before {
    content: "\e688"
}

.icon-eraser:before {
    content: "\e689"
}

.icon-export:before {
    content: "\e68a"
}

.icon-eye-with-line:before {
    content: "\e68b"
}

.icon-eye:before {
    content: "\e68c"
}

.icon-feather:before {
    content: "\e68d"
}

.icon-fingerprint:before {
    content: "\e68e"
}

.icon-flag:before {
    content: "\e68f"
}

.icon-flash:before {
    content: "\e690"
}

.icon-flashlight:before {
    content: "\e691"
}

.icon-flat-brush:before {
    content: "\e692"
}

.icon-flow-branch:before {
    content: "\e693"
}

.icon-flow-cascade:before {
    content: "\e694"
}

.icon-flow-line:before {
    content: "\e695"
}

.icon-flow-parallel:before {
    content: "\e696"
}

.icon-flow-tree:before {
    content: "\e697"
}

.icon-flower:before {
    content: "\e698"
}

.icon-folder-images:before {
    content: "\e699"
}

.icon-folder-music:before {
    content: "\e69a"
}

.icon-folder-video:before {
    content: "\e69b"
}

.icon-folder:before {
    content: "\e69c"
}

.icon-forward:before {
    content: "\e69d"
}

.icon-funnel:before {
    content: "\e69e"
}

.icon-game-controller:before {
    content: "\e69f"
}

.icon-gauge:before {
    content: "\e6a0"
}

.icon-globe:before {
    content: "\e6a1"
}

.icon-graduation-cap:before {
    content: "\e6a2"
}

.icon-grid:before {
    content: "\e6a3"
}

.icon-hair-cross:before {
    content: "\e6a4"
}

.icon-hand:before {
    content: "\e6a5"
}

.icon-heart-outlined:before {
    content: "\e6a6"
}

.icon-heart:before {
    content: "\e6a7"
}

.icon-help-with-circle:before {
    content: "\e6a8"
}

.icon-help:before {
    content: "\e6a9"
}

.icon-home:before {
    content: "\e6aa"
}

.icon-hour-glass:before {
    content: "\e6ab"
}

.icon-image-inverted:before {
    content: "\e6ac"
}

.icon-image:before {
    content: "\e6ad"
}

.icon-images:before {
    content: "\e6ae"
}

.icon-inbox:before {
    content: "\e6af"
}

.icon-infinity:before {
    content: "\e6b0"
}

.icon-info-with-circle:before {
    content: "\e6b1"
}

.icon-info:before {
    content: "\e6b2"
}

.icon-install:before {
    content: "\e6b3"
}

.icon-key:before {
    content: "\e6b4"
}

.icon-keyboard:before {
    content: "\e6b5"
}

.icon-lab-flask:before {
    content: "\e6b6"
}

.icon-landline:before {
    content: "\e6b7"
}

.icon-language:before {
    content: "\e6b8"
}

.icon-laptop:before {
    content: "\e6b9"
}

.icon-layers:before {
    content: "\e6ba"
}

.icon-leaf:before {
    content: "\e6bb"
}

.icon-level-down:before {
    content: "\e6bc"
}

.icon-level-up:before {
    content: "\e6bd"
}

.icon-lifebuoy:before {
    content: "\e6be"
}

.icon-light-bulb:before {
    content: "\e6bf"
}

.icon-light-down:before {
    content: "\e6c0"
}

.icon-light-up:before {
    content: "\e6c1"
}

.icon-line-graph:before {
    content: "\e6c2"
}

.icon-link:before {
    content: "\e6c3"
}

.icon-list:before {
    content: "\e6c4"
}

.icon-location-pin:before {
    content: "\e6c5"
}

.icon-location:before {
    content: "\e6c6"
}

.icon-lock-open:before {
    content: "\e6c7"
}

.icon-lock:before {
    content: "\e6c8"
}

.icon-log-out:before {
    content: "\e6c9"
}

.icon-login:before {
    content: "\e6ca"
}

.icon-loop:before {
    content: "\e6cb"
}

.icon-magnet:before {
    content: "\e6cc"
}

.icon-magnifying-glass:before {
    content: "\e6cd"
}

.icon-mail:before {
    content: "\e6ce"
}

.icon-man:before {
    content: "\e6cf"
}

.icon-map:before {
    content: "\e6d0"
}

.icon-mask:before {
    content: "\e6d1"
}

.icon-medal:before {
    content: "\e6d2"
}

.icon-megaphone:before {
    content: "\e6d3"
}

.icon-menu:before {
    content: "\e6d4"
}

.icon-merge:before {
    content: "\e6d5"
}

.icon-message:before {
    content: "\e6d6"
}

.icon-mic:before {
    content: "\e6d7"
}

.icon-minus:before {
    content: "\e6d8"
}

.icon-mobile:before {
    content: "\e6d9"
}

.icon-modern-mic:before {
    content: "\e6da"
}

.icon-moon:before {
    content: "\e6db"
}

.icon-mouse-pointer:before {
    content: "\e6dc"
}

.icon-mouse:before {
    content: "\e6dd"
}

.icon-music:before {
    content: "\e6de"
}

.icon-network:before {
    content: "\e6df"
}

.icon-new-message:before {
    content: "\e6e0"
}

.icon-new:before {
    content: "\e6e1"
}

.icon-news:before {
    content: "\e6e2"
}

.icon-newsletter:before {
    content: "\e6e3"
}

.icon-note:before {
    content: "\e6e4"
}

.icon-notification:before {
    content: "\e6e5"
}

.icon-notifications-off:before {
    content: "\e6e6"
}

.icon-old-mobile:before {
    content: "\e6e7"
}

.icon-old-phone:before {
    content: "\e6e8"
}

.icon-open-book:before {
    content: "\e6e9"
}

.icon-palette:before {
    content: "\e6ea"
}

.icon-paper-plane:before {
    content: "\e6eb"
}

.icon-pencil:before {
    content: "\e6ec"
}

.icon-phone:before {
    content: "\e6ed"
}

.icon-pie-chart:before {
    content: "\e6ee"
}

.icon-pin:before {
    content: "\e6ef"
}

.icon-plus:before {
    content: "\e6f0"
}

.icon-popup:before {
    content: "\e6f1"
}

.icon-power-plug:before {
    content: "\e6f2"
}

.icon-price-ribbon:before {
    content: "\e6f3"
}

.icon-price-tag:before {
    content: "\e6f4"
}

.icon-print:before {
    content: "\e6f5"
}

.icon-progress-empty:before {
    content: "\e6f6"
}

.icon-progress-full:before {
    content: "\e6f7"
}

.icon-progress-one:before {
    content: "\e6f8"
}

.icon-progress-two:before {
    content: "\e6f9"
}

.icon-publish:before {
    content: "\e6fa"
}

.icon-quote:before {
    content: "\e6fb"
}

.icon-radio:before {
    content: "\e6fc"
}

.icon-remove-user:before {
    content: "\e6fd"
}

.icon-reply-all:before {
    content: "\e6fe"
}

.icon-reply:before {
    content: "\e6ff"
}

.icon-resize-100:before {
    content: "\e700"
}

.icon-resize-full-screen:before {
    content: "\e701"
}

.icon-retweet:before {
    content: "\e702"
}

.icon-rocket:before {
    content: "\e703"
}

.icon-round-brush:before {
    content: "\e704"
}

.icon-rss:before {
    content: "\e705"
}

.icon-ruler:before {
    content: "\e706"
}

.icon-save:before {
    content: "\e707"
}

.icon-scissors:before {
    content: "\e708"
}

.icon-select-arrows:before {
    content: "\e709"
}

.icon-share-alternative:before {
    content: "\e70a"
}

.icon-share:before {
    content: "\e70b"
}

.icon-shareable:before {
    content: "\e70c"
}

.icon-shield:before {
    content: "\e70d"
}

.icon-shop:before {
    content: "\e70e"
}

.icon-shopping-bag:before {
    content: "\e70f"
}

.icon-shopping-basket:before {
    content: "\e710"
}

.icon-shopping-cart:before {
    content: "\e711"
}

.icon-shuffle:before {
    content: "\e712"
}

.icon-signal:before {
    content: "\e713"
}

.icon-sound-mix:before {
    content: "\e714"
}

.icon-sound-mute:before {
    content: "\e715"
}

.icon-sound:before {
    content: "\e716"
}

.icon-sports-club:before {
    content: "\e717"
}

.icon-spreadsheet:before {
    content: "\e718"
}

.icon-squared-cross:before {
    content: "\e719"
}

.icon-squared-minus:before {
    content: "\e71a"
}

.icon-squared-plus:before {
    content: "\e71b"
}

.icon-star-outlined:before {
    content: "\e71c"
}

.icon-star:before {
    content: "\e71d"
}

.icon-stopwatch:before {
    content: "\e71e"
}

.icon-suitcase:before {
    content: "\e71f"
}

.icon-swap:before {
    content: "\e720"
}

.icon-sweden:before {
    content: "\e721"
}

.icon-switch:before {
    content: "\e722"
}

.icon-tablet-mobile-combo:before {
    content: "\e723"
}

.icon-tablet:before {
    content: "\e724"
}

.icon-tag:before {
    content: "\e725"
}

.icon-text-document-inverted:before {
    content: "\e726"
}

.icon-text-document:before {
    content: "\e727"
}

.icon-text:before {
    content: "\e728"
}

.icon-thermometer:before {
    content: "\e729"
}

.icon-thumbs-down:before {
    content: "\e72a"
}

.icon-thumbs-up:before {
    content: "\e72b"
}

.icon-thunder-cloud:before {
    content: "\e72c"
}

.icon-ticket:before {
    content: "\e72d"
}

.icon-time-slot:before {
    content: "\e72e"
}

.icon-tools:before {
    content: "\e72f"
}

.icon-traffic-cone:before {
    content: "\e730"
}

.icon-trash:before {
    content: "\e731"
}

.icon-tree:before {
    content: "\e732"
}

.icon-triangle-down:before {
    content: "\e733"
}

.icon-triangle-left:before {
    content: "\e734"
}

.icon-triangle-right:before {
    content: "\e735"
}

.icon-triangle-up:before {
    content: "\e736"
}

.icon-trophy:before {
    content: "\e737"
}

.icon-tv:before {
    content: "\e738"
}

.icon-typing:before {
    content: "\e739"
}

.icon-uninstall:before {
    content: "\e73a"
}

.icon-unread:before {
    content: "\e73b"
}

.icon-untag:before {
    content: "\e73c"
}

.icon-upload-to-cloud:before {
    content: "\e73d"
}

.icon-upload:before {
    content: "\e73e"
}

.icon-user:before {
    content: "\e73f"
}

.icon-users:before {
    content: "\e740"
}

.icon-v-card:before {
    content: "\e741"
}

.icon-video-camera:before {
    content: "\e742"
}

.icon-video:before {
    content: "\e743"
}

.icon-vinyl:before {
    content: "\e744"
}

.icon-voicemail:before {
    content: "\e745"
}

.icon-wallet:before {
    content: "\e746"
}

.icon-warning:before {
    content: "\e747"
}

.icon-water:before {
    content: "\e748"
}

.icon-px-with-circle:before {
    content: "\e749"
}

.icon-px:before {
    content: "\e74a"
}

.icon-app-store:before {
    content: "\e74b"
}

.icon-baidu:before {
    content: "\e74c"
}

.icon-basecamp:before {
    content: "\e74d"
}

.icon-behance:before {
    content: "\e74e"
}

.icon-creative-cloud:before {
    content: "\e74f"
}

.icon-dribbble-with-circle:before {
    content: "\e750"
}

.icon-dribbble:before {
    content: "\e751"
}

.icon-dropbox:before {
    content: "\e752"
}

.icon-evernote:before {
    content: "\e753"
}

.icon-facebook-with-circle:before {
    content: "\e754"
}

.icon-facebook:before {
    content: "\e755"
}

.icon-flattr:before {
    content: "\e756"
}

.icon-flickr-with-circle:before {
    content: "\e757"
}

.icon-flickr:before {
    content: "\e758"
}

.icon-foursquare:before {
    content: "\e759"
}

.icon-github-with-circle:before {
    content: "\e75a"
}

.icon-github:before {
    content: "\e75b"
}

.icon-google-drive:before {
    content: "\e75c"
}

.icon-google-hangouts:before {
    content: "\e75d"
}

.icon-google-play:before {
    content: "\e75e"
}

.icon-google-with-circle:before {
    content: "\e75f"
}

.icon-google:before {
    content: "\e760"
}

.icon-grooveshark:before {
    content: "\e761"
}

.icon-houzz:before {
    content: "\e762"
}

.icon-icloud:before {
    content: "\e763"
}

.icon-instagram-with-circle:before {
    content: "\e764"
}

.icon-instagram:before {
    content: "\e765"
}

.icon-lastfm-with-circle:before {
    content: "\e766"
}

.icon-lastfm:before {
    content: "\e767"
}

.icon-linkedin-with-circle:before {
    content: "\e768"
}

.icon-linkedin:before {
    content: "\e769"
}

.icon-mail-with-circle:before {
    content: "\e76a"
}

.icon-medium-with-circle:before {
    content: "\e76b"
}

.icon-medium:before {
    content: "\e76c"
}

.icon-mixi:before {
    content: "\e76d"
}

.icon-onedrive:before {
    content: "\e76e"
}

.icon-paypal:before {
    content: "\e76f"
}

.icon-picasa:before {
    content: "\e770"
}

.icon-pinterest-with-circle:before {
    content: "\e771"
}

.icon-pinterest:before {
    content: "\e772"
}

.icon-qq-with-circle:before {
    content: "\e773"
}

.icon-qq:before {
    content: "\e774"
}

.icon-raft-with-circle:before {
    content: "\e775"
}

.icon-raft:before {
    content: "\e776"
}

.icon-rainbow:before {
    content: "\e777"
}

.icon-rdio-with-circle:before {
    content: "\e778"
}

.icon-rdio:before {
    content: "\e779"
}

.icon-renren:before {
    content: "\e77a"
}

.icon-scribd:before {
    content: "\e77b"
}

.icon-sina-weibo:before {
    content: "\e77c"
}

.icon-skype-with-circle:before {
    content: "\e77d"
}

.icon-skype:before {
    content: "\e77e"
}

.icon-slideshare:before {
    content: "\e77f"
}

.icon-smashing:before {
    content: "\e780"
}

.icon-soundcloud:before {
    content: "\e781"
}

.icon-spotify-with-circle:before {
    content: "\e782"
}

.icon-spotify:before {
    content: "\e783"
}

.icon-stumbleupon-with-circle:before {
    content: "\e784"
}

.icon-stumbleupon:before {
    content: "\e785"
}

.icon-swarm:before {
    content: "\e786"
}

.icon-tripadvisor:before {
    content: "\e787"
}

.icon-tumblr-with-circle:before {
    content: "\e788"
}

.icon-tumblr:before {
    content: "\e789"
}

.icon-twitter-with-circle:before {
    content: "\e78a"
}

.icon-twitter:before {
    content: "\e78b"
}

.icon-vimeo-with-circle:before {
    content: "\e78c"
}

.icon-vimeo:before {
    content: "\e78d"
}

.icon-vine-with-circle:before {
    content: "\e78e"
}

.icon-vine:before {
    content: "\e78f"
}

.icon-vk-alternitive:before {
    content: "\e790"
}

.icon-vk-with-circle:before {
    content: "\e791"
}

.icon-vk:before {
    content: "\e792"
}

.icon-windows-store:before {
    content: "\e793"
}

.icon-xing-with-circle:before {
    content: "\e794"
}

.icon-xing:before {
    content: "\e795"
}

.icon-yelp:before {
    content: "\e796"
}

.icon-youko-with-circle:before {
    content: "\e797"
}

.icon-youko:before {
    content: "\e798"
}

.icon-youtube-with-circle:before {
    content: "\e799"
}

.icon-youtube:before {
    content: "\e79a"
}

[class*="icon-"].small {
    font-size: 1.6rem
}

[class*="icon-"].medium {
    font-size: 2.4rem
}

[class*="icon-"].large {
    font-size: 3.2rem
}

[class*="icon-"].xlarge {
    font-size: 4.8rem
}

.icon-boxed,
.icon-circled {
    width: 6.6rem;
    height: 6.6rem;
    margin-bottom: 0.4rem;
    line-height: 6.6rem;
    text-align: center;
    border: 1px solid #eeeeee
}

.icon-boxed.small,
.icon-circled.small {
    width: 5.8rem;
    height: 5.8rem;
    line-height: 5.6rem
}

.icon-boxed.medium,
.icon-circled.medium {
    width: 6.6rem;
    height: 6.6rem;
    line-height: 6.4rem
}

.icon-boxed.large,
.icon-circled.large {
    width: 7.4rem;
    height: 7.4rem;
    line-height: 7.2rem
}

.icon-boxed.xlarge,
.icon-circled.xlarge {
    width: 9rem;
    height: 9rem;
    line-height: 8.8rem
}

.icon-boxed.rounded {
    border-radius: 0.3rem
}

.icon-circled {
    border-radius: 10rem
}

[class*="border-"].padded,
[class*="bkg-"].padded {
    padding: 1rem 2rem
}

[class*="border-"] {
    border: 1px solid #eee
}

[class*="border-"]:not([class*="bkg-"]),
[class*="border-"][class*="bkg-hover-"],
[class*="border-hover-"]:hover {
    background: none;
    background: rgba(0, 0, 0, 0)
}

[class*="border-"].solid {
    border-style: solid;
    border-width: 1px
}

[class*="border-"].dashed {
    border-style: dashed;
    border-width: 1px
}

[class*="border-"].dotted {
    border-style: dotted;
    border-width: 1px
}

[class*="border-"].thick,
[class*="border-"].thick * {
    border-width: 0.2rem
}

.border-turquoise {
    border-color: #00a186
}

.border-hover-turquoise:hover {
    border-color: #008b73
}

.border-turquoise-light {
    border-color: #00bd9c
}

.border-hover-turquoise-light:hover {
    border-color: #00a186
}

.border-green {
    border-color: #1bb05d;
    color: #1bb05d
}

.border-hover-green:hover {
    border-color: #179851
}

.border-green-light {
    border-color: #1ecd6d
}

.border-hover-green-light:hover {
    border-color: #1bb05d
}

.border-blue {
    border-color: #227fbb
}

.border-hover-blue:hover {
    border-color: #1d6ea3
}

.border-blue-light {
    border-color: #2c96dd
}

.border-hover-blue-light:hover {
    border-color: #227fbb
}

.border-navy {
    border-color: #2c3e51
}

.border-hover-navy:hover {
    border-color: #1f2d3b
}

.border-navy-light {
    border-color: #33495f
}

.border-hover-navy-light:hover {
    border-color: #2c3e51
}

.border-purple {
    border-color: #8f3faf
}

.border-hover-purple:hover {
    border-color: #7b3697
}

.border-purple-light {
    border-color: #b078c6
}

.border-hover-purple-light:hover {
    border-color: #8f3faf
}

.border-yellow {
    border-color: #f3b100
}

.border-hover-yellow:hover {
    border-color: #e2a50b
}

.border-yellow-light {
    border-color: #ffbe12
}

.border-hover-yellow-light:hover {
    border-color: #f3b100
}

.border-orange {
    border-color: #e87f04
}

.border-hover-orange:hover {
    border-color: #df6f0b
}

.border-orange-light {
    border-color: #f59d00
}

.border-hover-orange-light:hover {
    border-color: #e87f04
}

.border-red {
    border-color: #c23824
}

.border-hover-red:hover {
    border-color: #a9311f
}

.border-red-light {
    border-color: #ea4b35
}

.border-hover-red-light:hover {
    border-color: #c23824
}

.border-black {
    border-color: #000000
}

.border-hover-black:hover {
    border-color: #000000
}

.border-white {
    border-color: #ffffff
}

.border-hover-white:hover {
    border-color: #ffffff
}

.border-grey {
    border-color: #666666
}

.border-hover-grey:hover {
    border-color: #555555
}

.border-grey-light {
    border-color: #999999
}

.border-hover-grey-light:hover {
    border-color: #888888
}

.border-grey-ultralight {
    border-color: #f4f4f4
}

.border-hover-grey-ultralight:hover {
    border-color: #f1f1f1
}

.border-charcoal {
    border-color: #333333
}

.border-hover-charcoal:hover {
    border-color: #222222
}

.border-charcoal-light {
    border-color: #555555
}

.border-hover-charcoal-light:hover {
    border-color: #444444
}

.border-black {
    border-color: #000000
}

.border-hover-black:hover {
    border-color: #000000
}

.border-base {
    border-color: #eeeeee
}

.border-hover-base:hover {
    border-color: #d0d0d0
}

.border-behance,
.border-hover-behance:hover {
    border-color: #1769ff;
    color: #1769ff
}

.border-dribbble,
.border-hover-dribbble:hover {
    border-color: #ea4c89;
    color: #ea4c89
}

.border-facebook,
.border-hover-facebook:hover {
    border-color: #3b5998;
    color: #3b5998
}

.border-flickr,
.border-hover-flickr:hover {
    border-color: #0063DC;
    color: #0063DC
}

.border-github,
.border-hover-github:hover {
    border-color: #333;
    color: #333
}

.border-gplus,
.border-hover-gplus:hover {
    border-color: #d34836;
    color: #d34836
}

.border-instagram,
.border-hover-instagram:hover {
    border-color: #517fa4;
    color: #517fa4
}

.border-linkedin,
.border-hover-linkedin:hover {
    border-color: #007bb6;
    color: #007bb6
}

.border-pinterest,
.border-hover-pinterest:hover {
    border-color: #cb2027;
    color: #cb2027
}

.border-skype,
.border-hover-skype:hover {
    border-color: #12A5F4;
    color: #12A5F4
}

.border-soundcloud,
.border-hover-soundcloud:hover {
    border-color: #f80;
    color: #f80
}

.border-spotify,
.border-hover-spotify:hover {
    border-color: #7ab800;
    color: #7ab800
}

.border-tumblr,
.border-hover-tumblr:hover {
    border-color: #35465c;
    color: #35465c
}

.border-twitter,
.border-hover-twitter:hover {
    border-color: #00aced;
    color: #00aced
}

.border-vimeo,
.border-hover-vimeo:hover {
    border-color: #1ab7ea;
    color: #1ab7ea
}

.border-youtube,
.border-hover-youtube:hover {
    border-color: #bb0000;
    color: #bb0000
}

[class*="bkg-hover-"]:hover *,
[class*="bkg-hover-"]:visited * {
    color: inherit
}

.bkg-turquoise {
    background: #00a186;
    border-color: #00a186
}

.bkg-hover-turquoise:hover {
    background: #008b73;
    border-color: #008b73
}

.bkg-turquoise-light {
    background: #00bd9c;
    border-color: #00bd9c
}

.bkg-hover-turquoise-light:hover {
    background: #00a186;
    border-color: #00a186
}

.bkg-green {
    background: #1bb05d;
    border-color: #1bb05d
}

.bkg-hover-green:hover {
    background: #179851;
    border-color: #179851
}

.bkg-green-light {
    background: #1ecd6d;
    border-color: #1ecd6d
}

.bkg-hover-green-light:hover {
    background: #1bb05d;
    border-color: #1bb05d
}

.bkg-blue {
    background: #227fbb;
    border-color: #227fbb
}

.bkg-hover-blue:hover {
    background: #1d6ea3;
    border-color: #1d6ea3
}

.bkg-blue-light {
    background: #2c96dd;
    border-color: #2c96dd
}

.bkg-hover-blue-light:hover {
    background: #227fbb;
    border-color: #227fbb
}

.bkg-navy {
    background: #2c3e51;
    border-color: #2c3e51
}

.bkg-hover-navy:hover {
    background: #1f2d3b;
    border-color: #1f2d3b
}

.bkg-navy-light {
    background: #33495f;
    border-color: #33495f
}

.bkg-hover-navy-light:hover {
    background: #2c3e51;
    border-color: #2c3e51
}

.bkg-purple {
    background: #8f3faf;
    border-color: #8f3faf
}

.bkg-hover-purple:hover {
    background: #7b3697;
    border-color: #7b3697
}

.bkg-purple-light {
    background: #b078c6;
    border-color: #b078c6
}

.bkg-hover-purple-light:hover {
    background: #8f3faf;
    border-color: #8f3faf
}

.bkg-yellow {
    background: #f3b100;
    border-color: #f3b100
}

.bkg-hover-yellow:hover {
    background: #e2a50b;
    border-color: #e2a50b
}

.bkg-yellow-light {
    background: #ffbe12;
    border-color: #ffbe12
}

.bkg-hover-yellow-light:hover {
    background: #f3b100;
    border-color: #f3b100
}

.bkg-orange {
    background: #e87f04;
    border-color: #e87f04
}

.bkg-hover-orange:hover {
    background: #df6f0b;
    border-color: #df6f0b
}

.bkg-orange-light {
    background: #f59d00;
    border-color: #f59d00
}

.bkg-hover-orange-light:hover {
    background: #e87f04;
    border-color: #e87f04
}

.bkg-red {
    background: #c23824;
    border-color: #c23824
}

.bkg-hover-red:hover {
    background: #a9311f;
    border-color: #a9311f
}

.bkg-red-light {
    background: #ea4b35;
    border-color: #ea4b35
}

.bkg-hover-red-light:hover {
    background: #c23824;
    border-color: #c23824
}

.bkg-black {
    background: #000000;
    border-color: #000000
}

.bkg-hover-black:hover {
    background: #000000;
    border-color: #000000
}

.bkg-white {
    background: #ffffff;
    border-color: #ffffff
}

.bkg-hover-white:hover {
    background: #ffffff;
    border-color: #ffffff
}

.bkg-grey {
    background-color: #666666;
    border-color: #666666
}

.bkg-hover-grey:hover {
    background-color: #555555;
    border-color: #555555
}

.bkg-grey-light {
    background-color: #999999;
    border-color: #999999
}

.bkg-hover-grey-light:hover {
    background-color: #888888;
    border-color: #888888
}

.bkg-grey-ultralight {
    background-color: #f4f4f4;
    border-color: #f4f4f4
}

.bkg-hover-grey-ultralight:hover {
    background-color: #f1f1f1;
    border-color: #f1f1f1
}

.bkg-charcoal {
    background-color: #333333;
    border-color: #333333
}

.bkg-hover-charcoal:hover {
    background-color: #222222;
    border-color: #222222
}

.bkg-charcoal-light {
    background-color: #555555;
    border-color: #555555
}

.bkg-hover-charcoal-light:hover {
    background-color: #444444;
    border-color: #444444
}

.bkg-black {
    background-color: #000000;
    border-color: #000000
}

.bkg-hover-black:hover {
    background-color: #000000;
    border-color: #000000
}

.bkg-base {
    background: #eeeeee;
    border-color: #666666;
    border-color: #eeeeee
}

.bkg-hover-base:hover {
    background: #d0d0d0;
    border-color: #666666;
    border-color: #eeeeee
}

.bkg-behance,
.bkg-hover-behance:hover {
    background-color: #1769ff;
    color: #ffffff
}

.bkg-dribbble,
.bkg-hover-dribbble:hover {
    background-color: #ea4c89;
    color: #ffffff
}

.bkg-facebook,
.bkg-hover-facebook:hover {
    background-color: #3b5998;
    color: #ffffff
}

.bkg-flickr,
.bkg-hover-flickr:hover {
    background-color: #0063DC;
    color: #ffffff
}

.bkg-github,
.bkg-hover-github:hover {
    background-color: #333;
    color: #ffffff
}

.bkg-gplus,
.bkg-hover-gplus:hover {
    background-color: #d34836;
    color: #ffffff
}

.bkg-instagram,
.bkg-hover-instagram:hover {
    background-color: #517fa4;
    color: #ffffff
}

.bkg-linkedin,
.bkg-hover-linkedin:hover {
    background-color: #007bb6;
    color: #ffffff
}

.bkg-pinterest,
.bkg-hover-pinterest:hover {
    background-color: #cb2027;
    color: #ffffff
}

.bkg-skype,
.bkg-hover-skype:hover {
    background-color: #12A5F4;
    color: #ffffff
}

.bkg-soundcloud,
.bkg-hover-soundcloud:hover {
    background-color: #f80;
    color: #ffffff
}

.bkg-spotify,
.bkg-hover-spotify:hover {
    background-color: #7ab800;
    color: #ffffff
}

.bkg-tumblr,
.bkg-hover-tumblr:hover {
    background-color: #35465c;
    color: #ffffff
}

.bkg-twitter,
.bkg-hover-twitter:hover {
    background-color: #00aced;
    color: #ffffff
}

.bkg-vimeo,
.bkg-hover-vimeo:hover {
    background-color: #1ab7ea;
    color: #ffffff
}

.bkg-youtube,
.bkg-hover-youtube:hover {
    background-color: #bb0000;
    color: #ffffff
}

/*[class*="color-"] * {
    color: inherit !important
}*/

.color-turquoise {
    color: #00a186
}

.color-hover-turquoise:hover {
    color: #008b73
}

.color-turquoise-light {
    color: #00bd9c
}

.color-hover-turquoise-light:hover {
    color: #00a186
}

.color-green {
    color: #1bb05d
}

.color-hover-green:hover {
    color: #179851
}

.color-green-light {
    color: #1ecd6d
}

.color-hover-green-light:hover {
    color: #1bb05d
}

.color-blue {
    color: #227fbb
}

.color-hover-blue:hover {
    color: #1d6ea3
}

.color-blue-light {
    color: #2c96dd
}

.color-hover-blue-light:hover {
    color: #227fbb
}

.color-navy {
    color: #2c3e51
}

.color-hover-navy:hover {
    color: #1f2d3b
}

.color-navy-light {
    color: #33495f
}

.color-hover-navy-light:hover {
    color: #2c3e51
}

.color-purple {
    color: #8f3faf
}

.color-hover-purple:hover {
    color: #7b3697
}

.color-purple-light {
    color: #b078c6
}

.color-hover-purple-light:hover {
    color: #8f3faf
}

.color-yellow {
    color: #f3b100
}

.color-hover-yellow:hover {
    color: #e2a50b
}

.color-yellow-light {
    color: #ffbe12
}

.color-hover-yellow-light:hover {
    color: #f3b100
}

.color-orange {
    color: #e87f04
}

.color-hover-orange:hover {
    color: #df6f0b
}

.color-orange-light {
    color: #f59d00
}

.color-hover-orange-light:hover {
    color: #e87f04
}

.color-red {
    color: #c23824
}

.color-hover-red:hover {
    color: #a9311f
}

.color-red-light {
    color: #ea4b35
}

.color-hover-red-light:hover {
    color: #c23824
}

.color-white {
    color: #ffffff
}

.color-hover-white:hover {
    color: #ffffff
}

.color-grey {
    color: #666666
}

.color-hover-grey:hover {
    color: #555555
}

.color-grey-light {
    color: #999999
}

.color-hover-grey-light:hover {
    color: #888888
}

.color-grey-ultralight {
    color: #f4f4f4
}

.color-hover-grey-ultralight:hover {
    color: #f1f1f1
}

.color-charcoal {
    color: #333333
}

.color-hover-charcoal:hover {
    color: #222222
}

.color-charcoal-light {
    color: #555555
}

.color-hover-charcoal-light:hover {
    color: #444444
}

.color-black {
    color: #000000
}

.color-hover-black:hover {
    color: #000000
}

.color-base {
    color: #666666
}

.color-hover-base:hover {
    color: #666666
}

.color-behance,
.color-hover-behance:hover {
    color: #1769ff
}

.color-dribbble,
.color-hover-dribbble:hover {
    color: #ea4c89
}

.color-facebook,
.color-hover-facebook:hover {
    color: #3b5998
}

.color-flickr,
.color-hover-flickr:hover {
    color: #0063DC
}

.color-github,
.color-hover-github:hover {
    color: #333
}

.color-gplus,
.color-hover-gplus:hover {
    color: #d34836
}

.color-instagram,
.color-hover-instagram:hover {
    color: #517fa4
}

.color-linkedin,
.color-hover-linkedin:hover {
    color: #007bb6
}

.color-pinterest,
.color-hover-pinterest:hover {
    color: #cb2027
}

.color-skype,
.color-hover-skype:hover {
    color: #12A5F4
}

.color-soundcloud,
.color-hover-soundcloud:hover {
    color: #f80
}

.color-spotify,
.color-hover-spotify:hover {
    color: #7ab800
}

.color-tumblr,
.color-hover-tumblr:hover {
    color: #35465c
}

.color-twitter,
.color-hover-twitter:hover {
    color: #00aced
}

.color-vimeo,
.color-hover-vimeo:hover {
    color: #1ab7ea
}

.color-youtube,
.color-hover-youtube:hover {
    color: #bb0000
}

[class*="bkg-"]::-webkit-input-placeholder,
[class*="border-"]::-webkit-input-placeholder,
[class*="color-"]::-webkit-input-placeholder {
    color: inherit
}

[class*="bkg-"]::-moz-placeholder,
[class*="border-"]::-moz-placeholder,
[class*="color-"]::-moz-placeholder {
    color: inherit
}

[class*="bkg-"]:-ms-input-placeholder,
[class*="border-"]:-ms-input-placeholder,
[class*="color-"]:-ms-input-placeholder {
    color: inherit
}

.highlight {
    background: #eeeeee;
    border-color: #eeeeee
}

.info {
    background: #d9edf7;
    color: #31708f;
    border-color: #d9edf7
}

.success {
    background: #dff0d8;
    color: #3c763d;
    border-color: #dff0d8
}

.alert {
    background: #fcf8e3;
    color: #8a6d3b;
    border-color: #fcf8e3
}

.warning {
    background: #f2dede;
    color: #a9444a;
    border-color: #f2dede
}

input,
textarea,
button,
a {
    -webkit-transition-property: background, border-color, color, opacity;
    transition-property: background, border-color, color, opacity;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

.bar,
.box,
.overlay-info,
.thumbnail img {
    -webkit-transition-property: padding, background, color, opacity, box-shadow, -webkit-transform;
    transition-property: padding, background, color, opacity, box-shadow, -webkit-transform;
    transition-property: padding, background, color, opacity, box-shadow, transform;
    transition-property: padding, background, color, opacity, box-shadow, transform, -webkit-transform;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

[class*="animation-"] {
    -webkit-backface-visibility: hidden
}

.animation-fade-in {
    opacity: 0
}

.animation-slide-in-top {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0)
}

.animation-slide-in-right {
    opacity: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0)
}

.animation-slide-in-bottom {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0)
}

.animation-slide-in-left {
    opacity: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0)
}

.animation-scale-in {
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 1);
    transform: scale3d(0.1, 0.1, 1)
}

.animation-rotate-in {
    opacity: 0;
    -webkit-transform: rotate3d(0.1, 0.1, 1, -180deg);
    transform: rotate3d(0.1, 0.1, 1, -180deg)
}

.animation-width {
    width: 0
}

.animation-height {
    height: 0
}

.no-transition {
    -webkit-transition-duration: 0ms !important;
    transition-duration: 0ms !important
}

@media only screen and (max-width: 1140px) {
    body.boxed .wrapper-inner,
    .row {
        max-width: 96rem
    }
    .navigation li:last-child .sub-menu {
        right: 0
    }
    .content-grid-5 .grid-item,
    .content-grid-6 .grid-item {
        width: 25%
    }
}

@media only screen and (max-width: 1023px) {
    .navigation > ul > li:nth-last-child(2) > .sub-menu {
        right: 0
    }
    .navigation > ul > li:nth-last-child(2) > .sub-menu ul {
        right: 18rem;
        left: auto
    }
    .content-grid-5 .grid-item,
    .content-grid-6 .grid-item {
        width: 33.33333%
    }
}

@media only screen and (min-width: 960px) {
    .table tr:last-child > td {
        border-bottom: none
    }
    .table > tbody > tr > td:first-child {
        border-left: none
    }
}

@media only screen and (max-width: 960px) {
    .overlay-navigation-wrapper.active {
        position: absolute
    }
    .aux-navigation-active .wrapper {
        /*position: fixed*/
    }
    .aux-navigation-active .side-navigation-wrapper,
    .aux-navigation-active .overlay-navigation-wrapper {
        position: absolute
    }
    body.boxed .wrapper-inner,
    .row {
        max-width: 76rem
    }
    header .navigation {
        display: none !important
    }
    .nav-bar .logo.logo-center {
        margin-left: -2.5rem !important;
        float: left;
        position: relative;
        left: 50%
    }
    .navigation-show {
        display: block
    }
    .table {
        width: 100%;
        display: block;
        position: relative
    }
    .table > thead,
    .table > thead > tr {
        display: block;
        float: left
    }
    .table > thead > tr > th {
        display: block
    }
    .table > tbody {
        display: block;
        position: relative;
        overflow-x: auto;
        white-space: nowrap
    }
    .table > tbody > tr {
        display: inline-block;
        vertical-align: top;
        margin-right: -0.4rem
    }
    .table > tbody > tr > td {
        display: block
    }
    .table tr > td:last-child {
        border-bottom: none
    }
    .table th+th {
        border-left: none;
        border-bottom: 1px solid transparent
    }
}

@media only screen and (min-width: 768px) and (max-width: 960px) {
    .pricing-table.columns-3 .pricing-table-column {
        width: 33.33333%
    }
    .pricing-table.columns-2 .pricing-table-column,
    .pricing-table.columns-4 .pricing-table-column,
    .pricing-table.columns-5 .pricing-table-column {
        width: 50%
    }
    .pricing-table.columns-4 .pricing-table-column:nth-last-child(2),
    .pricing-table.columns-5 .pricing-table-column:last-child,
    .pricing-table.columns-5 .pricing-table-column:nth-last-child(3) {
        border-left: 1px solid #eeeeee
    }
}

@media only screen and (max-width: 768px) {
    body.boxed .wrapper-inner,
    .row {
        max-width: 60rem
    }
    .width-1,
    .width-2,
    .width-3,
    .width-4,
    .width-5,
    .width-6,
    .width-7,
    .width-8,
    .width-9,
    .width-10,
    .width-11,
    .width-12 {
        width: 100%
    }
    [class*="offset-"] {
        margin-left: 0
    }
    .column[class*='push-'],
    .column[class*='pull-'] {
        position: static;
        left: 0;
        right: 0
    }
    [class*="content-grid-"] .grid-item {
        width: 100%
    }
    .dropdown .login-form-container .button {
        width: 100%;
        display: block;
        text-align: center
    }
    button.no-label-on-mobile span:not([class*="icon-"]),
    .button.no-label-on-mobile span:not([class*="icon-"]) {
        display: none
    }
    button.no-label-on-mobile [class*="icon-"]:before,
    .button.no-label-on-mobile [class*="icon-"]:before {
        margin: 0
    }
    blockquote {
        width: 100% !important
    }
    .lead {
        font-size: 1.8rem;
        line-height: 1.5;
        font-weight: 200
    }
    .pricing-table.columns-3 .pricing-table-column.callout {
        top: 0
    }
    .pricing-table.columns-3 .pricing-table-column {
        margin-bottom: 0.5rem
    }
    .pricing-table.columns-2 .pricing-table-column,
    .pricing-table.columns-3 .pricing-table-column,
    .pricing-table.columns-4 .pricing-table-column,
    .pricing-table.columns-5 .pricing-table-column {
        width: 50%
    }
    .pricing-table.columns-3 .pricing-table-column:last-child,
    .pricing-table.columns-4 .pricing-table-column:nth-last-child(2),
    .pricing-table.columns-5 .pricing-table-column:last-child,
    .pricing-table.columns-5 .pricing-table-column:nth-last-child(3) {
        border-left: 1px solid #eeeeee
    }
    .hide-on-mobile,
    .hide-on-mobile * {
        display: none !important;
        visibility: hidden !important
    }
    .show-on-mobile,
    .show-on-mobile * {
        display: block !important;
        visibility: visible !important
    }
    .pull-left-on-mobile {
        float: left !important
    }
    .pull-right-on-mobile {
        float: right !important
    }
    .clear-float-on-mobile {
        float: none !important
    }
    .v-align-bottom,
    .v-align-bottom-on-mobile {
        padding-bottom: 2rem !important;
        vertical-align: bottom !important
    }
    .v-align-top,
    .v-align-top-on-mobile {
        padding-top: 2rem !important;
        vertical-align: top !important
    }
    .no-padding-on-mobile {
        padding: 0 !important
    }
    .no-background-on-mobile {
        background: none !important;
        border: none !important
    }
    .thumbnail .v-align-top,
    .thumbnail .v-align-top-on-mobile,
    .thumbnail .v-align-bottom,
    .thumbnail .v-align-bottom-on-mobile {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }
    .background-on-mobile {
        width: 100%;
        padding-top: 3rem;
        padding-bottom: 3rem;
        background: #f4f4f4;
        color: #666;
        text-align: left;
        position: relative;
        z-index: 1
    }
    .v-align-top-on-mobile .background-on-mobile {
        margin-top: -2rem
    }
    .v-align-bottom-on-mobile .background-on-mobile {
        margin-bottom: -2rem
    }
    .left-on-mobile {
        text-align: left !important
    }
    .center-on-mobile {
        text-align: center !important
    }
    .right-on-mobile {
        text-align: right !important
    }
    .row.background-on-mobile {
        padding-left: 0;
        padding-right: 0
    }
    .background-on-mobile .column > *:last-child {
        margin-bottom: 0
    }
    .row > .background-on-mobile {
        margin-left: 0;
        margin-right: 0
    }
    .no-transition-on-mobile {
        -webkit-transition-duration: 0ms !important;
        transition-duration: 0ms !important
    }
}

@media only screen and (max-width: 600px) {
    body.boxed .wrapper-inner,
    .row {
        max-width: 48rem
    }
}

@media only screen and (max-width: 480px) {
    body.boxed .wrapper-inner,
    .row {
        max-width: 35rem
    }
    [class*="content-grid-"] .grid-item {
        width: 100%
    }
    .side-navigation-wrapper {
        width: 25rem;
        -webkit-transform: translate3d(-25rem, 0, 0);
        transform: translate3d(-25rem, 0, 0)
    }
    .side-navigation-wrapper.enter-right {
        -webkit-transform: translate3d(25rem, 0, 0);
        transform: translate3d(25rem, 0, 0)
    }
    .side-navigation-wrapper.active {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0)
    }
    .element-reveal-left {
        -webkit-transform: translate3d(25rem, 0, 0);
        transform: translate3d(25rem, 0, 0)
    }
    .element-reveal-right {
        -webkit-transform: translate3d(-25rem, 0, 0);
        transform: translate3d(-25rem, 0, 0)
    }
    .side-navigation-wrapper.hide {
        right: 25rem
    }
    .side-navigation-wrapper.enter-right.hide {
        right: -25rem
    }
    .element-show-left {
        left: 25rem
    }
    .element-show-right {
        left: -25rem
    }
    .list-group {
        margin-bottom: 0
    }
    blockquote {
        width: 100% !important
    }
    .tabs.vertical .tab-nav,
    .tabs.vertical.right .tab-nav,
    .tabs.vertical .tab-panes,
    .tabs.vertical.right .tab-panes {
        width: 100%;
        float: none
    }
    .tab-nav li {
        width: 100%;
        float: none
    }
    .tab-nav li a {
        width: 100%
    }
    .tabs.rounded li:first-child a {
        border-radius: 0.3rem 0.3rem 0 0
    }
    .tabs.rounded .tab-panes {
        border-radius: 0 0 0.3rem 0.3rem
    }
    .tabs li.active:last-child a {
        border-bottom: 1px solid #eeeeee
    }
    .tabs.vertical li.active a,
    .tabs.vertical.right li.active a {
        border-left: 1px solid #eeeeee
    }
    .tabs.vertical .tab-panes,
    .tabs.vertical.right .tab-panes {
        border-top: none
    }
    .pricing-table.columns-2 .pricing-table-column,
    .pricing-table.columns-3 .pricing-table-column,
    .pricing-table.columns-4 .pricing-table-column,
    .pricing-table.columns-5 .pricing-table-column {
        width: 100%
    }
    .pricing-table .pricing-table-column.callout {
        top: 0
    }
    .pricing-table .pricing-table-column {
        margin-bottom: 0.5rem
    }
    .pricing-table[class*="columns-"] .pricing-table-column {
        border-left: 1px solid #eeeeee
    }
}

@media only screen and (max-width: 350px) {
    body.boxed .wrapper-inner,
    .row {
        max-width: 90%
    }
}
*,
*:before,
*:after {
    box-sizing: border-box
}

.tm-slider-container,
.mobile .tm-slider-parallax-container .tm-slider-container {
    background: none;
    position: relative
}

.tm-slider-parallax-container .tm-slider-container {
    position: fixed
}

.tm-slider-container.background-slider {
    position: absolute;
    z-index: 0
}

.tm-slider-container.fullscreen,
.tm-slider-container.full-width-slider {
    width: 100%
}

.tm-slider-container.fullscreen {
    width: 100%;
    height: 100%;
    height: 100vh
}

.tm-slider-container.tms-carousel {
    overflow: hidden
}

.tm-slider-container.tms-scalable-height {
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms
}

.tms-slides {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 1;
    overflow: hidden;
    position: relative
}

.tms-slide {
    width: 100%;
    height: 100%;
    overflow: hidden;
    visibility: hidden;
    position: absolute;
    left: 0;
    z-index: 1
}

.tms-carousel .tms-slide {
    float: left;
    position: relative
}

.tms-slide.active {
    visibility: visible
}

.tms-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1
}

.tms-error {
    width: 100%;
    font-size: 1.4rem;
    text-align: center;
    position: relative;
    top: 50%
}

.tms-slide > video,
.tms-slide > iframe,
.tms-slide > object,
.tms-slide > embed {
    position: relative;
    opacity: 0
}

.tms-slide > video {
    z-index: 1
}

.tms-slide.tms-video-no-ratio > video,
.tms-slide.tms-video-no-ratio > iframe,
.tms-slide.tms-video-no-ratio > object,
.tms-slide.tms-video-no-ratio > embed {
    width: 100% !important;
    height: 100% !important;
    position: static
}

.tms-slide img {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    display: block;
    float: none;
    position: relative;
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0)
}

.tms-carousel .tms-slide img {
    max-height: none
}

.tms-slide > video + img,
.tms-slide.tms-forcefit > img,
.tms-fullscreen .tms-slide > img {
    max-width: none;
    max-height: none;
    float: none;
    position: absolute
}

.tms-progress-bar {
    width: 0;
    height: 0.5rem;
    background: rgba( 0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    z-index: 2
}

.tm-loader,
.tm-loader #circle {
    border-radius: 50%
}

.tm-loader {
    width: 2rem;
    height: 2rem;
    margin: -1rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2
}

.tm-loader #circle {
    width: 2rem;
    height: 2rem;
    background: #666666;
    -webkit-animation: preloader 1s ease infinite;
    animation: preloader 1s ease infinite
}

@-webkit-keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
    }
}

@keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
        transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
        transform: scale( 0);
    }
}

.tms-caption {
    width: auto;
    display: inline-block !important;
    background-image: url(../images/blank.png);
    opacity: 0;
    position: absolute;
    z-index: 2;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none
}

.tms-caption video,
.tms-caption iframe,
.tms-caption object,
.tms-caption embed {
    width: 100% !important;
    height: 100% !important
}

.tms-caption.no-transition {
    opacity: 1 !important;
    visibility: hidden;
    display: block !important
}

.tms-caption:last-child {
    margin-bottom: 0
}

.tms-content {
    width: 100%;
    max-width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: table;
    table-layout: fixed;
    text-align: center;
    position: absolute;
    z-index: 2
}

.tms-content-inner {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    float: none
}

.tms-content-scalable {
    padding-bottom: 3rem
}

.tms-content-inner .tms-caption {
    position: static
}

.tms-content-inner.left {
    text-align: left
}

.tms-content-inner.right {
    text-align: right
}

.tms-content-inner.v-align-top {
    padding-top: 7rem;
    vertical-align: top
}

.tms-content-inner.v-align-bottom {
    padding-bottom: 7rem;
    vertical-align: bottom
}

.tms-fullscreen .tms-caption {
    position: relative
}

.tms-slide .row,
.tms-slide .column {
    height: 100%
}

.tms-content .row,
.tms-content .column {
    height: auto
}

.tms-slide .row .tms-content {
    position: relative
}

@media only screen and (max-width: 768px) {
    .tms-slide .row .tms-content,
    .tms-slide .row .tms-content-inner {
        display: block
    }
    .tms-caption.hide-on-mobile {
        display: none !important
    }
}

.tms-arrow-nav {
    margin-top: -2.5rem;
    width: 5rem;
    height: 5rem;
    background-color: rgba(0, 0, 0, 0.3);
    color: #ccc;
    font-size: 3rem;
    text-align: center;
    line-height: 5rem;
    display: none;
    position: absolute;
    z-index: 10
}

.tms-arrow-nav:hover {
    width: 7rem;
    color: #fff
}

#tms-next {
    top: 50%;
    right: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px
}

#tms-next:before {
    content: '\e644'
}

#tms-prev {
    top: 50%;
    left: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px
}

#tms-prev:before {
    content: "\e643"
}

.tms-pagination {
    width: 100%;
    text-align: center;
    display: none;
    position: absolute;
    bottom: 2rem;
    line-height: 0;
    z-index: 10
}

.tms-bullet-nav {
    width: 0.8rem;
    height: 0.8rem;
    margin: 0 0.3rem;
    display: inline-block;
    background: #ffffff;
    opacity: 0.5;
    border-radius: 50%
}

.tms-thumb-nav {
    width: 5rem;
    height: 5rem;
    opacity: 0.5
}

.tms-bullet-nav:hover,
.tms-thumb-nav:hover {
    opacity: 1
}

.tms-bullet-nav.active,
.tms-thumb-nav.active {
    opacity: 1
}

.tm-slider-container.show-on-hover .tms-arrow-nav,
.tm-slider-container.show-on-hover .tms-pagination {
    opacity: 0
}

.tm-slider-container.show-on-hover:hover .tms-arrow-nav,
.tm-slider-container.show-on-hover:hover .tms-pagination {
    opacity: 1
}

.tm-slider-container.background-slider .tms-arrow-nav,
.tm-slider-container.background-slider .tms-pagination {
    display: none !important
}

.tms-arrow-nav,
.tms-pagination {
    -webkit-transition-property: width, background, color, opacity;
    transition-property: width, background, color, opacity;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/entypo.eot');
    src: url('../fonts/entypo.eot') format('embedded-opentype'), url('../fonts/entypo.woff') format('woff'), url('../fonts/entypo.ttf') format('truetype'), url('../fonts/entypo.svg') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="tms-arrow-nav"]:before,
[class*="tms-arrow-nav"]:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

*,
*:before,
*:after {
    box-sizing: border-box
}

.modal-open {
    overflow: hidden
}

#tml-lightbox,
#tml-content-wrapper,
#tml-content {
    position: absolute
}

.tm-lightbox {
    width: 100%;
    height: 100%;
    background: #000;
    background: rgba(255, 255, 255, .9);
    opacity: 0;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1000;
    -webkit-backface-visibility: hidden
}

#tm-lightbox.tml-modal-mode {
    background: rgba(0, 0, 0, 0.2);
    overflow-x: hidden;
    overflow-y: auto
}

#tml-content {
    width: 100%;
    opacity: 0;
    visibility: hidden;
    -webkit-backface-visibility: hidden
}

.tml-modal-mode .modal-dialog {
    background: #fff;
    box-shadow: 0 0 1rem rgba( 0, 0, 0, .5)
}

.tml-modal-mode .modal-dialog > div {
    padding-left: 3rem;
    padding-right: 3rem
}

#tml-content-wrapper.zoomed {
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important
}

#tml-content.scrollable-content:not(.modal-dialog) {
    background: #fff;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch
}

#tml-content.tml-error {
    width: 100%;
    background: none;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    top: 50%;
    left: 0;
    box-shadow: none
}

@media only screen and (max-width: 479px) {
    #tml-content-wrapper {
        left: 10% !important;
        right: 10% !important
    }
    .tml-modal-mode #tml-content-wrapper {
        left: 5% !important;
        right: 5% !important
    }
}

#tml-content video,
#tml-content iframe,
#tml-content object,
#tml-content embed {
    width: 100% !important;
    height: 100% !important
}

.tm-loader,
.tm-loader #circle {
    border-radius: 50%
}

.tm-loader {
    width: 2rem;
    height: 2rem;
    margin: -1rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    left: 50%
}

.tm-loader #circle {
    width: 2rem;
    height: 2rem;
    background: #666666;
    -webkit-animation: preloader 1s ease infinite;
    animation: preloader 1s ease infinite
}

@-webkit-keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
    }
}

@keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
        transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
        transform: scale( 0);
    }
}

#tml-caption {
    width: 100%;
    font-size: 1.2rem;
    font-style: italic;
    text-align: center;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    -ms-text-size-adjust: none;
    position: absolute;
    bottom: 0
}

#tml-caption span {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: block;
    padding: 1rem 1.5rem;
    border-top: 0.1rem solid #eeeeee
}

.tml-nav {
    width: 4.4rem;
    height: 4.4rem;
    font-size: 2rem;
    text-align: center;
    text-transform: capitalize;
    line-height: 4.4rem;
    opacity: 0.4;
    position: absolute;
    z-index: 10
}

.tml-nav:hover {
    opacity: 1
}

#tml-next {
    top: 50%;
    right: 3rem;
    margin-top: -2.1rem
}

#tml-next:before {
    content: '\e644'
}

#tml-prev {
    top: 50%;
    left: 3rem;
    margin-top: -2.1rem
}

#tml-prev:before {
    content: '\e643'
}

#tml-exit {
    top: 1.5rem;
    right: 3rem
}

.tml-modal-mode #tml-exit {
    right: 1.5rem
}

#tml-tool-bar {
    margin: 0;
    display: inline-block;
    position: absolute;
    top: 1.5rem;
    left: 3rem
}

#tml-tool-bar .tml-nav {
    font-size: 1.5rem
}

#tml-tool-bar .tml-nav,
#tml-tool-bar #tml-share-wrapper,
#tml-tool-bar .tml-social-list {
    float: left;
    position: relative
}

#tml-tool-bar .tml-social-list.list-vertical,
#tml-tool-bar .tml-social-list.list-vertical .tml-nav {
    float: none;
    text-align: left
}

#tml-tool-bar .tml-social-list {
    margin: 0;
    text-align: left;
    opacity: 0;
    visibility: hidden;
    position: relative;
    z-index: 101;
    -webkit-transition-property: -webkit-transform, opacity, visibility;
    -webkit-transition-property: opacity, visibility, -webkit-transform;
    transition-property: opacity, visibility, -webkit-transform;
    transition-property: transform, opacity, visibility;
    transition-property: transform, opacity, visibility, -webkit-transform;
    -webkit-transition-duration: 400ms;
    transition-duration: 400ms
}

#tml-tool-bar .tml-social-list,
#tml-tool-bar .tml-social-list li {
    overflow: hidden
}

#tml-tool-bar .tml-social-list li a {
    margin-right: 1rem
}

#tml-tool-bar .tml-social-list.active {
    opacity: 1;
    visibility: visible
}

#tml-tool-bar .tml-social-list .tml-nav {
    width: auto;
    min-width: 4.4rem;
    font-size: 1.1rem
}

#tml-tool-bar #tml-zoom:before {
    content: '\e6cd'
}

#tml-tool-bar #tml-share:before {
    content: '\e70b'
}

#tml-exit:before {
    content: '\e66f'
}

.tml-nav {
    -webkit-transition-property: color, opacity;
    transition-property: color, opacity;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms
}

@font-face {
    font-family: 'icomoon';
    src: url('../fonts/entypo.eot?-r9lvae');
    src: url('../fonts/entypo.eot?#iefix-r9lvae') format('embedded-opentype'), url('../fonts/entypo.woff?-r9lvae') format('woff'), url('../fonts/entypo.ttf?-r9lvae') format('truetype'), url('../fonts/entypo.svg?-r9lvae#icomoon') format('svg');
    font-weight: normal;
    font-style: normal
}

[class^="tml-nav"]:before,
[class*="tml-nav"]:before {
    font-family: 'icomoon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

*,
*:before,
*:after {
    box-sizing: border-box
}

.parallax {
    height: 55rem;
    background: #000;
    position: relative;
    overflow: hidden
}

.parallax.fullscreen {
    width: 100%;
    height: 100%;
    height: 100vh
}

.parallax .horizon {
    opacity: 0;
    visibility: hidden
}

.tm-parallax,
.tmp-content,
.tmp-content-inner {
    width: 100%;
    height: 100%
}

.tmp-content {
    max-width: 114rem;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    display: table;
    text-align: center
}

.tm-parallax {
    overflow: hidden;
    visibility: visible;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    -webkit-backface-visibility: hidden
}

.tmp-content {
    display: table
}

.tmp-content-inner {
    height: 100%;
    vertical-align: middle;
    display: table-cell;
    float: none;
    position: relative;
    z-index: 1
}

.tmp-content-inner.left {
    text-align: left
}

.tmp-content-inner.right {
    text-align: right
}

.tmp-content-inner.v-align-top {
    padding-top: 7rem;
    vertical-align: top
}

.tmp-content-inner.v-align-bottom {
    padding-bottom: 7rem;
    vertical-align: bottom
}

.tmp-mobile {
    height: 'auto'
}

.tm-parallax img {
    width: 100%;
    max-width: none;
    position: absolute;
    opacity: 1;
    -webkit-backface-visibility: hidden
}

.tm-loader,
.tm-loader #circle {
    border-radius: 50%
}

.tm-loader {
    width: 2rem;
    height: 2rem;
    margin: -1rem 0 0 -1rem;
    position: absolute;
    top: 50%;
    left: 50%
}

.tm-loader #circle {
    width: 2rem;
    height: 2rem;
    background: #666666;
    -webkit-animation: preloader 1s ease infinite;
    animation: preloader 1s ease infinite
}

@-webkit-keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
    }
}

@keyframes "preloader" {
    from {
        opacity: 1;
        -webkit-transform: scale( 1);
        transform: scale( 1);
    }
    to {
        opacity: 0;
        -webkit-transform: scale( 0);
        transform: scale( 0);
    }
}

*,
*:before,
*:after {
    box-sizing: border-box
}

.horizon {
    opacity: 0;
    visibility: hidden
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

body,
.content {
    background-color: #fff
}

body.boxed {
    background-color: #000
}

body.boxed .wrapper-inner {
    background-color: #fff
}

.section-block {
    width: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background-size: cover;
    background-position: center;
    position: relative;
    z-index: 1
}

.replicable-content {
    padding-bottom: 4rem
}

.section-block.featured-media,
body.boxed .section-block.featured-media {
    padding-top: 0;
    padding-bottom: 0
}

body.boxed .section-block {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

body.boxed .section-block.featured-media {
    margin-left: -1.5rem;
    margin-right: -1.5rem
}

.background-fixed,
.background-fixed:before,
.background-fixed:after {
    background-attachment: fixed
}

.mobile .background-fixed,
.mobile .background-fixed:before,
.mobile .background-fixed:after {
    background-attachment: scroll
}

.background-cover,
.background-cover:before,
.background-cover:after,
.fullscreen-section.background-cover .background-image {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat
}

.background-contain,
.background-contain:before,
.background-contain:after,
.fullscreen-section.background-contain .background-image {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat
}

.background-full,
.fullscreen-section.background-full .background-image {
    background-size: 100%;
    background-position: center center;
    background-repeat: no-repeat
}

.horizon {
    opacity: 0;
    visibility: hidden
}

.aux-navigation-active .one-page-nav + .wrapper {
    position: absolute
}

.aux-navigation-active .side-navigation-wrapper.one-page-nav,
.aux-navigation-active .overlay-navigation-wrapper.one-page-nav {
    position: fixed
}

.masonry-grid,
.row .masonry-grid {
    max-width: 100%;
    min-height: 30rem;
    visibility: hidden;
    z-index: 1
}

.masonry-grid.fade-in-progressively .grid-item,
.masonry-grid.fade-in-progressively .masonry-stamp {
    opacity: 0
}

.masonry-grid.filtering {
    -webkit-transition-property: height;
    transition-property: height;
    -webkit-transition-duration: 0.8s;
    transition-duration: 0.8s
}

.masonry-set-dimensions {
    padding-top: 7rem;
    padding-bottom: 6rem
}

.masonry-set-dimensions.full-width .row {
    max-width: 100%
}

.masonry-set-dimensions.full-width [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.masonry-set-dimensions.full-width > .row > .column {
    padding-left: 0;
    padding-right: 0
}

.masonry-set-dimensions.small-margins.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.masonry-set-dimensions.no-margins [class*="content-grid"],
.masonry-set-dimensions.no-margins [class*="content-grid"] .grid-item {
    padding: 0 !important;
    margin: 0 !important
}

.masonry-set-dimensions.full-width.no-margins {
    padding: 0
}

.masonry-set-dimensions.no-padding-top {
    padding-top: 1rem
}

.masonry-set-dimensions.no-padding-bottom {
    padding-bottom: 0.5rem
}

.masonry-set-dimensions > .row > .column {
    padding-left: 0;
    padding-right: 0
}

.masonry-set-dimensions [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-top: -1.5rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -1.5rem
}

.masonry-set-dimensions [class*="content-grid"] .grid-item {
    margin-bottom: 0;
    padding: 1.5rem;
    position: relative
}

.masonry-set-dimensions.small-margins > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem
}

.masonry-set-dimensions.small-margins [class*="content-grid"] {
    margin-top: -0.5rem;
    margin-bottom: -0.5rem
}

.masonry-set-dimensions.small-margins [class*="content-grid"] .grid-item {
    padding: 0.5rem
}

.masonry-set-dimensions.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.masonry-set-dimensions.no-margins.full-width > .row > .column {
    padding-left: 0rem;
    padding-right: 0rem
}

.masonry-set-dimensions .thumbnail {
    height: 100%;
    overflow: hidden;
    margin-bottom: 0
}

.masonry-set-dimensions .grid-item {
    overflow: hidden
}

.masonry-set-dimensions .grid-item img {
    width: 100%
}

.masonry-set-dimensions .content-outer {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
    bottom: 0
}

.masonry-set-dimensions .thumbnail + .content-outer {
    position: absolute
}

.masonry-set-dimensions .content-inner {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: table-cell;
    vertical-align: middle
}

.masonry-set-dimensions .content-inner > *:first-child {
    margin-top: 0
}

.masonry-set-dimensions .content-inner > *:last-child {
    margin-bottom: 0
}

.masonry-set-dimensions .content-slider,
.masonry-set-dimensions video,
.masonry-set-dimensions iframe {
    width: 100% !important;
    height: 100% !important
}

.masonry-set-dimensions .content-slider img {
    width: auto
}

.masonry-set-dimensions .mejs-container {
    width: 20rem;
    height: 3rem;
    margin-left: -10rem;
    margin-top: -1.5rem;
    position: absolute;
    left: 50%;
    top: 50%
}

.masonry-stamp {
    padding: 1.5rem
}

.small-margins .masonry-stamp {
    padding: 0.5rem
}

.no-margins .masonry-stamp {
    padding: 0
}

.isotope-item {
    z-index: 2
}

.isotope-hidden.isotope-item {
    pointer-events: none;
    z-index: 1
}

.isotope,
.isotope .isotope-item {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s
}

.isotope {
    -webkit-transition-property: height, width;
    transition-property: height, width
}

.isotope .isotope-item {
    -webkit-transition-property: -webkit-transform, opacity;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform
}

.isotope.no-transition,
.isotope.no-transition .isotope-item,
.isotope .isotope-item.no-transition {
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.header {
    width: 100%;
    background-color: #232323;
    position: relative
}

.header .header-inner {
    max-width: 100%;
    opacity: 1;
    position: relative;
    z-index: 100;
    -webkit-transform: translateZ(0)
}

body.boxed .header .header-inner {
    max-width: 114rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    margin-left: auto;
    margin-right: auto
}

.header .logo {
    height: auto;
    line-height: 1;
    float: left
}

.header .logo a {
    font-weight: bold;
    text-decoration: none;
    color: #ffffff;
    display: block;
    -webkit-transition-property: opacity, background, color, visibility, -webkit-transform;
    transition-property: opacity, background, color, visibility, -webkit-transform;
    transition-property: opacity, background, color, visibility, transform;
    transition-property: opacity, background, color, visibility, transform, -webkit-transform
}

.header .logo a:hover {
    opacity: 0.6 !important
}

.header .logo img {
    width: 100%;
    height: auto;
    max-height: 100%
}

.header .navigation {
    float: right
}

.header.header-sticky .header-inner {
    width: 100%;
    position: fixed;
    top: 0;
    bottom: auto;
    z-index: 100
}

.header.fixed-width .header-inner {
    max-width: 114rem;
    margin-left: auto;
    margin-right: auto
}

.header.fixed-width.header-sticky {
    width: 114rem;
    margin-left: auto;
    margin-right: auto
}

.header .logo a:first-child {
    display: none
}

.header .logo a:last-child {
    display: table-cell
}

.mobie .header .logo a:first-child,
.header-background .logo a:first-child {
    display: table-cell
}

.header-background .logo a + a {
    display: none !important
}

.header.header-animated {
    -webkit-transition-property: height;
    transition-property: height
}

.header.header-animated .header-inner,
.header.header-animated .header-inner .navigation,
.header.header-animated .header-inner .navigation > ul > li > a,
.header.header-animated .header-inner .button,
.header.header-animated .header-inner .button-nav .header.header-animated .header-inner .dropdown {
    -webkit-transition-property: height, background, border, padding, margin, font-size, color, opacity;
    -webkit-transition-property: background, border, padding, margin, font-size, color, opacity;
    transition-property: background, border, padding, margin, font-size, color, opacity
}

.header.header-animated .logo,
.header.header-animated .logo span,
.header.header-animated .navigation-show {
    -webkit-transition-property: width, background, border, padding, margin, font-size, opacity;
    transition-property: width, background, border, padding, margin, font-size, opacity
}

.header.header-animated,
.header.header-animated .header-inner,
.header.header-animated .header-inner .navigation,
.header.header-animated .header-inner .navigation > ul > li > a,
.header.header-animated .logo,
.header.header-animated .logo span,
.header.header-animated .logo a,
.header.header-animated .navigation-show,
.header.header-animated .header-inner .button,
.header.header-animated .header-inner .button-nav,
.header.header-animated .header-inner .dropdown {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms
}

.header.header-hide .header-inner {
    opacity: 0
}

.header.header-positioned .header-inner {
    -webkit-transform: translate3d(0, -10rem, 0);
    transform: translate3d(0, -10rem, 0)
}

.header.header-in .header-inner,
.header.header-out .header-inner {
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms
}

.header.header-in .header-inner {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.header.header-out .header-inner {
    -webkit-transform: translate3d(0, -10rem, 0);
    transform: translate3d(0, -10rem, 0)
}

.header.header-in .header-inner .logo,
.header.header-in .header-inner .navigation,
.header.header-in .navigation-show {
    -webkit-transition: none !important;
    transition: none !important
}

.header .navigation > ul > li:last-child > a {
    padding-right: 0;
    margin-right: 0
}

.header-1 {
    background-color: transparent;
    background-image: url(../images/blank.png);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100
}

.ie-browser .wrapper.inactive .header-1 {
    position: absolute
}

.header-1 .header-inner {
    background: transparent
}

.header-1 .navigation > ul > li:last-child > a {
    padding-right: 0;
    margin-right: 0
}

.header-1 .logo {
    width: 13rem;
    padding-top: 3rem
}

.header-1 .logo span {
    font-size: 2rem;
    line-height: 2
}

.header-1 .header-inner .navigation {
    padding-top: 4rem
}

.header-1 .logo,
.header-1 .header-inner .navigation > ul > li > a {
    padding-bottom: 4rem
}

.header-1 .header-inner .navigation > ul > li > a {
    line-height: 2rem
}

.header-1 .navigation-show {
    height: auto;
    margin-top: 3rem;
    margin-left: 2rem;
    line-height: 1;
    margin-left: 2rem;
    display: block
}

.header-1 .navigation > ul > li > a,
.header-1 .navigation > ul > li > a:hover,
.header-1 .navigation > ul > li.current > a,
.header-1 .navigation > ul > li.current > a:hover {
    background-color: rgba(0, 0, 0, 0)
}

.header-1 .navigation > ul > li.current > a,
.header-1 .navigation > ul > li.current > a:hover {
    /*color: #fff*/
}

.header-2 .header-inner {
    background: #232323
}

.header-2 .logo,
.header-2 .navigation {
    float: none
}

.header-2 .navigation {
    margin-left: -1.5rem
}

.header-2 .header-inner {
    padding-top: 4rem
}

.header-2 .logo {
    width: 17rem;
    height: 4.4rem;
    margin-bottom: 2rem
}

.header-2 .logo span {
    font-size: 2rem;
    line-height: 2
}

.header-2 .header-inner .navigation > ul > li > a,
.header-2 .navigation-show {
    margin-bottom: 5rem
}

.header-2 .header-inner .navigation > ul > li > a {
    line-height: 2rem
}

.header-2 .navigation-show {
    height: auto;
    margin-left: 2rem;
    line-height: 1;
    margin-left: 2rem;
    display: block
}

.header-2.header-compact .header-inner {
    padding-top: 0
}

.header-2.header-compact .logo {
    margin-bottom: 0;
    float: left
}

.header-2.header-compact .navigation {
    padding-top: 2rem;
    margin-left: 5rem;
    float: left
}

.header-2 .navigation > ul > li > a,
.header-2 .navigation > ul > li > a:hover,
.header-2 .navigation > ul > li.current > a,
.header-2 .navigation > ul > li.current > a:hover {
    background-color: rgba(0, 0, 0, 0)
}

.header-2 .navigation > ul > li.current > a,
.header-2 .navigation > ul > li.current > a:hover {
    color: #fff
}

.header-3 .header-inner-top {
    width: 100%;
    height: 4rem;
    background: #151515
}

.header-3 .header-inner-top p {
    font-size: 1.1rem;
    line-height: 4rem;
    margin-bottom: 0
}

.header-3 .header-inner-top .navigation {
    margin-bottom: 0
}

.header-3 .header-inner-top .navigation ul li a {
    height: auto;
    line-height: 4rem;
    font-size: 1rem;
    text-transform: uppercase;
    background: none;
    padding: 0 1rem
}

.header-3 .header-inner-top .navigation ul li a [class*="icon-"] {
    font-size: 1.3rem
}

.header-3 .header-inner-top .navigation ul li:last-child a [class*="icon-"] {
    margin-right: 0
}

.header-3 .header-inner-top .navigation ul li:hover > a {
    background: none;
    color: #ffffff
}

.header-3 .header-inner-top .navigation ul li.current > a {
    background: none;
    color: #fff
}

.header-3 .header-inner {
    width: 100%;
    background: #232323
}

.header-3 .navigation-show {
    height: auto;
    margin-top: 3.7rem;
    margin-left: 2rem;
    line-height: 1;
    margin-left: 2rem;
    display: block
}

.header-3 .logo {
    width: 13rem;
    padding-top: 3rem
}

.header-3 .logo span {
    font-size: 2rem;
    line-height: 2
}

.header-3 .header-inner .navigation {
    padding-top: 4rem
}

.header-3 .logo,
.header-3 .header-inner .navigation > ul > li > a {
    padding-bottom: 4rem
}

.header-3 .header-inner .navigation > ul > li > a {
    line-height: 2rem
}

.header-3 .navigation > ul > li:last-child > a {
    padding-right: 0;
    margin-right: 0
}

.header-3 .navigation > ul > li > a,
.header-3 .navigation > ul > li > a:hover,
.header-3 .navigation > ul > li.current > a,
.header-3 .navigation > ul > li.current > a:hover {
    background-color: rgba(0, 0, 0, 0)
}

.header-3 .navigation > ul > li.current > a,
.header-3 .navigation > ul > li.current > a:hover {
    color: #fff
}

.header-4 {
    background: transparent;
    background-image: url(../images/blank.png);
    position: absolute;
    bottom: 0;
    z-index: 100
}

.ie-browser .wrapper.inactive .header-4 {
    position: absolute
}

.header-4 .header-inner {
    background: none
}

.header-4 .navigation > ul > li:last-child > a {
    padding-right: 0;
    margin-right: 0
}

.header-4 .logo {
    width: 12rem;
    padding-top: 2rem
}

.header-4 .logo span {
    font-size: 1.5rem;
    line-height: 2
}

.header-4 .header-inner .navigation {
    padding-top: 3rem
}

.header-4 .logo,
.header-4 .header-inner .navigation > ul > li > a {
    padding-bottom: 3rem
}

.header-4 .header-inner .navigation > ul > li > a {
    line-height: 2rem
}

.header-4 .navigation-show {
    height: auto;
    margin-top: 2.7rem;
    margin-left: 2rem;
    line-height: 1;
    margin-left: 2rem;
    display: block
}

.header-4 .navigation > ul > li > a,
.header-4 .navigation > ul > li > a:hover,
.header-4 .navigation > ul > li.current > a,
.header-4 .navigation > ul > li.current > a:hover {
    background-color: rgba(0, 0, 0, 0)
}

.header-4 .navigation > ul > li.current > a,
.header-4 .navigation > ul > li.current > a:hover {
    color: #fff
}

.header.header-background .header-inner {
    background: #232323
}

.header.header-compact .logo {
    width: 10rem;
    padding-top: 1.5rem
}

.header.header-compact .logo span {
    font-size: 1.5rem;
    line-height: 2
}

.header.header-compact .header-inner .navigation {
    padding-top: 2rem
}

.header-1.header-compact .logo,
.header-2.header-compact .navigation-show,
.header-3.header-compact .logo,
.header-4.header-compact .logo,
.header.header-compact .header-inner .navigation > ul > li > a {
    padding-bottom: 2rem
}

.header.header-compact .header-inner .navigation > ul > li > a {
    line-height: 2rem
}

.header.header-compact .navigation-show {
    margin-top: 1rem
}

.header .social-list.pull-left li:last-child span {
    margin-left: 0
}

.header .social-list.center li:last-child span,
.header .social-list.pull-right li:last-child span {
    margin-right: 0
}

.mobile .header {
    position: static !important
}

.mobile .header.header-fixed-on-mobile .header-inner {
    width: 100%;
    position: fixed !important;
    left: 0;
    top: 0;
    background: rgba(255,255,255, 0.7);
}

.mobile .header.header-sticky .header-inner {
    position: relative
}

.mobile .header,
.mobile .header .header-inner {
    height: 8rem !important;
    padding-top: 0;
    background: #232323
}

.mobile .header .header-inner,
.mobile .header .nav-bar {
    border-top: none !important
}

.mobile .header .header-inner-top {
    display: none
}

.mobile .header .nav-bar,
.mobile .header .nav-bar-inner,
.mobile .header .navigation-show {
    height: 100%
}

.mobile .header .logo {
    height: 100%;
    padding: 0 !important;
    margin: 0 !important;
    display: table;
    float: left
}

.mobile .header .logo-inner {
    display: table-cell;
    vertical-align: middle
}

.mobile .header .logo-inner a {
    display: block;
    position: relative
}

.mobile .header .logo a:first-child {
    display: table-cell;
    vertical-align: middle
}

.mobile .header .logo a + a {
    display: none !important
}

.mobile .header .navigation-show {
    height: 100%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    display: table !important;
    visibility: visible !important
}

.mobile .header .navigation-show a {
    display: table-cell;
    vertical-align: middle
}

.mobile .header .header-inner,
.mobile .header .logo,
.mobile .header .logo span,
.mobile .header .navigation-show,
.mobile .header .navigation-show a span {
    -webkit-transition: none;
    transition: none
}

@media only screen and (max-width: 1140px) {
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 96rem
    }
}

@media only screen and (max-width: 960px) {
    .header {
        position: static !important
    }
    .header.header-fixed-on-mobile .header-inner {
        width: 100%;
        position: fixed !important;
        left: 0;
        top: 0;
        background-color: rgba(255,255,255, 0.7);
    }
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 76rem
    }
    .header.header-sticky .header-inner {
        position: relative
    }
    .header {
        background: none
    }
    .header .header-inner {
        background: #232323
    }
    .header .header-inner,
    .header .nav-bar {
        border-top: none !important
    }
    .header,
    .header .header-inner {
        height: 8rem !important;
        padding-top: 0;
        border: none
    }
    .header .header-inner-top {
        display: none
    }
    .header .nav-bar,
    .header .nav-bar-inner,
    .header .navigation-show {
        height: 100%
    }
    .header .logo {
        height: 100%;
        padding: 0 !important;
        margin: 0 !important;
        display: table;
        float: left
    }
    .header .logo-inner {
        display: table-cell;
        vertical-align: middle
    }
    .header .logo-inner a {
        display: block;
        position: relative
    }
    .header .logo a {
        display: table-cell;
        vertical-align: middle
    }
    .header .logo a:first-child {
        display: table-cell
    }
    .header .logo a + a {
        display: none !important
    }
    .header .navigation-show {
        height: 100%;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        display: table !important;
        visibility: visible !important
    }
    .header .navigation-show a {
        display: table-cell;
        vertical-align: middle
    }
    .header .header-inner,
    .header .logo,
    .header .logo span,
    .header .navigation-show,
    .header .navigation-show a span {
        -webkit-transition: none;
        transition: none
    }
}

@media only screen and (max-width: 768px) {
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 60rem
    }
}

@media only screen and (max-width: 600px) {
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 48rem
    }
}

@media only screen and (max-width: 480px) {
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 35rem
    }
}

@media only screen and (max-width: 350px) {
    body.boxed .header.header-fixed-on-mobile .header-inner {
        max-width: 100%
    }
}

.side-navigation-header,
.side-navigation-header .logo {
    min-height: 8rem;
    line-height: 8rem
}

.side-navigation-header,
.side-navigation-footer {
    font-size: 1.4rem;
    line-height: 1.8
}

.side-navigation-header {
    padding: 0 4rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #232323
}

.side-navigation-header .logo {
    width: 100%
}

.side-navigation-header .logo a {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #666666
}

.side-navigation-wrapper .navigation-hide {
    position: absolute;
    right: 0;
    top: 0
}

.side-navigation {
    margin-bottom: 3rem
}

.side-navigation-footer {
    width: 100%;
    padding: 1rem 4rem;
    font-size: 1.2rem;
    position: absolute;
    bottom: 0
}

.side-navigation-footer .social-list {
    margin-bottom: 1rem
}

.side-navigation-footer .social-list a:hover {
    color: #fff
}

.side-navigation-wrapper.center .logo,
.side-navigation-wrapper.center .side-navigation,
.side-navigation-wrapper.center .side-navigation-footer {
    text-align: center
}

.side-navigation-wrapper.center .side-navigation .sub-menu a {
    padding-left: 4rem
}

.mobile .side-navigation-footer {
    position: relative
}

.overlay-navigation-header {
    width: 100%;
    padding: 1rem 0;
    margin-bottom: 3rem;
    position: absolute;
    top: 2rem
}

.overlay-navigation-wrapper .navigation-hide {
    min-height: 4rem;
    line-height: 4rem;
    position: absolute;
    right: 3rem;
    top: 0
}

.overlay-navigation-header .logo {
    width: 13rem;
    margin: 0 auto
}

.overlay-navigation {
    margin-bottom: 6rem
}

.overlay-navigation-footer {
    font-size: 1.2rem;
    position: absolute;
    bottom: 3rem
}

.overlay-navigation-footer .copyright {
    font-size: 1.1rem
}

.overlay-navigation-footer .social-list {
    margin-bottom: 1rem
}

.overlay-navigation-footer .social-list a:hover {
    color: #fff
}

@media only screen and (max-width: 960px) {
    .side-navigation-footer {
        position: relative;
        bottom: 1rem
    }
    .overlay-navigation-footer {
        position: relative;
        bottom: auto;
        margin-top: 5rem
    }
}

[class*="intro-title-"] .row,
[class*="intro-title-"] .column,
.title-container {
    height: 100%
}

.title-container[class^="title-"] {
    margin-bottom: 0
}

.title-container {
    width: 100%;
    display: table
}

.title-container-inner {
    display: table-cell;
    vertical-align: middle
}

.title-container-inner > *:first-child {
    margin-top: 0
}

.title-container-inner > *:last-child {
    margin-bottom: 0
}

[class*="intro-title-"] .subtitle {
    font-size: 2.2rem;
    line-height: 1.5;
    font-weight: 300;
    font-style: italic
}

.intro-title-1 {
    height: 40rem
}

.intro-title-2 {
    height: 40rem;
    background-image: url(../images/slider/slide-9-fw.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.intro-title-2 h1,
.intro-title-2 .subtitle {
    color: #fff
}

@media only screen and (max-width: 768px) {
    [class*="intro-title-"] h1 {
        font-size: 4rem
    }
}

@media only screen and (max-width: 480px) {
    [class*="intro-title-"] h1 {
        font-size: 3.5rem
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .intro-title-2 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
}

[class*="hero-"] > .row,
[class*="hero-"] > .row > .column {
    height: 100%
}

.hero-content {
    width: 100%;
    height: 100%;
    display: table !important;
    table-layout: fixed
}

.section-block .hero-content-inner {
    display: table-cell;
    vertical-align: top
}

.section-block .hero-content-inner .row,
.section-block .hero-content-inner .column {
    height: auto
}

[class*="hero-"] .column:first-child .split-hero-content .hero-content-inner {
    padding-right: 8.5rem
}

[class*="hero-"] .column + .column .split-hero-content .hero-content-inner,
[class*="hero-"] .column[class*="push-"] .split-hero-content .hero-content-inner {
    padding-left: 5rem;
    padding-right: 0rem
}

body.boxed [class*="hero-"] .column:first-child .split-hero-content .hero-content-inner {
    padding-right: 3rem
}

body.boxed [class*="hero-"] .column + .column .split-hero-content .hero-content-inner,
body.boxed [class*="hero-"] .column[class*="push-"] .split-hero-content .hero-content-inner {
    padding-left: 3rem;
    padding-right: 0rem
}

.hero-content-inner > *:first-child {
    margin-top: 0
}

.hero-content-inner > *:last-child {
    margin-bottom: 0
}

[class*="hero-"],
[class*="hero-"] > .row:after,
[class*="hero-"] > .row:before {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat
}

.hero-1 {
    height: 50rem;
    background-color: #1ecd6d;
    text-align: left;
    color: #fff
}

.hero-1 h2,
.hero-1 h6 {
    color: #fff
}

.hero-2 {
    height: 50rem;
    text-align: left;
    color: #fff;
    background-image: url(../images/slider/slide-2-fs-hp.jpg);
    background-repeat: no-repeat
}

.hero-2 h2,
.hero-2 h6 {
    color: #fff
}

.hero-3 {
    height: 50rem;
    text-align: left;
    display: table;
    background-image: url(../images/slider/slide-5-fw.jpg);
    background-repeat: no-repeat;
    position: relative
}

.hero-3 > div {
    display: table-cell;
    vertical-align: middle;
    float: none
}

.hero-3 .row,
.hero-3 .column {
    height: auto
}

.hero-3.right .column {
    float: right
}

.hero-3.top {
    padding-top: 0
}

.hero-3.top > div {
    vertical-align: top
}

.hero-3.top .hero-content {
    border-bottom: 8px solid #333
}

.hero-3.bottom {
    padding-bottom: 0
}

.hero-3.bottom > div {
    vertical-align: bottom
}

.hero-3.bottom .hero-content {
    border-top: 8px solid #333
}

.hero-3[class*="center"] {
    padding-top: 0
}

.hero-3.center-left .column {
    text-align: left
}

.hero-3.center .column {
    margin: auto;
    float: none
}

.hero-3.center-right .column {
    text-align: right;
    float: right
}

.hero-3 .row {
    width: 100%;
    bottom: 0
}

.hero-3 .hero-content {
    background: #fff;
    position: relative;
    bottom: 0
}

.hero-3 .hero-content-inner {
    padding: 3rem
}

.hero-3 h2,
.hero-3 h6 {
    color: #666
}

.hero-4 {
    height: 50rem;
    text-align: left;
    color: #fff;
    background-image: url(../images/slider/slide-9-fw.jpg);
    background-repeat: no-repeat;
    position: relative
}

.hero-4 > .row:before {
    width: 50%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    position: absolute;
    top: 0;
    left: 0
}

.hero-4.right > .row:before {
    top: 0;
    right: 0;
    left: auto
}

.hero-4 .column {
    position: relative
}

.hero-4 h2,
.hero-4 h6 {
    color: #fff
}

.hero-5 {
    height: 50rem;
    /*color: #fff;*/
    text-align: left;
    position: relative
}

.hero-5 > .row:before {
    width: 50%;
    height: 100%;
    background-color: #2c96dd;
    content: "";
    position: absolute;
    top: 0;
    left: 0
}

.hero-5 > .row:before {
    background-color: inherit
}

.safari-browser .hero-5 > .row:before {
    width: 50.1%
}

.hero-5 > .row:after {
    width: 50%;
    height: 100%;
    background-image: url(../images/slider/slide-9-fw.jpg);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0;
    right: 0
}

.hero-5.right > .row:before {
    left: auto;
    right: 0
}

.hero-5.right > .row:after {
    left: 0;
    right: auto
}

.hero-5 .column {
    position: relative;
    z-index: 1
}

.hero-5 h2,
.hero-5 h6 {
    color: #fff
}

.hero-5 .testimonial-slider {
    max-width: 50rem;
    margin: 0 auto
}

.hero-5 .testimonial-slider blockquote {
    padding-right: 1rem;
    padding-left: 0
}

.hero-5 .right .testimonial-slider blockquote {
    padding-right: 0;
    padding-left: 1rem
}

.hero-5 .tms-pagination {
    text-align: left
}

.hero-5 .center .tms-pagination {
    text-align: center
}

.hero-5 .right .tms-pagination {
    text-align: right
}

.hero-6 {
    padding: 0;
    text-align: right
}

body.boxed .hero-6 {
    padding: 0
}

.hero-6 .hero-slider {
    width: 100% !important;
    height: 50rem;
    margin-bottom: 0
}

.hero-6 .hero-slider .tms-pagination {
    width: 50%;
    text-align: right;
    padding-right: 5rem
}

.hero-6 .hero-slider .tms-bullet-nav {
    background: #eee
}

.hero-6 .hero-content {
    width: 50%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: table !important
}

.hero-6.right .hero-content {
    left: auto;
    right: 0;
    text-align: left
}

.hero-6 .hero-content-inner {
    padding-left: 5rem;
    padding-right: 5rem
}

.hero-6 h2,
.hero-6 h6 {
    color: #fff
}

.hero-7 {
    height: 50rem;
    color: #fff;
    text-align: left;
    position: relative
}

.hero-7 > .row:before {
    width: 50%;
    height: 100%;
    background-image: url(../images/slider/slide-9-fw.jpg);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    top: 0;
    left: 0
}

[class*="bkg-"].hero-7 > .row:before {
    background-color: inherit
}

.safari-browser .hero-7 > .row:after {
    width: 50.1%
}

.hero-7 > .row:after {
    width: 50%;
    height: 100%;
    background-color: #000;
    content: "";
    position: absolute;
    top: 0;
    right: 0
}

.hero-7.right > .row:before {
    left: auto;
    right: 0
}

.hero-7.right > .row:after {
    left: 0;
    right: auto
}

.hero-7 .column {
    position: relative;
    z-index: 1
}

.hero-7 .media-column {
    height: 100%;
    padding: 0;
    position: absolute;
    left: 50%;
    top: 0;
    z-index: 2
}

.hero-7.right .media-column {
    left: 0;
    top: 0
}

.hero-7 .media-column > * {
    width: 50vw;
    height: 100%
}

body.boxed .hero-7 .media-column > * {
    width: 100%
}

.hero-7.right .media-column > * {
    float: right
}

.hero-7 .media-column > * {
    float: left
}

.hero-7 h2,
.hero-7 h6 {
    color: #fff
}

@media only screen and (max-width: 960px) {
    [class*="hero-"] .column:first-child .split-hero-content .hero-content-inner {
        padding-right: 2.5rem
    }
    [class*="hero-"] .column + .column .split-hero-content .hero-content-inner,
    [class*="hero-"] .column[class*="push-"] .split-hero-content .hero-content-inner {
        padding-left: 2.5rem
    }
    body.boxed [class*="hero-"] .column:first-child .split-hero-content .hero-content-inner {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
    body.boxed [class*="hero-"] .column + .column .split-hero-content .hero-content-inner,
    body.boxed [class*="hero-"] .column[class*="push-"] .split-hero-content .hero-content-inner {
        padding-left: 2.5rem;
        padding-right: 2.5rem
    }
}

@media only screen and (max-width: 768px) {
    .hero-1,
    .hero-2,
    .hero-3,
    .hero-4,
    .hero-5,
    .hero-7 {
        height: auto !important
    }
    [class*="hero-"] > .row:before,
    [class*="hero-"] > .row:after,
    .safari-browser [class*="hero-"] .row:before {
        width: 100%;
        left: 0;
        top: 0
    }
    [class*="hero-"] .column {
        left: 0;
        float: none
    }
    [class*="hero-"] .column:first-child .hero-content,
    .hero-7 .column:first-child.media-column {
        margin-bottom: 5rem
    }
    [class*="hero-"] .column:last-child .hero-content {
        margin-bottom: 0
    }
    [class*="hero-"] .column:first-child .split-hero-content .hero-content-inner {
        padding-right: 0
    }
    [class*="hero-"] .column + .column .split-hero-content .hero-content-inner,
    [class*="hero-"] .column[class*="push-"] .split-hero-content .hero-content-inner {
        padding-left: 0
    }
    .hero-content {
        text-align: center
    }
    .hero-3[class*="center"] {
        padding-top: 7rem;
        padding-bottom: 7rem
    }
    .section-block.hero-5 {
        padding-bottom: 0
    }
    body.boxed .hero-5 {
        padding-left: 0;
        padding-right: 0
    }
    .hero-5 > .row:after {
        min-height: 50rem
    }
    .hero-5 .column:last-child .hero-content {
        margin-bottom: 7rem
    }
    .hero-5 > .row:after,
    .hero-7 > .row:after {
        position: relative
    }
    .hero-5 > .row,
    .hero-7 > .row {
        max-width: none
    }
    .hero-5 > .row > .column,
    .hero-7 > .row > .column {
        max-width: 60rem;
        margin: auto;
        float: none
    }
    .hero-5 .testimonial-slider {
        max-width: 40rem
    }
    .hero-5 .tms-pagination {
        text-align: center !important
    }
    .hero-6 .hero-slider .tms-pagination {
        width: 100%;
        padding: 0;
        text-align: center
    }
    .hero-6 .hero-content-inner {
        padding-left: 3rem;
        padding-right: 3rem
    }
    .hero-slider .hero-content {
        width: 100%;
        height: inherit;
        text-align: center
    }
    .hero-7 .column {
        width: 100%;
        padding-left: 1.5rem;
        padding-right: 1.5rem
    }
    .hero-7 .media-column {
        position: relative;
        left: auto;
        right: auto
    }
    .hero-7 .media-column > * {
        width: 57rem;
        height: 32rem;
        position: relative;
        left: 0
    }
    body.boxed .hero-7 .media-column > * {
        width: 54rem;
        height: 30rem
    }
    .hero-7 .media-column > *,
    .hero-7.right .media-column > * {
        float: none
    }
}

@media only screen and (max-width: 600px) {
    [class*="hero-"] > .row > .column {
        max-width: 48rem
    }
    .hero-7 .media-column > * {
        max-width: 45rem;
        height: 25rem;
        position: relative
    }
    body.boxed .hero-7 .media-column > * {
        width: 42rem;
        height: 23.6rem
    }
}

@media only screen and (max-width: 480px) {
    [class*="hero-"] > .row > .column {
        max-width: 35rem
    }
    .hero-7 .media-column > * {
        max-width: 32rem;
        height: 18rem
    }
    body.boxed .hero-7 .media-column > * {
        width: 29rem;
        height: 16.3rem
    }
}

@media only screen and (max-width: 350px) {
    [class*="hero-"] > .row > .column {
        width: 100%
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .hero-2 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
    .hero-3 {
        background-image: url(../images/slider/slide-5-fw@2x.jpg)
    }
    .hero-4 {
        background-image: url(../images/slider/dev-video-cta-3@2x.jpg)
    }
    .hero-5 .hero-content.background-image {
        background-image: url(../images/slider/slide-9-fw@2x.jpg)
    }
}

.feature-content,
.feature-image,
.feature-slider {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed
}

.feature-content-inner,
.feature-image-inner,
.feature-slider-inner {
    display: table-cell;
    vertical-align: middle
}

.feature-slider .tm-slider-container {
    width: 100% !important
}

.feature-slider .tms-pagination {
    bottom: 0
}

.feature-content-inner *:first-child {
    margin-top: 0
}

.section-block.feature-1 {
    padding-bottom: 0
}

.feature-1 {
    text-align: center
}

.section-block.feature-2 {
    padding-bottom: 0
}

.feature-2 {
    position: relative
}

.feature-2 .feature-image {
    width: 170%
}

.feature-2.right .feature-image {
    float: right
}

.feature-3 {
    position: relative
}

.feature-3 .feature-image {
    width: 100%;
    height: 100%;
    overflow: hidden
}

.feature-3 .feature-image-inner {
    max-height: 53rem
}

@media only screen and (max-width: 960px) {
    .feature-2 {
        padding-bottom: 7rem
    }
    .feature-2 .feature-image {
        height: 100%
    }
    .header-1 + .content > [class*="feature-"]:first-child {
        padding-top: 5rem
    }
}

@media only screen and (min-width: 768px) {
    .feature-2 .feature-content-inner *:last-child {
        margin-bottom: 0
    }
}

@media only screen and (max-width: 768px) {
    .feature-2 .column,
    .feature-3 .column {
        height: auto !important
    }
    .feature-2.left {
        padding-bottom: 0
    }
    .feature-2 .feature-image {
        width: 100%;
        float: none
    }
    .feature-2.right .column + .column .feature-content {
        margin-bottom: 0
    }
    .feature-3 .feature-image {
        text-align: center
    }
    .feature-3 .feature-image img,
    .feature-3 .feature-slider {
        max-width: 60%;
        margin: 0 auto
    }
    .feature-2.right .feature-image,
    .feature-3 .feature-image,
    .feature-3 .feature-slider {
        margin-bottom: 5rem
    }
    .feature-3 .column:last-child .feature-image {
        margin-bottom: 0
    }
    .feature-3 .column:last-child .feature-content {
        padding-bottom: 0
    }
    .feature-3 .column:last-child .feature-column:last-child {
        margin-bottom: 0
    }
}

@media only screen and (max-width: 480px) {
    .feature-3 .feature-image img,
    .feature-3 .feature-slider {
        max-width: 70%
    }
}

[class*="call-to-action-"] .row,
[class*="call-to-action-"] .column {
    height: 100%
}

.call-to-action-content {
    width: 100%;
    height: 100%;
    display: table !important
}

.call-to-action-content-inner {
    display: table-cell;
    vertical-align: middle
}

[class*="call-to-action-"] .column:last-child .call-to-action-content-inner {
    padding-bottom: 0
}

.call-to-action-content-inner *:first-child {
    margin-top: 0
}

.call-to-action-content-inner *:last-child {
    margin-bottom: 0
}

[class*="call-to-action-"] {
    background-color: inherit
}

.call-to-action-1 {
    padding-left: 3rem;
    padding-right: 3rem;
    font-size: 2.5rem;
    line-height: 3.5rem;
    text-align: center
}

.call-to-action-1 {
    width: 100%;
    background-color: #2c96dd;
    font-weight: 300;
    color: #fff;
    display: block
}

.call-to-action-1:hover {
    background-color: #1ecd6d
}

.call-to-action-1 span {
    display: block
}

.call-to-action-2 {
    padding-left: 3rem;
    padding-right: 3rem;
    text-align: center;
    background: #ea4b35;
    color: #fff
}

.call-to-action-2 p {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 300
}

.call-to-action-3 {
    background-color: #ffbe12;
    color: #fff;
    position: relative
}

.call-to-action-3 p {
    font-size: 2.5rem;
    line-height: 3.5rem;
    font-weight: 300
}

.call-to-action-3 .call-to-action-button {
    padding-right: 0;
    position: absolute;
    top: 0;
    right: 0
}

.call-to-action-3 .call-to-action-button .button {
    padding: 2rem 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
    border-radius: 0;
    display: table
}

.call-to-action-3 .call-to-action-button > .button > span {
    height: 100%;
    display: table-cell;
    vertical-align: middle
}

@media only screen and (max-width: 960px) {
    .call-to-action-1,
    .call-to-action-2 p,
    .call-to-action-3 p {
        font-size: 1.9rem;
        line-height: 2.9rem
    }
}

@media only screen and (max-width: 768px) {
    [class*="call-to-action"] {
        height: auto
    }
    .call-to-action-2 .call-to-action-content-inner,
    .call-to-action-3 .call-to-action-content-inner {
        padding-bottom: 2.5rem;
        text-align: center
    }
    .call-to-action-3 .row {
        max-width: 100%
    }
    .call-to-action-3 .call-to-action-button {
        position: static;
        padding-left: 0;
        padding-right: 0
    }
}

.form-element[class*="border-"] {
    background: #fff
}

.form-honeypot {
    display: none
}

.contact-form-container {
    position: relative;
    margin-bottom: 3rem
}

.contact-form-container .contact-form {
    position: relative
}

.contact-form-container .row {
    margin-left: -1rem;
    margin-right: -1rem
}

.contact-form-container [class*="width-"] {
    padding-left: 1rem;
    padding-right: 1rem
}

.contact-form-container .form-element {
    margin-bottom: 2rem
}

.contact-form-container .form-response {
    font-size: 1.2rem;
    line-height: 1.3;
    color: #666;
    position: absolute;
    bottom: 0
}

.contact-form-container input[type=submit] {
    margin-bottom: 0
}

.contact-form-container input[disabled=disabled][type=submit] {
    opacity: 0.6
}

.contact-1 h2 {
    margin-bottom: 3rem
}

.contact-1 .contact-form-container {
    margin-bottom: 0
}

.contact-1 .contact-form-container .form-response {
    bottom: -3rem
}

.contact-2 .contact-form-container .form-response {
    bottom: -3rem
}

.signup-form-container {
    position: relative;
    margin-bottom: 3rem
}

.signup-form-container .signup-form {
    position: relative
}

.signup-form-container .row {
    margin-left: -1rem;
    margin-right: -1rem
}

.signup-form-container [class*="width-"] {
    padding-left: 1rem;
    padding-right: 1rem
}

.signup-form-container .form-element {
    margin-bottom: 2rem
}

.signup-form-container .form-response {
    font-size: 1.2rem;
    line-height: 1.3;
    position: absolute;
    bottom: 0;
    -ms-word-break: break-all;
    word-break: break-word
}

.signup-form-container input[type=submit] {
    margin-bottom: 2rem
}

.signup-form-container input[disabled=disabled][type=submit] {
    opacity: 0.6
}

.signup-form-container.boxed {
    padding: 4rem
}

.signup-1 .signup-form-container {
    margin-bottom: 0
}

.signup-1 .form-submit {
    width: 100%
}

.signup-1 .signup-form-container .form-response {
    bottom: -1.3rem
}

.signup-2 {
    background-image: url(../images/slider/slide-5-fw.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.signup-2 .signup-form-container {
    padding: 4rem;
    margin-bottom: 0;
    background: rgba(0, 0, 0, .5)
}

.signup-2 .signup-form-container .form-response {
    bottom: 2.5rem
}

.signup-2 .form-submit {
    width: 100%
}

.signup-3 {
    background-image: url(../images/slider/slide-5-fw.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

.signup-3 .signup-inner {
    background: rgba(0, 0, 0, 0)
}

.signup-3 .row {
    text-align: center
}

.signup-3 .signup-form-container {
    width: 30rem;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto
}

.signup-3 .form-element {
    width: 100%
}

.signup-3 .signup-form-container .form-response {
    width: 100%;
    bottom: -1.3rem
}

.search-form-container .row {
    margin-left: -1rem;
    margin-right: -1rem
}

.search-form-container [class*="width-"] {
    padding-left: 1rem;
    padding-right: 1rem
}

.search-form-container input[type=submit] {
    margin-bottom: 0
}

.search-form-container .form-element {
    margin-bottom: 2rem
}

.login-form-container .row {
    margin-left: -1rem;
    margin-right: -1rem
}

.login-form-container [class*="width-"] {
    padding-left: 1rem;
    padding-right: 1rem
}

.login-form-container input[type=submit] {
    margin-bottom: 0
}

.login-form-container .form-element {
    margin-bottom: 2rem
}

.login-form-container .recuperate-password {
    font-size: 1rem;
    display: block
}

@media only screen and (max-width: 768px) {
    .signup-form-container input[type=submit] {
        width: auto
    }
}

@media only screen and (max-width: 480px) {
    .signup-3 .signup-form-container {
        width: 100%
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .signup-2 {
        background-image: url(../images/slider/slide-5-fw@2x.jpg)
    }
}

.testimonial-1 h6 {
    margin-bottom: 4rem;
    font-size: 1.3rem;
    text-transform: uppercase
}

.testimonial-1 blockquote {
    width: 70%;
    padding: 0;
    margin-bottom: 0
}

.testimonial-1 blockquote.avatar span,
.testimonial-1 blockquote.avatar span img {
    width: 9rem;
    height: 9rem
}

.testimonial-1 p {
    margin-top: 3rem
}

.testimonial-1 cite {
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.1rem
}

.testimonial-2 h6 {
    margin-bottom: 1rem;
    font-size: 1.3rem;
    text-transform: uppercase
}

.testimonial-2 .brief {
    height: 100%;
    border-right: 1px solid #ddd;
    display: table
}

.testimonial-2 .brief p:last-child {
    margin-bottom: 0
}

.testimonial-2 .brief-inner {
    display: table-cell;
    vertical-align: middle
}

.testimonial-2 blockquote {
    width: 100%;
    padding: 0;
    margin-bottom: 3rem
}

.testimonial-2 blockquote:last-child {
    margin-bottom: 0
}

.testimonial-2 cite {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.1rem
}

.testimonial-3 [class*="content-grid"] {
    margin-bottom: -3rem
}

.testimonial-3 h6 {
    margin-bottom: 4rem;
    font-size: 1.3rem;
    text-transform: uppercase
}

.testimonial-3 blockquote {
    width: 100%;
    padding: 0;
    margin: 0
}

.testimonial-3 cite {
    text-transform: uppercase;
    letter-spacing: 0.1rem
}

.testimonial-4 [class*="content-grid"] {
    margin-bottom: -3rem
}

.testimonial-4 h6 {
    margin-bottom: 4rem;
    font-size: 1.3rem;
    text-transform: uppercase
}

.testimonial-4 blockquote {
    height: 100%;
    padding: 2.5rem;
    margin: 0;
    border-radius: 0.3rem;
    border: 1px solid #ddd
}

.testimonial-4 cite {
    text-transform: uppercase;
    letter-spacing: 0.1rem
}

.testimonial-5 {
    color: #fff;
    background-image: url(../images/slider/slide-2-fs-hp.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.testimonial-5 blockquote {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    float: none
}

.testimonial-5 cite {
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0.1rem
}

.testimonial-slider {
    width: 100% !important;
    height: 35rem;
    margin: 0 auto;
    background: none
}

.testimonial-slider .tms-pagination {
    bottom: 0
}

.testimonial-slider.left,
.testimonial-slider.right {
    margin: 0
}

.testimonial-slider blockquote,
.center .testimonial-slider blockquote {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.testimonial-slider.left blockquote,
.testimonial-slider.left .tms-pagination {
    text-align: left
}

.testimonial-slider.left blockquote {
    padding-left: 0;
    padding-right: 1rem
}

.testimonial-slider.right blockquote,
.testimonial-slider.right .tms-pagination {
    text-align: right
}

.testimonial-slider.right blockquote {
    padding-left: 1rem;
    padding-right: 0
}

@media only screen and (max-width: 960px) {
    .testimonial-1 p,
    .testimonial-5 .testimonial-slider p {
        font-size: 1.9rem;
        line-height: 2.9rem
    }
}

@media only screen and (max-width: 768px) {
    .testimonial-2 .brief {
        border-right: none
    }
    .testimonial-2 .brief h6:last-child,
    .testimonial-2 .brief p:last-child {
        margin-bottom: 3rem
    }
    .testimonial-3 blockquote {
        height: 100%;
        padding-bottom: 3rem;
        border-bottom: 1px solid #ddd
    }
    .testimonial-3 .grid-item:last-child blockquote {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none
    }
    .testimonial-slider.center-on-mobile {
        margin: 0 auto
    }
    .testimonial-slider.center-on-mobile blockquote,
    .testimonial-slider.center-on-mobile .tms-pagination {
        text-align: center
    }
    .testimonial-slider.center-on-mobile blockquote {
        padding: 0 !important
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .testimonial-5 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
}

[class*="team-"] .signature {
    width: 100%
}

[class*="team-"] .thumbnail {
    float: none
}

.social-list a {
    margin-right: 1rem
}

.social-list:not(.center) li:last-child a {
    margin-right: 0
}

.right .social-list a,
.social-list.right a {
    margin-left: 1rem;
    margin-right: 0
}

.social-list.right li:last-child a {
    margin-left: 0
}

.center .social-list a,
.center .social-list li:last-child a,
.social-list.center a {
    margin-right: 0.5rem;
    margin-left: 0.5rem
}

.social-list [class*="icon-"] {
    margin: 0
}

.team-slider .social-list,
.team-slider .social-list li {
    padding: 0;
    margin: 0
}

.team-1 [class*="content-grid"] {
    margin-bottom: -3rem
}

.team-1 .team-content-info h2 {
    margin-bottom: 3rem
}

.team-1 .team-content-info h5 {
    margin-top: 2rem;
    margin-bottom: 0
}

.team-1 h6.occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem
}

.team-2 {
    background: #f4f4f4
}

.team-2 [class*="content-grid"] {
    margin-bottom: -3rem
}

.team-2 .thumbnail {
    margin-bottom: 1rem
}

.team-2 .team-content {
    background: #fff
}

.team-2 .team-content-info {
    padding: 2rem
}

.team-2 .team-content-info > *:first-child {
    margin-top: 0
}

.team-2 .team-content-info h2 {
    margin-bottom: 3rem
}

.team-2 .team-content-info h5 {
    margin-top: 1rem;
    margin-bottom: 0
}

.team-2 h6.occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem
}

.team-2 .social-list .list-label {
    margin-right: 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: #999;
    text-transform: uppercase;
    display: inline-block
}

.team-2 .social-list.boxed {
    margin: 0 -2rem -2rem -2rem;
    padding: 2rem;
    background: #e7e7e7
}

.team-3 {
    background-image: url(../images/team/page-01-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.team-3 {
    color: #fff;
    text-align: left
}

body.boxed .team-3 {
    padding-left: 0;
    padding-right: 0
}

.team-3 .slider-column {
    padding-left: 0;
    padding-right: 0
}

.team-3 .team-slider {
    height: 45rem;
    margin-bottom: 0;
    background: none
}

.team-3 .team-slider .tms-slides {
    margin-bottom: 2rem
}

.team-3 .team-slider li {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.team-3 .team-slider .tms-pagination {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    bottom: 0
}

.team-3 .team-content {
    margin-bottom: 4rem
}

.team-3 .team-content-info {
    color: #fff
}

.team-3 .team-content-info h2 {
    margin-bottom: 3rem
}

.team-3 .team-content-info h5 {
    margin-top: 2rem;
    margin-bottom: 0;
    color: #fff
}

.team-3 h6.occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem
}

.team-3 .social-list a {
    color: #fff
}

.team-3 .social-list a:hover {
    opacity: 0.5
}

.section-block.team-4 {
    padding-bottom: 0
}

.team-4 {
    text-align: center;
    overflow: hidden
}

.team-4 .grid-item {
    margin-bottom: 0
}

.team-4 .thumbnail {
    margin-bottom: 0
}

.team-4 .overlay-info > span {
    padding: 3rem;
    font-size: 1.4rem;
    line-height: 1.8;
    font-weight: normal
}

.team-4 .team-content-info h5 {
    margin-top: 2rem;
    margin-bottom: 0
}

.team-4 h6.occupation {
    margin-top: 0;
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: 1.2rem;
    color: #999;
    letter-spacing: 0.1rem
}

.team-4 .social-list {
    display: block;
    margin-top: 2rem
}

.team-4 .social-list a {
    margin: 0 0.5rem;
    color: #fff
}

.team-4 .social-list a:hover {
    opacity: 0.5
}

.mobile .team-4 .overlay-info {
    height: auto;
    background: none;
    position: relative;
    color: #999;
    -webkit-transition: none;
    transition: none;
    opacity: 1;
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.mobile .team-4 .overlay-info > span {
    padding-left: 1rem;
    padding-right: 1rem
}

.mobile .team-4 .social-list a {
    color: #999
}

@media only screen and (min-width: 479px) and (max-width: 960px) {
    .team-2 .content-grid-4 .grid-item,
    .team-4 .content-grid-4 .grid-item {
        width: 50%
    }
}

[class*="portfolio-"] > .row > .column {
    padding-left: 0;
    padding-right: 0
}

[class*="portfolio-"] [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

[class*="portfolio-"] [class*="content-grid"] .grid-item {
/*
    padding-left: 1.5rem;
    padding-right: 1.5rem
*/

}

[class*="portfolio-"] .thumbnail {
    margin-bottom: 0;
    float: none
}

.portfolio-filter-menu > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

[class*="portfolio-"].small-margins > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem
}

[class*="portfolio-"].small-margins.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

body.boxed [class*="portfolio-"].small-margins.full-width > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem
}

[class*="portfolio-"].small-margins.no-margins.full-width > .row > .column {
    padding-left: 0rem;
    padding-right: 0rem
}

[class*="portfolio-"].small-margins.full-width [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

[class*="portfolio-"].small-margins [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-top: -0.5rem;
    margin-left: 0;
    margin-right: 0
}

[class*="portfolio-"].small-margins [class*="content-grid"] .grid-item {
    margin-bottom: 0;
    padding: 0.5rem;
    position: relative
}

[class*="portfolio-"].no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

[class*="portfolio-"].no-margins [class*="content-grid"],
[class*="portfolio-"].no-margins [class*="content-grid"] .grid-item {
    padding: 0 !important;
    margin: 0 !important
}

[class*="portfolio-"].full-width .row {
    max-width: 100%
}

[class*="portfolio-"].full-width [class*="content-grid"] {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

[class*="portfolio-"].full-width > .row > .column {
    padding-left: 0;
    padding-right: 0
}

[class*="portfolio-"].full-width.no-margins {
    padding: 0 !important
}

.portfolio-filter-menu + [class*="portfolio-"].full-width.no-margins {
    padding-top: 7rem
}

[class*="portfolio-"] .masonry-grid {
    margin-right: 0;
    margin-left: 0
}

[class*="portfolio-"].small-margins.no-padding-bottom {
    padding-bottom: 0.5rem !important
}

.portfolio-filter-menu + [class*="portfolio-"].no-padding-top {
    padding-top: 0 !important
}

.portfolio-filter-menu + .portfolio-5.no-margins.no-padding-top {
    margin-top: 0
}

[class*="portfolio-"] .project-title,
[class*="portfolio-"] .project-description {
    display: block
}

[class*="portfolio-"] .project-title + .project-description {
    margin-top: 0.5rem
}

[class*="portfolio-"] .item-description {
    width: 100%;
    margin-top: 2rem
}

[class*="portfolio-"] .item-description .project-title {
    margin-top: 0;
    margin-bottom: 0.5rem
}

[class*="portfolio-"] .item-description *:last-child {
    margin-bottom: 0rem
}

[class*="portfolio-"] [class*="content-grid"] {
    margin-bottom: -3rem
}

[class*="portfolio-"].small-margins [class*="content-grid"] {
    margin-bottom: -0.5rem
}

[class*="portfolio-"].no-margins [class*="content-grid"] {
    margin-bottom: 0
}

[class*="portfolio-"].full-width.no-padding-bottom [class*="content-grid"] {
    margin-bottom: 0
}

.masonry-grid .content-outer {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
    bottom: 0;
    left: 0
}

.masonry-grid .thumbnail + .content-outer {
    position: absolute
}

.masonry-grid .content-inner {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: table-cell;
    vertical-align: middle
}

.masonry-grid .content-inner .row {
    margin: 0
}

.portfolio-2 .grid-description *:first-child {
    margin-top: 0
}

.portfolio-2 .grid-description {
    width: 100%;
    height: 100%;
    padding: 2rem;
    background: #222;
    display: table
}

.portfolio-2 .grid-description-inner {
    height: 100%;
    display: table-cell;
    vertical-align: middle
}

.portfolio-2 .grid-description h2 {
    color: #fff
}

.portfolio-2 h2:last-child,
.portfolio-2 p:last-child {
    margin-bottom: 0
}

.portfolio-3 .item-description {
    text-align: center
}

.portfolio-3 h5:last-child,
.portfolio-3 p:last-child {
    margin-bottom: 0
}

.portfolio-4 .item-description {
    padding: 2rem;
    margin-top: 0;
    text-align: center;
    background: #fff
}

.portfolio-4 h5:last-child,
.portfolio-4 p:last-child {
    margin-bottom: 0
}

.portfolio-5 [class*="content-grid"] {
    margin-bottom: -1.5rem
}

.portfolio-5.no-padding-top {
    padding-top: 0
}

.portfolio-5.no-padding-bottom {
    padding-bottom: 1.5rem !important
}

.portfolio-5.small-margins.no-padding-bottom {
    padding-bottom: 0.5rem !important
}

.portfolio-5 > .row > .column {
    padding-left: 0;
    padding-right: 0
}

.portfolio-5.small-margins > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem
}

.portfolio-5.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.portfolio-5.full-width > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

body.boxed .portfolio-5.full-width > .row > .column {
    padding-left: 0;
    padding-right: 0
}

.portfolio-5.small-margins.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.portfolio-5.no-margins.full-width > .row > .column {
    padding-left: 0rem;
    padding-right: 0rem
}

.portfolio-5.full-width [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.portfolio-5 [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.portfolio-5 [class*="content-grid"] {
    margin-top: -1.5rem
}

.portfolio-5.small-margins [class*="content-grid"] {
    margin-top: -0.5rem
}

.portfolio-5 [class*="content-grid"] .grid-item {
    padding: 1.5rem;
    margin-bottom: 0;
    position: relative
}

.portfolio-5.small-margins [class*="content-grid"] .grid-item {
    padding: 0.5rem
}

.portfolio-5 .grid-item.large {
    width: 50%
}

.portfolio-5 .grid-item img {
    width: 100%
}

.portfolio-5 .thumbnail {
    height: 100%;
    overflow: hidden
}

.portfolio-5 .caption {
    position: absolute;
    left: 2rem;
    bottom: 2rem
}

.portfolio-5 .content-outer,
.masonry-set-dimensions .content-outer {
    width: 100%;
    height: 100%;
    display: table;
    position: relative;
    bottom: 0;
    left: 0
}

.portfolio-5 .thumbnail + .content-outer,
.masonry-set-dimensions .thumbnail + .content-outer {
    position: absolute
}

.portfolio-5 .content-inner,
.masonry-set-dimensions .content-inner {
    width: 100%;
    height: 100%;
    padding: 3rem;
    display: table-cell;
    vertical-align: middle
}

.portfolio-5 .content-inner > *:first-child,
.masonry-set-dimensions .content-inner > *:first-child {
    margin-top: 0
}

.portfolio-5 .content-inner > *:last-child,
.masonry-set-dimensions .content-inner > *:first-child {
    margin-bottom: 0
}

.portfolio-5 .content-slider,
.portfolio-5 video,
.portfolio-5 iframe {
    width: 100% !important;
    height: 100% !important
}

.portfolio-5 .content-inner img,
.portfolio-5 .content-slider img {
    width: auto
}

.portfolio-5 .mejs-container {
    width: 20rem;
    height: 3rem;
    margin-left: -10rem;
    margin-top: -1.5rem;
    position: absolute;
    left: 50%;
    top: 50%
}

.portfolio-6 [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0
}

.portfolio-6 [class*="content-grid"] .grid-item {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 0;
    position: relative
}

.portfolio-6 .thumbnail {
    float: left
}

.portfolio-6 .description {
    padding: 2rem;
    text-align: center;
    background: #fff
}

.portfolio-6 h5:last-child,
.portfolio-6 p:last-child {
    margin-bottom: 0
}

.portfolio-6 .half.image-left .thumbnail,
.portfolio-6 .half.image-right .thumbnail {
    width: 50%
}

.portfolio-6 .two-third.image-left .thumbnail,
.portfolio-6 .two-third.image-right .thumbnail {
    width: 66.66667%
}

.portfolio-6 .half.image-top .thumbnail,
.portfolio-6 .half.image-bottom .thumbnail {
    width: 100%
}

.portfolio-6 .two-third.image-top .thumbnail,
.portfolio-6 .two-third.image-bottom .thumbnail {
    width: 100%
}

.portfolio-6 .half.image-right .thumbnail,
.portfolio-6 .two-third.image-right .thumbnail {
    float: right
}

.portfolio-6 .image-left.no-description .thumbnail,
.portfolio-6 .image-right.no-description .thumbnail {
    width: 100%
}

.portfolio-6 .item-description {
    margin-top: 0;
    height: 100%;
    background: #f9f9f9;
    text-align: center;
    display: table;
    position: relative
}

.portfolio-6 .item-description-inner {
    height: 100%;
    padding: 1rem 3rem 3rem 3rem;
    display: table-cell;
    vertical-align: middle
}

.portfolio-6 .half.image-left .item-description {
    width: 50%;
    text-align: left;
    float: right
}

.portfolio-6 .half.image-right .item-description {
    width: 50%;
    text-align: right;
    float: left
}

.portfolio-6 .two-third.image-left .item-description {
    width: 33.33333%;
    text-align: left;
    float: right
}

.portfolio-6 .two-third.image-right .item-description {
    width: 33.33333%;
    text-align: right;
    float: left
}

.portfolio-6 .half.image-top .item-description {
    width: 100%;
    height: 50%;
    text-align: center
}

.portfolio-6 .half.image-bottom .item-description {
    width: 100%;
    height: 50%;
    text-align: center
}

.portfolio-6 .two-third.image-top .item-description {
    width: 100%;
    height: 33.33333%;
    text-align: center
}

.portfolio-6 .item-description:after {
    width: 0;
    height: 0;
    margin-top: -10px;
    border: solid transparent;
    content: " ";
    position: absolute;
    pointer-events: none;
    border-color: #f9f9f9;
    border-width: 10px;
    top: 50%;
    z-index: 10
}

.portfolio-6 .item-description:after {
    margin-left: -10px;
    border: solid transparent;
    border-bottom-color: #f9f9f9;
    border-width: 10px;
    top: auto;
    bottom: 100%;
    left: 50%
}

.portfolio-6 .image-left .item-description:after {
    margin-top: -10px;
    border: solid transparent;
    border-right-color: #f9f9f9;
    border-width: 10px;
    top: 50%;
    right: 100%;
    bottom: auto;
    left: auto
}

.portfolio-6 .image-right .item-description:after {
    margin-top: -10px;
    border: solid transparent;
    border-left-color: #f9f9f9;
    border-width: 10px;
    top: 50%;
    bottom: auto;
    right: -20px;
    left: auto
}

.portfolio-6 .image-bottom .item-description:after {
    margin-left: -10px;
    border: solid transparent;
    border-top-color: #f9f9f9;
    border-width: 10px;
    top: auto;
    bottom: -2rem;
    left: 50%
}

.portfolio-7 .column.slider-column {
    padding-left: 0;
    padding-right: 0
}

.portfolio-7 .portfolio-recent-slider {
    padding: 0;
    margin-bottom: 0;
    background: none
}

.portfolio-7 .portfolio-recent-slider li {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.portfolio-7 .previous-portfolio-recent-slider,
.portfolio-7 .next-portfolio-recent-slider {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    line-height: 3rem;
    text-align: center;
    display: inline-block;
    border: 1px solid #ddd
}

.portfolio-7 .button {
    line-height: 1
}

.portfolio-7 [class*="icon-"] {
    margin: 0
}

.portfolio-7 .grid-description {
    width: 100%;
    height: 100%;
    padding: 0;
    display: block
}

.ie-browser .portfolio-5 .row .masonry-grid,
.ie-browser .portfolio-6 .row .masonry-grid,
.safari-browser [class*="portfolio"].full-width.no-margins .column.width-12 {
    width: 100.2%
}

.portfolio-filter-menu {
    padding-top: 3.5rem;
    padding-bottom: 0rem;
    text-align: center
}

.portfolio-filter-menu.left {
    text-align: left
}

.portfolio-filter-menu.left li:first-child a {
    padding-left: 0;
    margin-left: 0
}

.portfolio-filter-menu.right {
    text-align: right
}

.portfolio-filter-menu.right li:last-child a {
    padding-right: 0;
    margin-right: 0
}

.portfolio-filter-menu ul {
    width: 100%;
    padding-top: 3.5rem;
    padding-bottom: 0rem;
    margin-bottom: 0;
    list-style: none
}

.portfolio-filter-menu li {
    padding: 0 2rem;
    display: inline-block;
    font-size: 1.2rem;
    line-height: 1;
    text-transform: uppercase
}

.portfolio-filter-menu a {
    padding: 1rem 0.8rem;
    -webkit-transition-property: background, border-color, color, opacity;
    transition-property: background, border-color, color, opacity;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms
}

.portfolio-filter-menu a:hover {
    color: #333
}

.portfolio-filter-menu a.active {
    color: #333;
    border-bottom: 1px solid #333
}

.portfolio-media,
.portfolio-media img,
.portfolio-media iframe,
.portfolio-media object {
    margin-bottom: 3rem
}

.portfolio-media *:last-child {
    margin-bottom: 0
}

.project-details h6 {
    margin-bottom: 1rem
}

.project-details ul {
    margin-bottom: 2rem
}

@media only screen and (max-width: 1300px) {
    [class*="portfolio-"] [class*="content-grid-5"] .grid-item,
    [class*="portfolio-"] [class*="content-grid-6"] .grid-item {
        width: 25%
    }
}

@media only screen and (max-width: 1140px) {
    [class*="portfolio-"] [class*="content-grid"] .grid-item {
        width: 33.33333%
    }
    [class*="portfolio-"] .content-grid-2 .grid-item,
    [class*="portfolio-"].full-width.small-margins .content-grid-2 .grid-item,
    [class*="portfolio-"].full-width.no-margins .content-grid-2 .grid-item {
        width: 50%
    }
}

@media only screen and (max-width: 960px) {
    [class*="portfolio-"] [class*="content-grid"] .grid-item {
        width: 50%
    }
    [class*="portfolio-"].full-width.small-margins [class*="content-grid"] .grid-item,
    [class*="portfolio-"].full-width.no-margins [class*="content-grid"] .grid-item {
        width: 33.33333%
    }
    .portfolio-7 .column {
        width: 100%
    }
    .portfolio-7 .grid-description {
        margin-bottom: 2rem
    }
}

@media only screen and (max-width: 768px) {
    .portfolio-filter-menu.left.center-on-mobile li:first-child a,
    .portfolio-filter-menu.right.center-on-mobile li:last-child a {
        padding: 1rem 0.8rem
    }
    [class*="portfolio-"].full-width [class*="content-grid"] .grid-item,
    [class*="portfolio-"].full-width.small-margins [class*="content-grid"] .grid-item,
    [class*="portfolio-"].full-width.no-margins [class*="content-grid"] .grid-item {
        width: 50%
    }
    .portfolio-5 [class*="content-grid"] .grid-item.large,
    .portfolio-5 [class*="content-grid"] .grid-item.portrait.large {
        width: 100%
    }
    .portfolio-6 .two-third.horizontal,
    .portfolio-6 .two-third.horizontal .thumbnail {
        width: 100% !important;
        height: auto !important
    }
    .portfolio-6 .two-third.image-left .item-description,
    .portfolio-6 .two-third.image-right .item-description {
        width: 100%;
        float: left
    }
    .portfolio-6 .two-third.image-left .item-description:after,
    .portfolio-6 .two-third.image-right .item-description:after {
        margin-left: -10px;
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        top: auto !important;
        bottom: 100%;
        left: 50%
    }
}

@media only screen and (max-width: 600px) {
    [class*="portfolio-"] [class*="content-grid"] .grid-item,
    [class*="portfolio-"].full-width [class*="content-grid"] .grid-item {
        width: 100%
    }
    .portfolio-6 .half.horizontal,
    .portfolio-6 .half.horizontal .thumbnail {
        width: 100% !important;
        height: auto !important
    }
    .portfolio-6 .half.image-left .item-description,
    .portfolio-6 .half.image-right .item-description {
        width: 100%;
        float: left
    }
    .portfolio-6 .image-left .item-description:after,
    .portfolio-6 .image-right .item-description:after {
        margin-left: -10px;
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 10px;
        top: auto !important;
        bottom: 100%;
        left: 50%
    }
}

@media only screen and (max-width: 480px) {
    [class*="portfolio-"].full-width.small-margins [class*="content-grid"] .grid-item,
    [class*="portfolio-"].full-width.no-margins [class*="content-grid"] .grid-item {
        width: 100%
    }
}

[class*="logos-"] .grid-item {
    margin-bottom: 0;
    text-align: center
}

.logos-1 [class*="content-grid"] {
    margin-top: -2rem;
    margin-bottom: -2rem
}

.logos-1 a,
.logos-1 span {
    padding: 2rem 0;
    display: block;
    opacity: 1
}

.logos-1 a:hover {
    opacity: 0.5
}

.logos-2 {
    background-image: url(../images/slider/slide-5-fw@2x.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.logos-2 [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0
}

.logos-2 .grid-item {
    padding: 0 0 1px 1px
}

.logos-2 a,
.logos-2 span {
    width: 100%;
    padding: 5rem 1.5rem;
    background-color: rgba(0, 0, 0, 0.4);
    color: #fff;
    opacity: 1;
    display: block
}

.logos-2 a:hover {
    background-color: rgba(0, 0, 0, 0.6)
}

.logos-3 [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0
}

.logos-3 .grid-item {
    padding: 0;
    border-right: 1px solid #ddd;
    border-top: 1px solid #ddd;
    position: relative
}

.logos-3 .grid-item:before {
    height: 100%;
    border-left: 1px solid #ddd;
    content: "";
    position: absolute;
    top: 0;
    left: -1px
}

.logos-3 .grid-item:after {
    width: 100%;
    border-bottom: 1px solid #ddd;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0
}

.logos-3 a,
.logos-3 span {
    width: 100%;
    padding: 5rem 1.5rem;
    opacity: 1;
    display: block
}

.logos-3 a:hover {
    background-color: #ddd
}

.logos-4 [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0;
    overflow: hidden
}

.logos-4 .grid-item {
    padding: 0;
    position: relative
}

.logos-4 .grid-item:before {
    height: 100%;
    border-left: 1px solid #ddd;
    content: "";
    position: absolute;
    top: 0;
    left: -1px
}

.logos-4 .grid-item:after {
    width: 100%;
    border-bottom: 1px solid #ddd;
    content: "";
    position: absolute;
    bottom: -1px;
    left: 0
}

.logos-4 a,
.logos-4 span {
    width: 100%;
    padding: 5rem 1.5rem;
    opacity: 1;
    display: block
}

.logos-4 a:hover {
    opacity: 0.5
}

.logos-5 .logo-slider {
    height: 15rem;
    margin-bottom: 0;
    background: none
}

.logos-5 > .row {
    margin-top: -3rem
}

.logos-5 .logo-slider .tms-pagination {
    bottom: 0
}

.logos-5 .logo-slider .tms-bullet-nav {
    background: #333
}

@media only screen and (max-width: 960px) {
    [class*="logos-"] {
        height: auto
    }
    [class*="logos-"] .grid-item {
        width: 33.3333%
    }
    .logos-2 a,
    .logos-2 span {
        padding: 4.5rem 1.5rem
    }
}

@media only screen and (max-width: 768px) {
    [class*="logos-"] .grid-item {
        width: 33.3333%
    }
    .logos-2 a,
    .logos-3 a,
    .logos-4 a,
    .logos-2 span,
    .logos-3 span,
    .logos-4 span {
        padding: 3rem 1.5rem
    }
}

@media only screen and (max-width: 480px) {
    [class*="logos-"] .grid-item {
        width: 50%
    }
    .logos-3 a,
    .logos-2 a,
    .logos-4 a,
    .logos-2 span,
    .logos-3 span,
    .logos-4 span {
        padding: 2rem 1.5rem
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .logos-2 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
}

.clients-1 {
    line-height: 1
}

.clients-1 .client-name {
    margin-top: 0;
    margin-bottom: 1rem
}

.clients-1 .client-description {
    font-size: 1.1rem;
    opacity: 0.7
}

@media only screen and (max-width: 768px) {
    [class*="clients-"] .grid-item {
        width: 33.3333%
    }
    [class*="clients-"] .client-description {
        display: none
    }
    [class*="clients-"] .client-name {
        font-size: 1.4rem
    }
}

@media only screen and (max-width: 480px) {
    [class*="clients-"] .grid-item {
        width: 50%
    }
}

.social-1 {
    text-align: center
}

.social-1 h6 {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem
}

.social-1 ul {
    margin-bottom: 0;
    list-style: none
}

.social-1 ul li {
    padding: 0 1rem;
    margin-bottom: 1rem;
    display: inline-block
}

.social-2 {
    padding-top: 0;
    padding-bottom: 0;
    text-align: center
}

body.boxed .social-2 {
    padding-left: 0;
    padding-right: 0
}

.safari-browser .social-2 {
    width: 100.4%
}

.social-2 [class*="content-grid-"] {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.social-2.full-width [class*="content-grid-"] {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0
}

.social-2 .grid-item {
    padding-right: 0;
    padding-left: 0;
    margin-bottom: 0
}

.social-2 a {
    width: 100%;
    height: 15rem;
    margin: 0;
    font-size: 2rem;
    line-height: 2rem;
    font-family: "Times New Roman", serif;
    font-style: italic;
    display: table
}

.social-2 a:hover {
    background: none;
    color: #999
}

.social-2 a span {
    display: table-cell;
    vertical-align: middle
}

.social-2 [class*="icon"] {
    width: 100%;
    margin: 0 0 1rem 0;
    font-size: 3rem;
    display: inline-block
}

.social-3 {
    text-align: center;
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.social-3 ul {
    margin-bottom: -1rem;
    list-style: none;
    line-height: 1
}

.social-3 ul li {
    padding: 0 1rem;
    margin-bottom: 1rem;
    display: inline-block;
    text-align: center
}

.social-3 a[class*="icon"] {
    width: 5rem;
    height: 5rem;
    margin: 0;
    line-height: 5rem;
    font-weight: normal;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%
}

.social-3 a {
    color: #fff
}

.social-3 a:hover {
    background: #fff
}

.social-4 {
    text-align: center
}

.social-4 h6 {
    font-size: 1.3rem;
    text-transform: uppercase;
    margin-bottom: 1.5rem
}

.social-4 ul {
    margin-bottom: -1rem;
    list-style: none
}

.social-4 ul li {
    padding: 0 1rem;
    margin: 0 2rem 1rem 2rem;
    display: inline-block
}

.social-4 ul li a {
    font-size: 1.7rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-weight: bold;
    color: #999
}

@media only screen and (max-width: 480px) {
    .social-2 ul li {
        width: 50%
    }
    .social-2 a {
        width: 100%
    }
    .social-4 ul li {
        display: block
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .social-3 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
}

[class*="stats-"] > .row,
[class*="stats-"] > .row > .column {
    height: 100%
}

[class*="stats-"] .stat-content {
    width: 100%;
    height: 100%;
    display: table
}

[class*="stats-"] .stat-content-inner {
    height: 100%;
    display: table-cell;
    vertical-align: middle
}

[class*="stats-"] .stat {
    width: 100%;
    height: 100%;
    display: table
}

[class*="stats-"] .stat-inner {
    display: table-cell;
    vertical-align: middle;
    text-align: center
}

[class*="stats-"].left .stat-inner {
    text-align: left
}

[class*="stats-"].center .stat-inner {
    text-align: center
}

[class*="stats-"].right .stat-inner {
    text-align: right
}

.stats-1 {
    color: #fff;
    background-image: url(../images/slider/slide-2-fs-hp@2x.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative
}

.stats-1 [class*="content-grid"] {
    margin-bottom: -3rem
}

.stats-1 .counter {
    font-size: 6rem;
    line-height: 1;
    font-weight: normal
}

.stats-1 p:last-child {
    margin-bottom: 0
}

.stats-2 {
    color: #666
}

.stats-2 [class*="content-grid"] {
    margin-bottom: -2rem
}

.stats-2 .grid-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    border-right: 1px solid #ddd
}

.stats-2 .grid-item:last-child {
    border: none
}

.stats-2 .counter {
    margin-bottom: 0.5rem;
    font-size: 3rem;
    line-height: 1;
    font-weight: bold
}

.stats-2 .description {
    font-size: 1.2rem;
    text-transform: uppercase
}

.stats-2 p:last-child {
    margin-bottom: 0
}

.stats-3 [class*="content-grid"] {
    margin-top: -2rem;
    margin-bottom: -2rem
}

.stats-3 .grid-item {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 0;
    border-right: 1px dotted #ddd
}

.stats-3 .grid-item:last-child {
    border: none
}

.stats-3 .counter {
    margin-bottom: 0.5rem;
    font-size: 3rem;
    line-height: 1;
    font-weight: bold
}

.stats-3 .mega-stat .counter {
    margin-bottom: 0.5rem;
    font-size: 7rem;
    line-height: 1;
    font-weight: bold
}

.stats-3 .description {
    font-size: 1.2rem;
    text-transform: uppercase;
    color: #999
}

.stats-3 .mega-stat .description {
    margin-bottom: 0;
    font-size: 2rem;
    line-height: 1;
    font-weight: bold
}

.stats-3 .description {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px dotted #ddd;
    font-weight: bold
}

.stats-3 .description:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border: none
}

.stats-3 p:last-child {
    margin-bottom: 0
}

.stats-3[class*="border-"] {
    border-width: 0
}

.stats-3[class*="border-"] * {
    border-color: inherit
}

@media only screen and (max-width: 1023px) {
    .stats-3 .mega-stat .counter {
        font-size: 5rem
    }
}

@media only screen and (max-width: 768px) {
    [class*="stats-"] {
        height: auto !important
    }
    .stats-2 .row-1 {
        border-bottom: 1px solid #ddd
    }
    .stats-2 .row-1:nth-of-type(even),
    .stats-2 .row-2:nth-of-type(even) {
        border-right: none !important
    }
    .stats-3 .grid-item.row-1 {
        border-bottom: 1px dotted #ddd;
        border-right: none
    }
    .stats-3 .grid-item {
        border-right: none
    }
    .stats-3 .row-1:nth-of-type(even),
    .stats-3 .row-2:nth-of-type(even) {
        border-right: none !important
    }
    .stats-3 .description {
        border-bottom: 1px dotted #ddd
    }
    [class*="stats-"].center-on-mobile .stat-inner {
        text-align: center
    }
}

@media only screen and (max-width: 480px) {
    .stats-2 .grid-item {
        border-right: none;
        border-bottom: 1px solid #ddd
    }
    .stats-3 .counter,
    .stats-3 .mega-stat .counter {
        font-size: 4rem
    }
    .stats-3 .mega-stat .description {
        font-size: 1.2rem
    }
    .stats-3 .grid-item.row-1 {
        border: none
    }
    .stats-3 .description {
        margin-bottom: 4rem;
        padding-bottom: 0;
        border: none
    }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .stats-1 {
        background-image: url(../images/slider/slide-2-fs-hp@2x.jpg)
    }
}

.section-block.fullscreen-sections-wrapper,
.section-block.fullscreen-section {
    padding-top: 0;
    padding-bottom: 0
}

.fullscreen-sections-wrapper {
    position: relative;
    z-index: 0
}

.fullscreen-section {
    width: 100%;
    height: 100%;
    height: 100vh;
    display: table;
    table-layout: fixed;
    overflow: hidden;
    position: relative;
    z-index: 0
}

body.boxed .fullscreen-section {
    padding-left: 0;
    padding-right: 0
}

.fullscreen-section.in-view {
    z-index: 1
}

.fullscreen-section .background-image {
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: 0
}

.fullscreen-section.background-fixed .background-image,
.fullscreen-section .background-slider-wrapper {
    width: 100%;
    height: 100vh;
    position: absolute;
    background-attachment: fixed;
    z-index: 0
}

.fullscreen-section .background-slider-wrapper {
    height: 100%
}

.fullscreen-section.background-fixed .background-slider-wrapper {
    position: fixed
}

.webkit .fullscreen-section .background-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    -webkit-backface-visibility: hidden;
    top: 0;
    left: 0
}

.webkit .fullscreen-section.background-fixed .background-image-wrapper {
    position: fixed
}

.webkit .fullscreen-section.background-fixed .background-image {
    position: relative;
    background-attachment: scroll
}

.webkit.side-nav-open .background-fixed .background-image-wrapper {
    position: absolute
}

.mobile .fullscreen-section {
    height: auto
}

.mobile .fullscreen-section .background-image-wrapper,
.mobile .fullscreen-section.background-fixed .background-image-wrapper,
.mobile .fullscreen-section .background-image {
    width: 100vw;
    position: relative !important
}

.fs-pagination {
    width: 2.8rem;
    padding: 1rem;
    text-align: center;
    visibility: visible;
    opacity: 0;
    position: fixed;
    right: 25px;
    top: 50%;
    z-index: 10;
    -webkit-transition-property: opacity, visibility;
    transition-property: opacity, visibility;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms;
    -webkit-backface-visibility: hidden
}

.fs-bullet-nav {
    width: 0.8rem;
    height: 0.8rem;
    display: inline-block;
    background: #fff;
    opacity: 0.5;
    border-radius: 50%
}

.nav-dark .fs-bullet-nav {
    background: #333
}

.fullscreen-sections-wrapper.nav-dark .fs-bullet-nav {
    background: #333
}

.fs-bullet-nav:hover {
    opacity: 1
}

.fs-bullet-nav.active {
    opacity: 1
}

.mobile .fs-pagination {
    display: none
}

.fullscreen-section .fullscreen-inner {
    width: 100%;
    height: 100%;
    padding-top: 7rem;
    padding-bottom: 7rem;
    background: rgba( 0, 0, 0, 0.6);
    display: table-cell;
    vertical-align: middle;
    position: relative;
    z-index: 2
}

.fullscreen-section.no-overlay .fullscreen-inner {
    background: rgba( 0, 0, 0, 0)
}

body.boxed .fullscreen-sections-wrapper .fullscreen-inner,
body.boxed .fullscreen-section .fullscreen-inner {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.fullscreen-section footer {
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: absolute;
    bottom: 0;
    z-index: 3
}

.fullscreen-section .copyright {
    margin-bottom: 0;
    font-size: 1.1rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    color: #fff
}

.fullscreen-section .social-list {
    margin-bottom: 0
}

.fullscreen-inner.v-align-top {
    vertical-align: top
}

.fullscreen-inner.v-align-bottom {
    vertical-align: bottom
}

.fullscreen-section .hero-4,
.fullscreen-section .hero-5,
.fullscreen-section .hero-6,
.fullscreen-section .hero-7 {
    height: 100vh
}

.coming-soon-1 .background-image {
    background-image: url(../images/fullscreen/coming-soon-1.jpg)
}

.coming-soon-2 .background-image {
    background-image: url(../images/fullscreen/coming-soon-1.jpg)
}

.coming-soon-2 h1 {
    font-size: 10rem;
    line-height: 1
}

.error-404 .background-image {
    background-image: url(../images/fullscreen/coming-soon-1.jpg)
}

.error-404 h1 {
    font-size: 15rem;
    line-height: 1
}

.error-404 .search-form-container {
    max-width: 60rem;
    margin-left: auto;
    margin-right: auto
}

.error-404 .form-submit {
    width: 100%
}

.error-500 .background-image {
    background-image: url(../images/fullscreen/coming-soon-1.jpg)
}

.error-500 h1 {
    font-size: 15rem;
    line-height: 1
}

.error-500 .button {
    margin-right: 2rem
}

.error-500 .button:last-child {
    margin-right: 0
}

[class*="error-"] .background-image,
[class*="coming-soon-"] .background-image {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover
}

@media only screen and (max-width: 768px) {
    .fs-pagination {
        display: none
    }
    .fullscreen-section footer {
        position: relative
    }
    .fullscreen-section .column:last-child > *:last-child:not(.hero-content):not(input[type="submit"]) {
        margin-bottom: 0
    }
    .fullscreen-section.content-below-on-mobile {
        height: auto;
        display: block
    }
    .fullscreen-section.content-below-on-mobile .fullscreen-inner {
        padding-top: 7rem !important;
        padding-bottom: 7rem !important;
        display: block;
        text-align: left
    }
    .fullscreen-section.no-overlay.content-below-on-mobile .background-on-mobile {
        padding: 0;
        margin-bottom: 0;
        background-color: #f4f4f4
    }
    .fullscreen-section.no-overlay.content-below-on-mobile *,
    .fullscreen-section .background-on-mobile * {
        color: #666
    }
    .fullscreen-section.content-below-on-mobile .alt-color-on-mobile {
        color: #fff
    }
    .fullscreen-section .background-image-wrapper,
    .webkit .fullscreen-section .background-image-wrapper,
    .fullscreen-section.background-fixed .background-image-wrapper,
    .webkit .fullscreen-section.background-fixed .background-image-wrapper,
    .fullscreen-section .background-image,
    .fullscreen-section.background-fixed .background-image,
    .webkit .fullscreen-section.background-fixed .background-image {
        max-width: 100vw;
        background-attachment: scroll
    }
    .fs-image-scale .background-image-wrapper,
    .fs-image-scale .background-image,
    .content-below-on-mobile .background-image-wrapper,
    .content-below-on-mobile .background-image,
    .content-below-on-mobile .background-slider-wrapper {
        position: relative !important
    }
    .fullscreen-section.background-contain .background-image,
    .fullscreen-section.background-contain .background-image {
        background-position: center !important;
        background-size: 100% !important
    }
    .mobile .fullscreen-section .background-image {
        max-width: 114rem;
        height: inherit
    }
    .mobile .fullscreen-section {
        margin-top: -1px
    }
    .coming-soon-2 h1 {
        font-size: 6rem
    }
    .error-404 .form-submit {
        width: auto
    }
    .error-404 h1,
    .error-500 h1 {
        font-size: 10rem
    }
}

.mobile .fullscreen-section[class*="error-"],
.mobile .fullscreen-section[class*="coming-soon-"] {
    height: 100vh;
    display: table
}

.mobile [class*="error-"] .fullscreen-inner,
.mobile [class*="coming-soon-"] .fullscreen-inner {
    display: table-cell
}

.mobile .fullscreen-section[class*="error-"] .background-image-wrapper,
.mobile .fullscreen-section[class*="coming-soon-"] .background-image-wrapper {
    position: fixed
}

@media only screen and (-webkit-min-device-pixel-ratio: 2),
only screen and ( min--moz-device-pixel-ratio: 2),
only screen and ( min-device-pixel-ratio: 2),
only screen and ( -webkit-min-device-pixel-ratio: 2),
only screen and ( min-resolution: 192dpi),
only screen and ( min-resolution: 2dppx) {
    .coming-soon-1 .background-image {
        background-image: url(../images/fullscreen/coming-soon-1@2x.jpg)
    }
    .coming-soon-2 .background-image {
        background-image: url(../images/fullscreen/coming-soon-1@2x.jpg)
    }
    .error-404 .background-image {
        background-image: url(../images/fullscreen/coming-soon-1@2x.jpg)
    }
    .error-500 .background-image {
        background-image: url(../images/fullscreen/coming-soon-1@2x.jpg)
    }
}

.pagination-previous.disabled,
.pagination-next.disabled,
.pagination-previous.disabled:hover,
.pagination-next.disabled:hover {
    -webkit-transition: none;
    transition: none;
    cursor: default;
    background: none !important
}

.pagination-1 small {
    font-size: 1.3rem;
    display: block
}

.pagination-1 small span {
    font-size: 1.3rem;
    display: inline-block
}

.pagination-1 [class*="icon-"] {
    height: 3.6rem;
    font-size: 3.6rem;
    display: inline-block
}

.pagination-1 .pagination-previous {
    text-align: left
}

.pagination-1 .pagination-previous [class*="icon-"] {
    margin-right: 1rem;
    float: left
}

.pagination-1 .pagination-next {
    text-align: right
}

.pagination-1 .pagination-next [class*="icon-"] {
    margin-left: 1rem;
    float: right
}

.pagination-1 span {
    font-size: 2rem;
    font-weight: bold;
    display: block
}

.pagination-1 a.disabled,
.pagination-1 a.disabled:hover {
    color: #ddd
}

.pagination-1 .return-to-index,
.pagination-1 .page-list {
    margin-top: 0.3rem;
    margin-bottom: 0;
    font-size: 1.7rem;
    text-align: center;
    font-weight: bold
}

.pagination-1 .page-list li {
    padding: 0 0.7rem
}

.section-block.pagination-2 {
    padding-top: 0;
    padding-bottom: 0
}

.pagination-2 .column,
.pagination-2 .column:hover {
    background-size: cover;
    background-position: center;
    -webkit-transform: translate(0, 0)
}

body.boxed .pagination-2 {
    padding-left: 0;
    padding-right: 0
}

.pagination-2 .pagination-previous,
.pagination-2 .pagination-next {
    padding-top: 7rem;
    padding-bottom: 7rem;
    display: block;
    position: relative
}

.pagination-2 .pagination-previous {
    padding-left: 3rem;
    padding-right: 5rem;
    background: #f9f9f9;
    text-align: right
}

.pagination-2 .pagination-next {
    padding-right: 3rem;
    padding-left: 5rem;
    background: #eee;
    text-align: left
}

.pagination-2 a:hover {
    background: #ff0000
}

.pagination-2 small {
    font-size: 1.3rem;
    display: block
}

.pagination-2 small span {
    font-size: 1.3rem;
    display: inline-block
}

.pagination-2 [class*="icon-"] {
    height: 3.6rem;
    font-size: 3.6rem;
    display: inline-block
}

.pagination-2 .pagination-previous [class*="icon-"] {
    margin-left: 1rem;
    float: right
}

.pagination-2 .pagination-next [class*="icon-"] {
    margin-right: 1rem;
    float: left
}

.pagination-2 span {
    font-size: 2rem;
    font-weight: bold;
    display: block
}

.pagination-2 a.disabled,
.pagination-2 a.disabled:hover {
    color: #ddd
}

.pagination-3 ul {
    margin: 0;
    text-align: center
}

.pagination-3 ul li {
    display: inline-block
}

.pagination-3 a {
    width: 4rem;
    height: 4rem;
    margin: 0;
    padding: 1rem;
    display: block;
    border: 1px solid #ddd;
    border-radius: 50%;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.8rem;
    text-align: center
}

.pagination-3 a.current,
.pagination-3 a:hover {
    background: #333;
    color: #fff;
    border: transparent
}

.pagination-3 li:first-child {
    float: left
}

.pagination-3 li:last-child {
    float: right
}

.pagination-3 a.disabled,
.pagination-3 a.disabled:hover {
    color: #ddd;
    border-color: #ddd
}

.pagination-4 {
    text-align: center
}

.pagination-4 .button {
    width: auto;
    margin: 0 0.5rem;
    font-size: 1.6rem;
    font-weight: bold
}

.pagination-4 .button.disabled,
.pagination-4 .button.disabled:hover {
    color: #ddd;
    background: none;
    border-color: #ddd
}

.pagination-4 .button [class*="icon-"]:before {
    margin: 0
}

.pagination-4 .pagination-previous [class*="icon-"],
.pagination-4 .pagination-next [class*="icon-"] {
    display: none
}

.pagination-5 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    border-top: 1px solid #ddd
}

.pagination-5 ul {
    margin: 0;
    text-align: center
}

.pagination-5 ul li {
    display: inline-block;
    margin-left: -0.4rem
}

.pagination-5 a {
    width: 6rem;
    height: 6rem;
    color: #333;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 6rem;
    display: block
}

.pagination-5 a.current {
    color: #999
}

.pagination-5 a:hover {
    background: #333;
    color: #fff
}

.pagination-5 li:first-child {
    float: left
}

.pagination-5 li:last-child {
    float: right
}

.pagination-5 a.disabled,
.pagination-5 a.disabled:hover {
    color: #ddd;
    border-color: #ddd
}

.pagination-5 a.pagination-previous,
.pagination-5 a.pagination-next {
    width: auto;
    padding: 0 2rem;
    font-weight: bold;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd
}

.pagination-5 a.pagination-previous {
    padding-right: 3.5rem
}

.pagination-5 a.pagination-next {
    padding-left: 3.5rem
}

.pagination-5 a span {
    width: auto;
    float: left
}

.pagination-5 a [class*="icon-"] {
    height: 100%;
    margin-right: 0;
    line-height: 6rem;
    font-size: 3.2rem
}

@media only screen and (max-width: 768px) {
    .pagination-1 .pagination-previous,
    .pagination-1 .return-to-index,
    .pagination-1 .page-list {
        padding-bottom: 3.8rem;
        margin-bottom: 3.8rem;
        border-bottom: 1px solid #ddd;
        display: block
    }
    .pagination-1 .return-to-index,
    .pagination-1 .page-list,
    .pagination-1 .pagination-next {
        text-align: left
    }
    .pagination-1 .pagination-previous [class*="icon-"] {
        margin-right: 0;
        float: right
    }
    .pagination-1 .pagination-next [class*="icon-"] {
        margin-left: 0
    }
    .pagination-2 [class*="pagination-"] {
        padding: 4rem 1.5rem
    }
    .pagination-2 .pagination-previous {
        text-align: left
    }
    .pagination-2 .pagination-previous:after {
        width: 10rem;
        height: 1rem;
        bottom: 0
    }
    .pagination-2 .pagination-previous [class*="icon-"] {
        margin-left: 0;
        margin-right: 1rem;
        float: left
    }
    .pagination-5 ul li a.pagination-previous,
    .pagination-5 ul li a.pagination-next {
        width: 6rem;
        padding: 0;
        text-align: center
    }
    .pagination-5 ul li a span {
        float: none
    }
    .pagination-5 ul li a.pagination-previous span:last-child {
        display: none
    }
    .pagination-5 ul li a.pagination-next span:first-child {
        display: none
    }
}

@media only screen and (max-width: 490px) {
    .pagination-1,
    .pagination-3,
    .pagination-4 {
        padding-top: 4rem;
        padding-bottom: 4rem
    }
    .pagination-4 span {
        display: none
    }
    .pagination-4 .back-to-index [class*="icon-"],
    .pagination-4 .pagination-previous [class*="icon-"],
    .pagination-4 .pagination-next [class*="icon-"] {
        display: block
    }
    .pagination-3 ul li,
    .pagination-5 ul li {
        display: none
    }
    .pagination-3 ul li:first-child,
    .pagination-3 ul li:last-child,
    .pagination-5 ul li:first-child,
    .pagination-5 ul li:last-child {
        display: inline-block
    }
}

.section-block.map-wrapper {
    padding-top: 0;
    padding-bottom: 0
}

.map-container {
    width: 100%;
    height: 40rem
}

#map-canvas {
    width: 100%;
    height: 100%
}

#map-canvas img {
    max-width: none
}

.gm-style .gm-style-iw {
    padding: 0.3rem;
    color: #666;
    line-height: 1.5
}

.footer {
    position: relative;
    z-index: 1
}

.footer [class*="content-grid"] {
    margin-left: -2rem;
    margin-right: -2rem
}

.footer .grid-item {
    padding-left: 2rem;
    padding-right: 2rem
}

.footer .widget {
    margin-bottom: 3rem
}

.footer .grid-item > *:first-child,
.footer .footer-top-inner > *:first-child,
.footer .widget:last-child > *:first-child {
    margin-top: 0
}

.footer .grid-item > *:last-child,
.footer .footer-top-inner > *:last-child,
.footer .widget:last-child > *:last-child {
    margin-bottom: 0
}

.footer .grid-item.one-third {
    width: 33.33333%
}

.footer .grid-item.two-thirds {
    width: 66.66667%
}

.footer .navigation,
.footer .social-list {
    float: none
}

.footer .footer-logo {
    line-height: 1
}

.footer .footer-logo a {
    -webkit-transition-property: opacity, background, color, visibility, -webkit-transform;
    transition-property: opacity, background, color, visibility, -webkit-transform;
    transition-property: opacity, background, color, visibility, transform;
    transition-property: opacity, background, color, visibility, transform, -webkit-transform;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms
}

.footer .footer-logo a:hover {
    opacity: 0.6
}

.footer .navigation li:hover a {
    background: none
}

.footer .navigation li:first-child a {
    padding-left: 0;
    margin-left: 0
}

.footer .navigation li:last-child a {
    padding-right: 0;
    margin-right: 0
}

body.boxed .footer .footer-top,
body.boxed .footer .footer-bottom {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.footer-1 {
    background-color: #222
}

.footer-1 .footer-top {
    padding-top: 7rem;
    padding-bottom: 7rem
}

.footer-1 .footer-logo {
    width: 17rem;
    margin-bottom: 1rem;
    display: inline-block
}

.footer-1 .footer-text {
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #555
}

.footer-1 .footer-bottom {
    padding-bottom: 2rem;
    color: #555
}

.footer-1 .footer-bottom-inner {
    padding-top: 3rem;
    border-top: 1px solid #333
}

.footer-1 .footer-bottom .copyright {
    font-size: 1.1rem;
    float: left
}

.footer-1 .navigation a {
    line-height: 2rem;
    text-transform: uppercase;
    background: none;
    padding: 0 1rem
}

.footer-1 .navigation a:hover {
    background: none;
    color: #fff
}

.footer-1 .navigation li.current a,
.footer-1 .navigation li.current a:hover {
    background: none;
    color: #fff
}

.footer-1 .footer-bottom .navigation,
.footer-1 .footer-bottom .social-list {
    float: right
}

.footer-1 .footer-bottom .navigation,
.footer-1 .footer-bottom .social-list,
.footer-1 .footer-bottom .copyright {
    margin-bottom: 1rem
}

.footer-2 {
    background-color: #333;
    text-align: center
}

.footer-2 .scroll-to-top-wrapper {
    text-align: center;
    display: block
}

.footer-2 .scroll-to-top {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
    background: #222
}

.footer-2 .footer-top {
    padding-top: 7rem;
    padding-bottom: 7rem
}

.footer-2 .footer-logo {
    width: 17rem;
    margin-bottom: 1rem;
    display: inline-block
}

.footer-2 .footer-text {
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #555
}

.footer-2 .navigation {
    float: none
}

.footer-2 .navigation li {
    display: inline-block;
    float: none
}

.footer-2 .navigation a {
    line-height: 2rem;
    text-transform: uppercase;
    background: none;
    padding: 0 1rem
}

.footer-2 .navigation a:hover {
    background: none;
    color: #fff
}

.footer-2 .navigation li.current > a,
.footer-2 .navigation li.current > a:hover {
    background: none;
    color: #fff
}

.footer-2 .footer-bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #222;
    color: #555
}

.footer-2 .copyright {
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 0.1rem
}

.footer-2 .footer-bottom .navigation,
.footer-2 .footer-bottom .social-list,
.footer-2 .footer-bottom .copyright {
    margin-bottom: 0
}

.footer-3 {
    background-color: #333
}

.footer-3 .scroll-to-top-wrapper {
    margin-top: 2rem;
    margin-bottom: -4rem;
    text-align: center;
    display: block
}

.footer-3 .scroll-to-top {
    width: 3rem;
    height: 3rem;
    margin: auto;
    line-height: 3rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #222
}

.footer-3 .footer-top {
    padding-top: 7rem;
    padding-bottom: 4rem
}

.footer-3 .footer-logo {
    width: 100%;
    margin: 0 auto 2rem auto
}

.footer-3 h5 {
    margin-bottom: 3rem;
    color: #fff
}

.footer-3 .footer-text {
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #555
}

.footer-3 .navigation li {
    display: inline-block;
    float: none
}

.footer-3 .navigation a {
    line-height: 2rem;
    text-transform: uppercase;
    background: none;
    padding: 0 1rem
}

.footer-3 .navigation a:hover {
    background: none;
    color: #ffffff
}

.footer-3 .navigation li.current > a,
.footer-3 .navigation li.current > a:hover {
    background: none;
    color: #fff
}

.footer-3 .footer-bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #222
}

.footer-3 .copyright {
    font-size: 1.1rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    color: #555
}

.footer-3 .footer-bottom .navigation,
.footer-3 .footer-bottom .social-list,
.footer-3 .footer-bottom .copyright {
    margin-bottom: 0
}

.footer-4 {
    background-color: #333
}

.footer-4 .scroll-to-top-wrapper {
    margin-top: 2rem;
    margin-bottom: -4rem;
    text-align: center;
    display: block
}

.footer-4 .scroll-to-top {
    width: 3rem;
    height: 3rem;
    margin: auto;
    line-height: 3rem;
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    background: #222
}

.footer-4 .footer-top {
    padding-top: 7rem;
    padding-bottom: 4rem
}

.footer-4 .footer-logo {
    width: 100%;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    border-bottom: 1px solid #444
}

.footer-4 h5 {
    margin-bottom: 3rem;
    color: #fff
}

.footer-4 .footer-text {
    margin-top: 0.5rem;
    text-transform: uppercase;
    font-size: 1.1rem;
    color: #555
}

.footer-4 .navigation li {
    display: inline-block;
    float: none
}

.footer-4 .navigation a {
    line-height: 2rem;
    text-transform: uppercase;
    background: none;
    padding: 0 1rem
}

.footer-4 .navigation a:hover {
    background: none;
    color: #ffffff
}

.footer-4 .navigation li.current > a,
.footer-4 .navigation li.current > a:hover {
    background: none;
    color: #fff
}

.footer-4 .footer-bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
    background: #222
}

.footer-4 .copyright {
    font-size: 1.1rem;
    font-weight: normal;
    letter-spacing: 0.1rem;
    color: #555
}

.footer-4 .footer-bottom .navigation,
.footer-4 .footer-bottom .social-list,
.footer-4 .footer-bottom .copyright {
    margin-bottom: 0
}

@media only screen and (max-width: 768px) {
    [class*="footer-"] .grid-item.one-third {
        width: 100%
    }
    [class*="footer-"] .grid-item.two-thirds {
        width: 100%
    }
    [class*="footer-"] .footer-bottom .copyright,
    [class*="footer-"] .footer-bottom .navigation,
    [class*="footer-"] .footer-bottom .social-list {
        display: block;
        float: none
    }
    .footer-1 .navigation li {
        display: inline-block;
        float: none
    }
}

@media only screen and (max-width: 480px) {
    .footer-3,
    .footer-4 {
        text-align: center
    }
}

.post {
    margin-bottom: 7rem
}

.post:last-child {
    margin-bottom: 0 !important
}

.post-content {
    position: relative;
    -webkit-transition-property: background, color;
    transition-property: background, color;
    -webkit-transition-duration: 600ms;
    transition-duration: 600ms
}

.post-media ~ .post-content,
.post-media + .post-info-aside {
    margin-top: 3rem
}

.post-content + .post-media {
    margin-top: 3rem
}

.blog-masonry.masonry-set-dimensions .post,
.blog-masonry.masonry-set-dimensions .post-media {
    height: 100%
}

.blog-masonry.masonry-set-dimensions .post-content {
    width: 100%;
    height: auto;
    padding: 0;
    color: #fff;
    display: table;
    position: absolute;
    bottom: 0;
    z-index: 2
}

.blog-masonry.masonry-set-dimensions .post-content:hover {
    background: rgba(0, 0, 0, 0.8)
}

.blog-masonry.masonry-set-dimensions .post-content-inner {
    height: 100%;
    padding: 2rem;
    display: table-cell;
    vertical-align: bottom
}

.post-content > *:last-child {
    margin-bottom: 0
}

.post-content.with-background {
    padding: 3rem;
    margin-top: 0;
    background: #f9f9f9
}

.post-content.with-background > *:first-child {
    margin-top: 0
}

.blog-masonry.masonry-set-dimensions .post-content.with-background {
    background: rgba(0, 0, 0, 0.2)
}

.blog-masonry.masonry-set-dimensions .post-media:hover .post-content {
    background: rgba(0, 0, 0, 0.8)
}

.post-info {
    margin-bottom: 3rem
}

.blog-masonry.masonry-set-dimensions .post-info {
    margin-bottom: 1rem
}

.post-info-aside,
.post-author-aside,
.post-comments-aside {
    position: absolute;
    z-index: 1
}

.post-info-aside + .post-content,
.post-author-aside + .author-bio,
.post-comments-aside + .comments-inner {
    padding-left: 10rem
}

.post-info {
    padding: 0;
    margin-right: 0.2rem;
    margin-top: 0;
    font-size: 1.1rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    display: inline-block;
    border: none
}

.post-info span:first-child {
    margin-left: 0
}

.post-info span:last-child {
    margin-right: 0
}

.post-info [class*="icon-"] {
    display: inline-block;
    position: relative;
    z-index: 1
}

.post-info .post-love [class*="icon-"] {
    margin-right: 0.2rem
}

.post-love a [class*="icon-"],
.post-comments a [class*="icon-"] {
    width: 1.2rem;
    text-align: center;
    -webkit-transition-property: transform;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 200ms;
    -moz-transition-duration: 200ms;
    -ms-transition-duration: 200ms;
    -o-transition-duration: 200ms;
    transform-transition-duration: 200ms
}

.post-love a:hover [class*="icon-"],
.post-comment a:hover [class*="icon-"] {
    -webkit-transform: scale3d(1.2, 1.2, 1);
    transform: scale3d(1.2, 1.2, 1)
}

.post-content .read-more {
    margin-bottom: 0
}

.post-media {
    max-width: 114rem
}

.post-media > * {
    float: none;
    margin-bottom: 0
}

.blog-masonry.masonry-set-dimensions .post-media {
    position: relative;
    overflow: hidden
}

.blog-masonry.masonry-set-dimensions.no-margins .post-media {
    position: static;
    overflow: hidden
}

.blog-regular .post-media .post-slider {
    width: 82.5rem;
    height: 55rem
}

.blog-single-post .post-media .post-slider,
.blog-masonry .post-media .post-slider {
    width: 82.5rem;
    height: 55rem
}

.blog-wide .post-media .post-slider {
    width: 111rem;
    height: 74rem
}

.post-media .mejs-audio,
.post-media .mejs-audio .mejs-controls,
.post-media .mejs-audio .mejs-container .mejs-button,
.post-media .mejs-audio .mejs-button.mejs-play,
.post-media .mejs-audio .mejs-button.mejs-pause,
.post-media .mejs-audio .mejs-button.mejs-mute,
.post-media .mejs-audio .mejs-controls div.mejs-time-rail,
.post-media .mejs-audio .mejs-controls div.mejs-horizontal-volume-slider {
    height: 4.9rem !important
}

.post-media .mejs-audio .mejs-controls .mejs-time {
    margin-top: 1rem
}

.post-media .mejs-audio .mejs-time-rail .mejs-time-total {
    margin-top: 2.1rem
}

.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-total,
.post-media .mejs-audio .mejs-horizontal-volume-slider .mejs-horizontal-volume-current {
    top: 2.1rem
}

.post-media .mejs-audio .mejs-button button {
    margin: 1.6rem 0.7rem
}

.post-media .mejs-audio .mejs-button.mejs-play,
.post-media .mejs-audio .mejs-button.mejs-pause {
    width: 4.4rem !important
}

.post-media .mejs-audio .mejs-button.mejs-play button,
.post-media .mejs-audio .mejs-button.mejs-pause button {
    margin: 1.6rem 1.4rem
}

.blog-masonry.no-padding-top {
    padding-top: 1rem
}

.blog-masonry.no-padding-bottom {
    padding-bottom: 0.5rem
}

.blog-masonry.full-width.no-margins {
    padding: 0
}

.blog-masonry [class*="content-grid"] {
    margin-bottom: -3rem
}

.blog-masonry.masonry-set-dimensions [class*="content-grid"] {
    margin-bottom: -1.5rem
}

.blog-masonry.no-margins [class*="content-grid"],
.blog-masonry.no-margins [class*="content-grid"] .grid-item {
    padding: 0 !important;
    margin: 0 !important
}

.blog-masonry > .row > .column,
.blog-masonry [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0
}

.blog-masonry [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0
}

.blog-masonry.full-width [class*="content-grid"] {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

body.boxed .blog-masonry.full-width [class*="content-grid"] {
    margin-left: 0;
    margin-right: 0
}

.blog-masonry [class*="content-grid"] .grid-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.blog-masonry .grid-item .video,
.blog-masonry .grid-item .video-container {
    margin-bottom: 0
}

.blog-regular h2.post-title,
.blog-wide h2.post-title,
.blog-masonry h2.post-title,
.blog-single-post h2.post-title {
    margin-top: 0;
    margin-bottom: 1rem
}

.blog-masonry.small-margins [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: -1.5rem;
    margin-right: -1.5rem
}

.blog-masonry.small-margins > .row > .column {
    padding-left: 1rem;
    padding-right: 1rem
}

.blog-masonry.small-margins.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.blog-masonry.small-margins.full-width > .row > .column {
    padding-left: 0.5rem;
    padding-right: 0.5rem
}

.blog-masonry.small-margins.no-margins.full-width > .row > .column {
    padding-left: 0rem;
    padding-right: 0rem
}

.blog-masonry.small-margins.full-width [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0
}

.blog-masonry.small-margins [class*="content-grid"] {
    padding-left: 0;
    padding-right: 0;
    margin-top: -0.5rem;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: -0.5rem
}

.blog-masonry.small-margins [class*="content-grid"] .grid-item {
    margin-bottom: 0;
    padding: 0.5rem;
    position: relative
}

.blog-masonry.no-margins > .row > .column {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.blog-masonry.full-width.no-margins > .row > .column {
    padding-left: 0;
    padding-right: 0
}

.blog-masonry.full-width .row {
    max-width: 100%
}

.sidebar.left .sidebar-inner {
    padding-right: 1rem
}

.sidebar.right .sidebar-inner {
    padding-left: 1rem
}

.sidebar.left .sidebar-inner,
.sidebar.right .sidebar-inner {
    text-align: left
}

.sidebar .widget:first-child .widget-title {
    margin-top: 0
}

.widget .post-info {
    display: block;
    margin-bottom: 0;
    line-height: 1
}

@media only screen and (max-width: 1140px) {
    .blog-wide .post-media .post-slider {
        width: 93rem;
        height: 62rem
    }
    .blog-regular .post-media .post-slider {
        width: 69rem;
        height: 46rem
    }
    .blog-masonry [class*="content-grid"] .grid-item {
        width: 33.33333%
    }
}

@media only screen and (max-width: 960px) {
    .blog-wide .post-media .post-slider {
        width: 73rem;
        height: 48.7rem
    }
    .blog-regular .post-media .post-slider {
        width: 54rem;
        height: 36rem
    }
    .sidebar.left .sidebar-inner {
        padding-right: 0
    }
    .sidebar.right .sidebar-inner {
        padding-left: 0
    }
    .blog-masonry [class*="content-grid"] .grid-item {
        width: 50%
    }
}

@media only screen and (max-width: 768px) {
    .blog-regular .post-media .post-slider,
    .blog-wide .post-media .post-slider {
        width: 57rem;
        height: 38rem
    }
    .post-info span.show-on-mobile,
    .post-info .show-on-mobile a,
    .post-info [class*="icon-"] {
        display: inline-block !important
    }
    .post-info-aside,
    .post-author-aside,
    .post-comments-aside {
        display: none !important
    }
    .post-info-aside + .post-content,
    .post-author-aside + .author-bio,
    .post-comments-aside + .comments-inner {
        padding-left: 0 !important
    }
    .comment-list ul {
        margin-left: 0 !important
    }
}

@media only screen and (max-width: 600px) {
    .blog-regular .post-media .post-slider,
    .blog-wide .post-media .post-slider {
        width: 45rem;
        height: 30rem
    }
    .blog-masonry [class*="content-grid"] .grid-item {
        width: 100%
    }
}

@media only screen and (max-width: 480px) {
    .blog-regular .post-media .post-slider,
    .blog-wide .post-media .post-slider {
        width: 32rem;
        height: 21.3rem
    }
}

.single-post .post {
    margin-bottom: 0
}

.single-post .post-content .post-media {
    margin-bottom: 3rem
}

.single-post .post-content.with-background .post-media {
    margin-bottom: 0
}

.single-post-tags {
    width: 100%;
    padding-top: 3rem;
    margin-top: 3rem;
    font-size: 1.2rem;
    border-top: 1px solid #ddd
}

.single-post-tags .tags-title {
    font-weight: bold;
    margin-top: 0
}

.single-post-tags a {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: inline-block;
    letter-spacing: 0.1rem
}

.post-author {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #ddd
}

.post-author .author-avatar {
    max-width: 7rem;
    float: left
}

.post-author .author-avatar img {
    border-radius: 50%
}

.post-author .author-content {
    margin-left: 10rem
}

.post-author .author-content *:last-child {
    margin-bottom: 0
}

.post-author .name {
    font-size: 1.6rem;
    margin-bottom: 0.6rem
}

.post-comments {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #ddd
}

.post-comments .comments-title {
    margin-bottom: 3rem
}

.post-comments .comment-list > li {
    margin-bottom: 4rem
}

.post-comments .comment-list > li:last-child {
    margin-bottom: 0
}

.post-comments .user-avatar {
    max-width: 7rem;
    float: left
}

.post-comments .user-avatar img {
    border-radius: 50%
}

.post-comments .comment-content {
    margin-left: 10rem
}

.post-comments .name {
    font-size: 1.6rem;
    margin-bottom: 0.6rem
}

.post-comments .comment-meta {
    width: 100%;
    margin-bottom: 2rem;
    line-height: 1
}

.post-comments .comment-meta a {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: inline-block;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    font-weight: bold
}

.post-comments .comment-meta a:first-child {
    margin-left: 0
}

.post-comment-respond {
    padding-top: 3rem;
    margin-top: 3rem;
    border-top: 1px solid #ddd
}

.post-comment-respond .reply-title {
    margin-bottom: 3rem
}

.post-comment-respond .comment-form .column {
    float: none
}

.sidebar .widget {
    margin-bottom: 3rem
}

.sidebar .widget li {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem
}

@media only screen and (max-width: 768px) {
    .sidebar .sidebar-inner {
        padding-top: 3rem;
        margin-top: 3rem;
        border-top: 1px solid #ddd
    }
}

[class^="title-"] {
    margin-bottom: 2rem
}

.title-xlarge {
    font-size: 9rem;
    line-height: 9rem
}

.title-large {
    font-size: 7rem;
    line-height: 7rem
}

.title-medium {
    font-size: 5rem;
    line-height: 5rem
}

.title-small {
    font-size: 3rem;
    line-height: 3rem
}

.text-xlarge {
    font-size: 2.2rem
}

.text-large {
    font-size: 1.8rem
}

.text-medium {
    font-size: 1.5rem
}

.text-small {
    font-size: 1.2rem
}

.text-uppercase {
    text-transform: uppercase
}

@media only screen and (max-width: 768px) {
    .title-xlarge {
        font-size: 4.5rem;
        line-height: 4.5rem
    }
    .title-large {
        font-size: 4rem;
        line-height: 4rem
    }
    .title-medium {
        font-size: 3rem;
        line-height: 3rem
    }
    .title-small {
        font-size: 2rem;
        line-height: 2rem
    }
    .text-xlarge {
        font-size: 2rem;
        line-height: 1.7
    }
    .text-large {
        font-size: 1.6rem;
        line-height: 1.6
    }
    .text-medium {
        font-size: 1.3rem;
        line-height: 1.5
    }
    .text-small {
        font-size: 1.2rem;
        line-height: 1.3
    }
}

.mt-5 {
    margin-top: 0.5rem
}

.mt-10 {
    margin-top: 1rem
}

.mt-20 {
    margin-top: 2rem
}

.mt-30 {
    margin-top: 3rem
}

.mt-40 {
    margin-top: 4rem
}

.mt-50 {
    margin-top: 5rem
}

.mt-60 {
    margin-top: 6rem
}

.mt-70 {
    margin-top: 7rem
}

.mt-80 {
    margin-top: 8rem
}

.mt-90 {
    margin-top: 9rem
}

.mt-100 {
    margin-top: 10rem
}

.mt-110 {
    margin-top: 11rem
}

.mt-120 {
    margin-top: 12rem
}

.mt-130 {
    margin-top: 13rem
}

.mt-140 {
    margin-top: 14rem
}

.mt-150 {
    margin-top: 15rem
}

.mb-5 {
    margin-bottom: 0.5rem
}

.mb-10 {
    margin-bottom: 1rem
}

.mb-20 {
    margin-bottom: 2rem
}

.mb-30 {
    margin-bottom: 3rem
}

.mb-40 {
    margin-bottom: 4rem
}

.mb-50 {
    margin-bottom: 5rem
}

.mb-60 {
    margin-bottom: 6rem
}

.mb-70 {
    margin-bottom: 7rem
}

.mb-80 {
    margin-bottom: 8rem
}

.mb-90 {
    margin-bottom: 9rem
}

.mb-100 {
    margin-bottom: 10rem
}

.mb-110 {
    margin-bottom: 11rem
}

.mb-120 {
    margin-bottom: 12rem
}

.mb-130 {
    margin-bottom: 13rem
}

.mb-140 {
    margin-bottom: 14rem
}

.mb-150 {
    margin-bottom: 15rem
}

.pt-5,
.section-block.pt-5 {
    padding-top: 0.5rem
}

.pt-10,
.section-block.pt-10 {
    padding-top: 1rem
}

.pt-20,
.section-block.pt-20 {
    padding-top: 2rem
}

.pt-30,
.section-block.pt-30 {
    padding-top: 3rem
}

.pt-40,
.section-block.pt-40 {
    padding-top: 4rem
}

.pt-50,
.section-block.pt-50 {
    padding-top: 5rem
}

.pt-60,
.section-block.pt-60 {
    padding-top: 6rem
}

.pt-70,
.section-block.pt-70 {
    padding-top: 7rem
}

.pt-80,
.section-block.pt-80 {
    padding-top: 8rem
}

.pt-90,
.section-block.pt-90 {
    padding-top: 9rem
}

.pt-100,
.section-block.pt-100 {
    padding-top: 10rem
}

.pt-110,
.section-block.pt-110 {
    padding-top: 11rem
}

.pt-120,
.section-block.pt-120 {
    padding-top: 12rem
}

.pt-130,
.section-block.pt-130 {
    padding-top: 13rem
}

.pt-140,
.section-block.pt-140 {
    padding-top: 14rem
}

.pt-150,
.section-block.pt-150 {
    padding-top: 15rem
}

.pb-5,
.section-block.pb-5 {
    padding-bottom: 0.5rem
}

.pb-10,
.section-block.pb-10 {
    padding-bottom: 1rem
}

.pb-20,
.section-block.pb-20 {
    padding-bottom: 2rem
}

.pb-30,
.section-block.pb-30 {
    padding-bottom: 3rem
}

.pb-40,
.section-block.pb-40 {
    padding-bottom: 4rem
}

.pb-50,
.section-block.pb-50 {
    padding-bottom: 5rem
}

.pb-60,
.section-block.pb-60 {
    padding-bottom: 6rem
}

.pb-70,
.section-block.pb-70 {
    padding-bottom: 7rem
}

.pb-80,
.section-block.pb-80 {
    padding-bottom: 8rem
}

.pb-90,
.section-block.pb-90 {
    padding-bottom: 9rem
}

.pb-100,
.section-block.pb-100 {
    padding-bottom: 10rem
}

.pb-110,
.section-block.pb-110 {
    padding-bottom: 11rem
}

.pb-120,
.section-block.pb-120 {
    padding-bottom: 12rem
}

.pb-130,
.section-block.pb-130 {
    padding-bottom: 13rem
}

.pb-140,
.section-block.pb-140 {
    padding-bottom: 14rem
}

.pb-150,
.section-block.pb-150 {
    padding-bottom: 15rem
}

[class*="pu-"],
[class*="pd-"] {
    position: relative
}

.pd-10 {
    top: 1rem
}

.pd-20 {
    top: 2rem
}

.pd-30 {
    top: 3rem
}

.pd-40 {
    top: 4rem
}

.pd-50 {
    top: 5rem
}

.pd-60 {
    top: 6rem
}

.pd-70 {
    top: 7rem
}

.pd-80 {
    top: 8rem
}

.pd-90 {
    top: 9rem
}

.pd-100 {
    top: 10rem
}

.pd-110 {
    top: 11rem
}

.pd-120 {
    top: 12rem
}

.pd-130 {
    top: 13rem
}

.pd-140 {
    top: 14rem
}

.pd-150 {
    top: 15rem
}

.pu-10 {
    top: -1rem
}

.pu-20 {
    top: -2rem
}

.pu-30 {
    top: -3rem
}

.pu-40 {
    top: -4rem
}

.pu-50 {
    top: -5rem
}

.pu-60 {
    top: -6rem
}

.pu-70 {
    top: -7rem
}

.pu-80 {
    top: -8rem
}

.pu-90 {
    top: -9rem
}

.pu-100 {
    top: -10rem
}

.pu-110 {
    top: -11rem
}

.pu-120 {
    top: -12rem
}

.pu-130 {
    top: -13rem
}

.pu-140 {
    top: -14rem
}

.pu-150 {
    top: -15rem
}

@media only screen and (max-width: 768px) {
    .mt-mobile-5 {
        margin-top: 0.5rem
    }
    .mt-mobile-10 {
        margin-top: 1rem
    }
    .mt-mobile-20 {
        margin-top: 2rem
    }
    .mt-mobile-30 {
        margin-top: 3rem
    }
    .mt-mobile-40 {
        margin-top: 4rem
    }
    .mt-mobile-50 {
        margin-top: 5rem
    }
    .mt-mobile-60 {
        margin-top: 6rem
    }
    .mt-mobile-70 {
        margin-top: 7rem
    }
    .mt-mobile-80 {
        margin-top: 8rem
    }
    .mt-mobile-90 {
        margin-top: 9rem
    }
    .mt-mobile-100 {
        margin-top: 10rem
    }
    .mt-mobile-110 {
        margin-top: 11rem
    }
    .mt-mobile-120 {
        margin-top: 12rem
    }
    .mt-mobile-130 {
        margin-top: 13rem
    }
    .mt-mobile-140 {
        margin-top: 14rem
    }
    .mt-mobile-150 {
        margin-top: 15rem
    }
    .mb-mobile-5 {
        margin-bottom: 0.5rem
    }
    .mb-mobile-10 {
        margin-bottom: 1rem
    }
    .mb-mobile-20 {
        margin-bottom: 2rem
    }
    .mb-mobile-30 {
        margin-bottom: 3rem
    }
    .mb-mobile-40 {
        margin-bottom: 4rem
    }
    .mb-mobile-50 {
        margin-bottom: 5rem
    }
    .mb-mobile-60 {
        margin-bottom: 6rem
    }
    .mb-mobile-70 {
        margin-bottom: 7rem
    }
    .mb-mobile-80 {
        margin-bottom: 8rem
    }
    .mb-mobile-90 {
        margin-bottom: 9rem
    }
    .mb-mobile-100 {
        margin-bottom: 10rem
    }
    .mb-mobile-110 {
        margin-bottom: 11rem
    }
    .mb-mobile-120 {
        margin-bottom: 12rem
    }
    .mb-mobile-130 {
        margin-bottom: 13rem
    }
    .mb-mobile-140 {
        margin-bottom: 14rem
    }
    .mb-mobile-150 {
        margin-bottom: 15rem
    }
    [class*="pu-"],
    [class*="pd-"] {
        top: 0
    }
}

@media screen and (max-width: 600px) {
  /*.row {
    padding-left: 20px;
    padding-right: 20px;
  }*/
}
